import { useEffect, useRef, FC } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { UploadFileLibrary } from "./UploadFileLibrary";
import { useMemo, useState } from "react";
import {
  ContentCategory,
  contentCategoryDetailsByExtension,
} from "./ContentCategoryEnum";
import { ContentCategories } from "./ContentCategories";
import { useQueryResources } from "state/remoteState";
import { useTranslation } from "../../layout/TranslationContext";
import { translatePage } from "../../layout/translatePage";
import { ContentLibraryBean } from "api/model";

const Preview: FC<{ resource: ContentLibraryBean }> = ({ resource }) => {
  const details = contentCategoryDetailsByExtension(resource.filetype);

  if (details.category === ContentCategory.Images && resource.sasUri) {
    return (
      <Image
        src={resource.sasUri}
        alt={resource.name ?? resource.filename ?? "unknown resource"}
        objectFit="cover"
        w="40px"
        h="40px"
        borderRadius="6"
        fallback={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="primary.1"
            w="40px"
            h="40px"
            borderRadius="6"
          >
            <Icon as={details.icon} color="primary.6" />
          </Box>
        }
      />
    );
  } else {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="primary.1"
        w="40px"
        h="40px"
        borderRadius="6"
      >
        <Icon as={details.icon} color="primary.6" />
      </Box>
    );
  }
};

export const Library = () => {
  const [activeContentCategory, setActiveContentCategories] =
    useState<ContentCategory>(ContentCategory.All);

  const onContentCategoriesSelected = (category: ContentCategory) => {
    setActiveContentCategories(category);
  };

  const { data, isLoading } = useQueryResources();
  const { language, setLanguage } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hasTranslatedModal, setHasTranslatedModal] = useState(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    const sessionKey = `translated_${language}`;
    if (!sessionStorage.getItem(sessionKey) || data) {
      translatePage(document.body, language);
      sessionStorage.setItem(sessionKey, "true");
    } else {
      translatePage(document.body, language);
    }
  }, [language, data, isLoading]);

  useEffect(() => {
    if (isOpen && modalRef.current && !hasTranslatedModal) {
      translatePage(modalRef.current!, language).then(() => {
        setHasTranslatedModal(true);
      });
    }
  }, [isOpen, modalRef, language, hasTranslatedModal]);

  const resources = useMemo(() => {
    if (!data) {
      return [];
    }
    return data
      .filter((item) => {
        if (activeContentCategory === ContentCategory.All) {
          return true;
        }
        return (
          contentCategoryDetailsByExtension(item.filetype?.toLowerCase() ?? "")
            .category === activeContentCategory
        );
      })
      .map((item) => ({
        name: item.name ?? "",
        connectionCount: item.connectionCount,
        date: new Date(item.dateCreated ?? "").toLocaleDateString("en-GB"),
        filetype: item.filetype ?? "",
        sasUri: item.sasUri,
      }));
  }, [data, activeContentCategory]);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="80%">
        <Spinner
          thickness="5px"
          speed="1.5s"
          emptyColor="gray.200"
          color="primary.4"
          size="xl"
          opacity="0.8"
        />
      </Flex>
    );
  }

  return (
    <>
      <Grid mt="2rem" mx="60px" mb="50px" gap="8">
        <GridItem>
          <Heading size="xl">Library</Heading>
        </GridItem>
        <GridItem>
          <Grid
            width="100%"
            justifyContent="space-between"
            templateColumns={"1fr 150px"}
            alignItems="center"
          >
            <ContentCategories
              onContentCategoriesSelected={onContentCategoriesSelected}
              activeContentCategory={activeContentCategory}
            />
            <Flex width="100%" justifyContent="flex-end">
              <Button width="152px" onClick={onOpen}>
                Upload File
              </Button>
            </Flex>
          </Grid>
        </GridItem>
        <GridItem>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th width="100%">Name</Th>
                  <Th px="40px">Connections</Th>
                  <Th px="40px">Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {resources.map((item, index) => (
                  <Tr key={index}>
                    <Td width="auto">
                      <Flex align="center" gap="3" w="100%">
                        <Preview resource={item} />
                        <Flex direction="column" data-nontranslatable>
                          <Text
                            fontWeight="500"
                            fontSize="sm"
                            color="black"
                            align="left"
                            data-nontranslatable={item.name}
                          >
                            {item.name}
                          </Text>
                        </Flex>
                      </Flex>
                    </Td>
                    <Td
                      px="20px"
                      textAlign="center"
                      data-nontranslatable={item.connectionCount?.toString()}
                    >
                      {item.connectionCount}
                    </Td>
                    <Td
                      px="20px"
                      textAlign="end"
                      data-nontranslatable={item.date}
                    >
                      {item.date}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </GridItem>
      </Grid>
      <div id="library">
        <div ref={modalRef}>
          {isOpen && <UploadFileLibrary isOpen={isOpen} onClose={onClose} />}
        </div>
      </div>
    </>
  );
};
