import { Button, Flex } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { ContentCategory } from "./ContentCategoryEnum";

interface ContentCategoriesProps {
  onContentCategoriesSelected: (category: ContentCategory) => void;
  activeContentCategory: ContentCategory;
}

export const ContentCategories: FC<ContentCategoriesProps> = ({
  onContentCategoriesSelected,
  activeContentCategory,
}) => {
  const contentCategories = useMemo(
    () => Object.values(ContentCategory) as ContentCategory[],
    [],
  );
  return (
    <Flex>
      {contentCategories.map((category, index) => (
        <Button
          key={index}
          minWidth="auto"
          className="item"
          size="sm"
          borderRadius="50"
          variant="ghost"
          isActive={activeContentCategory === category}
          padding="4px 16px"
          marginRight="10px"
          data-targetid={index + 1}
          onClick={() => onContentCategoriesSelected(category)}
        >
          {category}
        </Button>
      ))}
    </Flex>
  );
};
