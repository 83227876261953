import { FC, useRef, useState, SyntheticEvent, DragEvent } from "react";
import { useForm } from "react-hook-form";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Content, ContentType } from "api/model";
import { useProjectChapterId } from "state/localState";
import {
  useMutationCreateContent,
  useMutationUpdateContent,
} from "state/remoteState";
import { IoMusicalNotesSharp } from "react-icons/io5";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { translateText } from "../../../../../../../layout/translatePage";
import { useTranslation } from '../../../../../../../layout/TranslationContext';

type FormData = {
  name: string;
};

export const TabAudioUpload: FC<CreateOrUpdateContentProps> = (props) => {
  const { projectId, chapterId } = useProjectChapterId();
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const [file, setFile] = useState<File | undefined>(props.file);
  const toast = useToast();
  const { language, setLanguage } = useTranslation();
  const mode = props.content ? "update" : "create";
  const content =
    props.content ??
    ({
      type: ContentType.Audio,
    } as Content);

  const {
    register,
    watch,
    formState: { isValid },
  } = useForm<FormData>({
    values: {
      name: content.name ?? "",
    },
  });
  const previewURL = (() => {
    if (file) {
      return URL.createObjectURL(file);
    }
    if (mode === "update" && content.sources?.main) {
      return content.sources?.main;
    }
    return undefined;
  })();
  const showTranslatedToast = async (message: string, language: string) => {
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage,
    });
  };
  const [dragActive, setDragActive] = useState<boolean>(false);
  const formData = watch();

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileUpload = () => {
    hiddenFileInput.current?.click();
  };

  const handleFileSelected = () => {
    const file = hiddenFileInput.current?.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleCreateContent = () => {
    content.name = formData.name;
    if (file) {
      content.description = file.name;
    }
    mutationCreateContent.mutate(
      { projectId, chapterId, content, file },
      {
        onSuccess: () => {
          showTranslatedToast(`Audio content "${content.name}" has been added`, language)
          // toast({
          //   status: "success",
          //   title: "Content added",
          //   description: `Audio content "${content.name}" has been added`,
          // });
          props.onSuccess();
        },
      },
    );
  };

  const handleUpdateContent = () => {
    content.name = formData.name;
    if (file) {
      content.description = file.name;
    }
    content.type = ContentType.Audio;
    mutationUpdateContent.mutate(
      {
        projectId,
        chapterId,
        content,
        file,
      },
      {
        onSuccess: () => {
          showTranslatedToast(`Audio content "${content.name ?? "unnamed"}" has been updated.`, language)
          // toast({
          //   status: "success",
          //   title: "Content updated.",
          //   description: `Audio content "${content.name ?? "unnamed"}" has been updated.`,
          // });
          props.onSuccess();
        },
      },
    );
  };

  return (
    <>
      <Grid templateColumns="1fr 1fr" gap="2" minHeight="250px">
        <GridItem>
          <Button
            position="relative"
            onClick={handleFileUpload}
            width="100%"
            height="100%"
            backgroundColor="primary.0"
            borderRadius="6"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{ backgroundColor: "primary.0" }}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <Flex direction="column" align="center">
              {!dragActive ? (
                previewURL ? (
                  <Box position="relative" maxHeight="250px" overflow="hidden">
                    <AudioPreview src={previewURL} />
                    <Text pt="6" color="primary.3">
                      Drag & Drop or click here
                    </Text>
                  </Box>
                ) : (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="primary.1"
                      w="50px"
                      h="50px"
                      borderRadius="50"
                    >
                      <Icon
                        as={IoMusicalNotesSharp}
                        color="primary.6"
                        boxSize="6"
                      />
                    </Box>
                    <Text color="primary.6" pt="6" pb="2" fontWeight="700">
                      Upload Audio
                    </Text>
                    <Text color="primary.4">Supports: MP3</Text>
                    <Text color="primary.3" pt="4">
                      Drag & Drop or click here
                    </Text>
                  </>
                )
              ) : (
                <Text color="primary.6">Drop here</Text>
              )}
            </Flex>
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            style={{ display: "none" }}
            onChange={handleFileSelected}
            accept="audio/*"
          />
        </GridItem>
        <Flex direction="column" gap="4" pl="2">
          <Alert
            status="info"
            color="secondary.2"
            backgroundColor="rgba(77, 136, 140, 0.05)"
            border="1px solid #4D888C"
            borderRadius="8"
          >
            <AlertIcon color="secondary.2" />
            <Text fontWeight="500" fontSize="xs">
              You can add a mp3 audio file here.
            </Text>
          </Alert>
          <Flex align="center" gap="2">
            <Text
              fontSize="sm"
              fontWeight="600"
              width="45%"
              color="primary.4"
              pr="1"
            >
              Content Name
            </Text>
            <Input
              placeholder="Name"
              borderRadius="32"
              size="sm"
              {...register("name")}
            />
          </Flex>
        </Flex>
      </Grid>
      <Flex width="100%" justifyContent="flex-end">
        <Spacer />
        {mode === "create" && (
          <Button
            isDisabled={!file || !isValid}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleCreateContent}
            isLoading={mutationCreateContent.isPending}
          >
            Add Content
          </Button>
        )}
        {mode === "update" && (
          <Button
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleUpdateContent}
          >
            Update Content
          </Button>
        )}
      </Flex>
    </>
  );
};

interface AudioPreviewProps {
  src: string;
}

const AudioPreview: FC<AudioPreviewProps> = ({ src }) => {
  return <audio src={src} controls></audio>;
};
