import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../app/authConfig";
import { Button, Flex } from "@chakra-ui/react";
import {createBrowserRouter, RouterProvider, useNavigate} from "react-router-dom";
import {DisplayQrContent} from "../pages/DisplayQrContent/DisplayQrContent";
import {FC, useEffect} from "react";

const createRouter = () => 
    createBrowserRouter([
        {
            path: "/",
            element : <UnauthenticatedLayout />
        },
        {
            path: "/qrContent/:id/c/:chapterId",
            element: (
                <DisplayQrContent />
            )
        },
        {
            path: "*",
            element: <RedirectToRoot />
        }
    ])

export const UnauthenticatedApp : FC = () => {
  return <RouterProvider router={createRouter()} />
};

export const UnauthenticatedLayout : FC = () =>  {
    const { instance } = useMsal();
    
    useEffect(() => {
        //instance.loginRedirect();
        if(instance.getActiveAccount() == null){
            instance.loginRedirect();
        }
    }, [instance]);
    
    return (
        <Flex w="100%" h="100%" align="center" justify="center">
            <Button
                colorScheme="yellow"
                onClick={() => instance.loginRedirect(loginRequest)}
            >
                Login
            </Button>
        </Flex>
    );
}

function RedirectToRoot() {
    let navigate = useNavigate();

    useEffect(() => {
        navigate("/");
    }, [navigate]);

    return null; // this component does not render anything
}