import { ContentCategory } from "pages/Library/ContentCategoryEnum";
import { ResourceLibrarySelector } from "./ResourceLibrarySelector";
import { Content, ContentLibraryBean } from "api/model";
import { FC, useState } from "react";
import { Button, Flex, Spacer, useToast } from "@chakra-ui/react";
import {
  useMutationCreateContent,
  useMutationUpdateContent,
} from "state/remoteState";
import { useProjectChapterId } from "state/localState";
import { initialConfigurationsFromResource } from "api/Configurations";

interface ResourceLibraryProps {
  content: Content;
  category: ContentCategory;
  onSuccess: () => void;
}

export const ResourceLibrary: FC<ResourceLibraryProps> = ({
  content,
  category,
  onSuccess,
}) => {
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const { projectId, chapterId } = useProjectChapterId();
  const toast = useToast();

  const [resource, setResource] = useState<ContentLibraryBean>();

  const mode = content.id ? "update" : "create";
  const handleSelect = (resource: ContentLibraryBean) => {
    console.log("Selected resource id", resource.id);
    setResource(resource);
  };

  const handleCreateContent = async () => {
    if (!resource) {
      return;
    }

    let configurations = await initialConfigurationsFromResource(resource);

    mutationCreateContent.mutate(
      {
        projectId,
        chapterId,
        content: {
          ...content,
          description: resource.name ?? resource.filename,
          sourceObjectId: resource.id,
          configurations: { ...content.configurations, ...configurations },
        },
      },
      {
        onSuccess: () => {
          toast({
            status: "success",
            title: "Content created.",
            description: `Content "${content.name ?? content.description ?? resource.name ?? resource.filename ?? "unnamed"}" has been created.`,
          });
          onSuccess();
        },
      },
    );
  };

  const handleUpdateContent = async () => {
    console.log("Updating content", content);
    if (!resource) {
      return;
    }
    // Fetch the image from the server using resource.sasUri, create a hiddent image element and set the src to the sasUri
    // then calculate size x: img.naturalWidth, y: img.naturalHeight amd calculate configurations using the initial2DConfigurations(size).

    let configurations = await initialConfigurationsFromResource(resource);

    mutationUpdateContent.mutate(
      {
        projectId,
        chapterId,
        content: {
          ...content,
          description: resource.name ?? resource.filename,
          sourceObjectId: resource.id,
          configurations: { ...content.configurations, ...configurations },
        },
      },
      {
        onSuccess: () => {
          toast({
            status: "success",
            title: "Content updated.",
            description: `Content "${content.name ?? content.description ?? resource.name ?? resource.filename ?? "unnamed"}" has been updated.`,
          });
          onSuccess();
        },
      },
    );
  };

  return (
    <>
      <ResourceLibrarySelector category={category} onSelect={handleSelect} />
      <Flex width="100%" justifyContent="flex-end" mt="4">
        <Spacer />
        {mode === "create" && (
          <Button
            isDisabled={!resource}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleCreateContent}
            isLoading={mutationCreateContent.isPending}
          >
            Add Content
          </Button>
        )}
        {mode === "update" && (
          <Button
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleUpdateContent}
          >
            Update Content
          </Button>
        )}
      </Flex>
    </>
  );
};
