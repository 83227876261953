import { FC, useEffect } from "react";
import {
  ClampToEdgeWrapping,
  DoubleSide,
  LinearFilter,
  TextureLoader,
} from "three";
import { useLoader } from "@react-three/fiber";
import { Anchor } from "api/model";

interface Props {
  marker: Anchor;
}

export const MarkerImage: FC<Props> = ({ marker }) => {
  const texture = useLoader(TextureLoader, marker.sources!.main);

  useEffect(() => {
    if (!texture) return;
    texture.generateMipmaps = false;
    texture.wrapS = texture.wrapT = ClampToEdgeWrapping;
    texture.minFilter = LinearFilter;
    texture.needsUpdate = true;
  }, [
    texture.generateMipmaps,
    texture.wrapS,
    texture.wrapT,
    texture.minFilter,
    texture.needsUpdate,
  ]);

  return (
    <mesh>
      <planeGeometry attach="geometry" />
      <meshBasicMaterial
        attach="material"
        map={texture}
        transparent={true}
        side={DoubleSide}
      />
    </mesh>
  );
};
