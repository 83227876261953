import { useRef, useState } from "react";
import { useEffect } from "react";
import { Chapters } from "./Chapters";
import { AnchorType, ProjectType, QrCodeConfig } from "api/model";
import { useProjectId } from "state/localState";
import { useDebouncedCallback } from "use-debounce";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Image,
  Textarea,
  useOutsideClick,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { CustomTag } from "./components/CustomTag";
import { IoAddSharp, IoArrowForwardSharp } from "react-icons/io5";
import { BsChevronDown, BsImage } from "react-icons/bs";
import { BiShuffle } from "react-icons/bi";
import { FiCopy, FiDownload, FiMoreVertical } from "react-icons/fi";
import { useDebounce } from "use-debounce";
import { AiOutlineDelete } from "react-icons/ai";
import {
  useMutationCreateQRCode,
  useMutationUpdateProject,
  useMutationPublishProject,
  useMutationUpdateProjectThumbnail,
  useQueryProject,
  useQueryMe,
  useQueryUsersGroups,
} from "state/remoteState";
import { AccessType, ProjectBean, Status } from "api/model";
import { PublishPopover } from "./PublishPopover";
import { useForm } from "react-hook-form";
import { HelpIconButton } from "pages/HelpIconButton";

interface ProjectForm {
  name: string;
  accessType: AccessType;
  categories: string[];
  description: string;
  type: ProjectType;
  offline: boolean;
}
import { useTranslation } from "../../layout/TranslationContext";
import { translatePage, translateText } from "../../layout/translatePage";
import { OfflineContentWarning } from "./components/OfflineContentWarning";
export const Project = () => {
  const projectId = useProjectId();
  const { data: project } = useQueryProject(projectId);
  const mutationUpdateProject = useMutationUpdateProject();
  //const [chapters, setChapters] = useState<any[]>([]);
  //const chapterKey = projectId;
  const [projectThumbnail, setProjectThumbnail] = useState("");
  const [projectQrCodes, setProjectQrCodes] = useState<QrCodeConfig[]>([]);
  const { data: me } = useQueryMe();
  const { data: userGroups } = useQueryUsersGroups(me!.subscriptions![0]);
  const accessGroups: string[] = userGroups
    ? [...userGroups.map((group) => group.name ?? "")]
    : ["you do not have groups yet!"];
  const mutationCreateQRCode = useMutationCreateQRCode();
  const mutationUpdateProjectThumbnail = useMutationUpdateProjectThumbnail();
  const [categoryMenuOpen, setCategoryMenuOpen] = useState(false);
  const [, setEditorMenuOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isPublished, setIsPublished] = useState(
    project?.status === Status.Published,
  );
  const [debouncedNewCategoryName] = useDebounce(newCategoryName, 1000);
  const dropdownRef = useRef<any>();
  const dropdownUserRef = useRef<any>();
  const categoryRef = useRef<any>();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState<
    string | null | undefined
  >(null);
  const [isUserGroupsOpen, setIsUserGroupsOpen] = useState(false);
  const mutationPublishProject = useMutationPublishProject();
  const [changes, isChanges] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);
  const showTranslatedToast = async (
    title: string,
    message: string,
    language: string,
  ) => {
    const translatedTitle = await translateText(title, language);
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedTitle,
      description: translatedMessage,
    });
  };

  useEffect(() => {
    if (selectedValue) {
      const groupId = userGroups?.find((ug) => ug.name === selectedValue);
      setSelectedGroupId(groupId?.id);
    }
  }, [selectedValue]);
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  
  const handleMouseEnter = () => {
    setIsUserGroupsOpen(true);
  };

  const handleMouseLeave = (event) => {
    const isLeavingMenu = event.relatedTarget?.closest('[role="menu"]');
    if (!isLeavingMenu) {
      setIsUserGroupsOpen(false);
    }
  };

  /*  const handleItemClick = (value) => {
    setSelectedValue(value);
    setIsUserGroupsOpen(false);
  };*/

  const { register, setValue, watch } = useForm<ProjectForm>({
    values: {
      name: project?.name ?? "Untitled Project",
      accessType: project?.accessType ?? AccessType.All,
      categories: project?.categories ?? [],
      description: project?.description ?? "",
      type: project?.type ?? ProjectType.Multiple,
      offline: project?.configurations?.offline ?? false,
    },
    defaultValues: {
      name: project?.name ?? "Untitled Project",
      accessType: project?.accessType ?? AccessType.All,
      categories: project?.categories ?? [],
      description: project?.description ?? "",
      type: project?.type ?? ProjectType.Multiple,
      offline: project?.configurations?.isOfflineProject ?? false,
    },
  });

  const formData = watch();
  useEffect(() => {
    //switching from a specific ug to All registered users does not trigger thus hook bevuase it is always
    const { unsubscribe } = watch((data) => {
      if (!project || data.name!.length < 5 || data.name!.length > 300) return;
      const projectToSave = project;
      projectToSave.name = data.name!;
      projectToSave.accessType = data.accessType!;
      projectToSave.categories = data.categories!.map((category) => category!);
      projectToSave.description = data.description!;
      projectToSave.type = data.type!;
      projectToSave.configurations = {
        isOfflineProject: data.offline ?? false,
      };
      console.log(projectToSave);
      mutationUpdateProjectDebounced(projectToSave);
      isChanges(true);
    });
    return unsubscribe;
  }, [watch, project]);

  useEffect(() => {
    if (!project) return;
    const projectToSave = project;
    projectToSave.userGroupsIds = project.userGroupsIds || [];
    if (
      selectedGroupId &&
      !projectToSave.userGroupsIds.includes(selectedGroupId)
    ) {
      projectToSave.userGroupsIds = [
        ...projectToSave.userGroupsIds,
        selectedGroupId!,
      ];
      mutationUpdateProjectDebounced(projectToSave);
      mutationPublishProject.mutate(projectToSave);
      isChanges(true);
    }
    if (selectedValue === "All registered users") {
      projectToSave.userGroupsIds = [];
      console.log(projectToSave.userGroupsIds);
      mutationUpdateProjectDebounced(projectToSave);
      mutationPublishProject.mutate(projectToSave);
      isChanges(true);
      setSelectedGroupId(null);
    }
  }, [selectedGroupId, selectedValue]); //did not work properly when combining the 2 useEffect hooks
  console.log(selectedValue);
  console.log(selectedGroupId);
  const [, setIsSaved] = useState(true);
  // Debounced version of mutationUpdateProject.mutate
  const mutationUpdateProjectDebounced = useDebouncedCallback(
    (project: ProjectBean) => {
      return mutationUpdateProject.mutate(project, {
        onSuccess: () => {
          setIsSaved(true);
          setTimeout(() => setIsSaved(false), 2000);
        },
        onError: () => {
          showTranslatedToast(
            "Error saving project",
            "Could not save project changes. Please try again.",
            language,
          );
          // toast({
          //   title: "Error saving project",
          //   description: "Could not save project changes. Please try again.",
          //   status: "error",
          //   duration: 9000,
          //   isClosable: true,
          //   position: "top-right",
          // });
        },
      });
    },
    1000,
  );

  useEffect(() => {
    if (!project) return;
    //setChapters(project.containers ?? []);
    setProjectThumbnail(project.thumbnailUri ?? "");
    setProjectQrCodes(project.qrCodeConfigs ?? []);
    setIsPublished(project.status == Status.Published);
  }, [project]);

  const {
    isOpen: isDeleteImageModalOpen,
    onOpen: onDeleteImageModalOpen,
    onClose: onDeleteImageModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (isDeleteImageModalOpen) {
      translatePage(document.body, language);
    }
  }, [isDeleteImageModalOpen]);

  const [dragActive, setDragActive] = useState(false);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => {
      setCategoryMenuOpen(false);
      categoryRef.current.style.display = "none";
    },
  });

  useOutsideClick({
    ref: dropdownUserRef,
    handler: () => {
      setEditorMenuOpen(false);
    },
  });

  const handleAddCategory = () => {
    // @ts-ignore
    categoryRef.current.style.display = "block";
    setCategoryMenuOpen(true);
  };

  const addCategory = () => {
    setValue("categories", [...formData.categories, debouncedNewCategoryName], {
      shouldDirty: true,
    });
    setCategoryMenuOpen(false);
    categoryRef.current.style.display = "none";
    setNewCategoryName("");
  };

  const onDeleteCategory = (name: string) => {
    setValue(
      "categories",
      formData.categories.filter((category) => category !== name),
      { shouldDirty: true },
    );
  };

  const handleFileUpload = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleChange = async () => {
    const thumbnail = hiddenFileInput?.current?.files?.item(0);
    if (!thumbnail || !project) return;
    setImage(URL.createObjectURL(thumbnail));
    mutationUpdateProjectThumbnail.mutate(
      {
        projectId: project.id!,
        thumbnail,
      },
      {
        onSuccess: () => {
          showTranslatedToast(
            "Content added",
            `Thumbnail for project ${project.name} was updated`,
            language,
          );
          // toast({
          //   status: "success",
          //   title: "Content added",
          //   description: `Thumbnail for project ${project.name} was updated`,
          //   isClosable: true,
          //   duration: 9000,
          //   position: "top-right",
          // });
        },
      },
    );
  };

  const handleDeleteImage = () => {
    onDeleteImageModalOpen();
  };

  const handleDeleteImageButton = () => {
    setProjectThumbnail("");
    setImage("");
    if (project) {
      project!.thumbnailObjectId = "";
      project!.thumbnailUri = "";
      console.log(project);
      mutationUpdateProjectDebounced(project);
      //mutationUpdateProject.mutate(project);
    }
    onDeleteImageModalClose();
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setImage(URL.createObjectURL(e.dataTransfer.files[0]));
      setProjectThumbnail(e.dataTransfer.files[0]);
    }

    setDragActive(false);
  };

  const loadQrCode = () => {
    mutationCreateQRCode.mutate(projectId);
    // setProjectQrCodes([...projectQrCodes, data]);
  };

  const downloadQrCode = (qrCodeUrl) => {
    const link = document.createElement("a");
    link.href = qrCodeUrl;
    link.download = "QRCode.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const copyQrLinkToClipboard = (qrLink) => {
    navigator.clipboard
      .writeText(qrLink)
      .then(() => {
        console.log("QR link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy QR link to clipboard", err);
      });
  };

  if (!project) return null;
  return (
    <Flex direction="column" gap="8" pt="6" pl="6" pr="50">
      <Grid
        gridTemplateRows="auto 1fr"
        gap="1"
        alignItems="stretch"
        mt="2"
        width="100%"
      >
        <Flex
          justifyContent="flex-end"
          pb="2"
          color="primary.3"
          alignItems="center"
        >
          {mutationUpdateProject.status === "pending" ? "Saving..." : "Saved"}
        </Flex>

        <GridItem justifySelf="end">
          {/* Temporarly disabled. TODO: enable when needed */}
          <Button variant="outline" onClick={onOpen} display="none">
            Preview
          </Button>

          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent width="80%" maxWidth="800px" p="10">
              <ModalCloseButton />
              <ModalBody>
                <Flex direction="column" align="center" justify="center">
                  <Text textAlign="center" fontSize="4xl" fontWeight="700">
                    Your Preview is active!
                  </Text>
                  <Text
                    textAlign="center"
                    my="4"
                    fontSize="lg"
                    fontWeight="400"
                  >
                    You can find your project preview in the mobile app. For
                    quick access you can use the QR Code below.
                  </Text>
                  <Box flex="0 0 auto">
                    {projectQrCodes.length === 0 ? (
                      <IconButton
                        aria-label="Add QR code"
                        icon={<IoAddSharp />}
                        variant="outline"
                        size="sm"
                        onClick={loadQrCode}
                      />
                    ) : (
                      projectQrCodes.map((config) => (
                        <Box key={config.id}>
                          <Image
                            src={config.sasUri!}
                            width="200px"
                            height="200px"
                            objectFit="cover"
                          />
                        </Box>
                      ))
                    )}
                  </Box>
                </Flex>
              </ModalBody>
              <ModalFooter justifyContent="center">
                <Button onClick={onClose} px="10">
                  Okay
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <div ref={modalRef}>
            <PublishPopover changes={changes} />
          </div>
        </GridItem>
        <GridItem>
          <Grid templateColumns="1fr 2fr" gap="10" alignItems="stretch">
            <GridItem width="100%" height="100%">
              {projectThumbnail || image ? (
                <Box position="relative">
                  <Image src={projectThumbnail ? projectThumbnail : image} />
                  <IconButton
                    aria-label="delete image"
                    icon={<AiOutlineDelete />}
                    position="absolute"
                    top="10px"
                    right="10px"
                    backgroundColor="primary.0"
                    color="black"
                    borderRadius="50%"
                    _hover={{ backgroundColor: "primary.0" }}
                    onClick={() => handleDeleteImage()}
                  />
                  <Modal
                    isOpen={isDeleteImageModalOpen}
                    onClose={onDeleteImageModalClose}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Delete this image?</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Text>
                          Are you sure you want to delete this image, you cannot
                          undo this action
                        </Text>
                      </ModalBody>
                      <ModalFooter gap="4">
                        <Button
                          onClick={() => onDeleteImageModalClose()}
                          size="md"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            handleDeleteImageButton();
                          }}
                          size="md"
                          colorScheme="red"
                        >
                          Delete
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Box>
              ) : (
                <>
                  <Button
                    position="relative"
                    onClick={handleFileUpload}
                    width="100%"
                    height="100%"
                    backgroundColor="primary.0"
                    borderRadius="6"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    _hover={{ backgroundColor: "primary.0" }}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  >
                    <Box
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      w="28px"
                      h="28px"
                      borderRadius="50"
                      top="10px"
                      left="10px"
                    >
                      <Icon as={BsImage} color="primary.6" />
                    </Box>
                    <Flex direction="column" align="center">
                      {!dragActive ? (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            backgroundColor="primary.1"
                            w="50px"
                            h="50px"
                            borderRadius="50"
                          >
                            <Icon as={BsImage} color="primary.6" boxSize="6" />
                          </Box>
                          <Text color="primary.6" pt="3">
                            Add thumbnail
                          </Text>
                          <Text color="primary.4">
                            Supports: JPG, JPEG, PNG
                          </Text>
                        </>
                      ) : (
                        <Text color="primary.6">Drop here</Text>
                      )}
                    </Flex>
                  </Button>
                  <Input
                    type="file"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={handleChange}
                  />
                </>
              )}
            </GridItem>

            <GridItem>
              <GridItem>
                <Flex direction="column" gap="4">
                  <Box>
                    <Input
                      variant="unstyled"
                      fontSize="3xl"
                      fontWeight="bold"
                      placeholder="Title"
                      data-nontranslatable={project.name}
                      {...register("name")}
                    />
                    <Flex alignItems="center" gap="3" mt="2">
                      {!isPublished && (
                        <Box
                          width="16px"
                          height="16px"
                          borderRadius="full"
                          bg="primary.1"
                        />
                      )}
                      {isPublished && (
                        <Box
                          width="16px"
                          height="16px"
                          borderRadius="full"
                          bg="green.400"
                        />
                      )}

                      <Text color="primary.4" fontWeight="600" fontSize="md">
                        {isPublished ? "Published" : "Not Published"}
                      </Text>
                    </Flex>
                  </Box>

                  <Grid
                    templateColumns="200px minmax(200px, 70%)"
                    alignItems="center"
                  >
                    <Text color="primary.4" fontWeight="600" fontSize="md">
                      Access
                    </Text>
                    <Menu>
                      <MenuButton
                        as={Button}
                        variant="outline"
                        rightIcon={<BsChevronDown />}
                        borderRadius="10"
                      >
                        <Flex align="center" gap="2">
                          <Text fontWeight="400" fontSize="sm">
                            {selectedValue ||
                              (formData.accessType === AccessType.All
                                ? "Anyone with the App Link QR Code"
                                : project.userGroupsIds!.length === 1
                                  ? userGroups?.filter(
                                      (ug) =>
                                        ug.id === project?.userGroupsIds![0],
                                    )[0]?.name
                                  : project.userGroupsIds!.length > 1
                                    ? userGroups
                                        ?.filter((ug) =>
                                          project.userGroupsIds!.includes(
                                            ug.id!,
                                          ),
                                        )
                                        .map((ug) => ug.name)
                                        .join(" - ")
                                    : "All registered users")}
                          </Text>
                        </Flex>
                      </MenuButton>
                      <MenuList
                        width="100%"
                        onMouseLeave={handleMouseLeave}
                        style={{ position: "relative" }}
                      >
                        <MenuItem
                          onClick={() => {
                            setValue("accessType", AccessType.All, {
                              shouldDirty: true,
                            });
                            setSelectedValue(
                              "Anyone with the App Link QR Code",
                            );
                          }}
                        >
                          <Flex direction="column">
                            <Flex align="center" gap="2">
                              <Text fontSize="sm">
                                Anyone with the App Link QR Code
                              </Text>
                            </Flex>
                          </Flex>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setValue("accessType", AccessType.MembersOnly, {
                              shouldDirty: true,
                            });
                            setSelectedValue("All registered users");
                          }}
                        >
                          <Flex direction="column">
                            <Flex align="center" gap="2">
                              <Text fontSize="sm">All registered users</Text>
                            </Flex>
                          </Flex>
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <Flex direction="column">
                            <Flex align="center" gap="2">
                              <Text fontSize="sm">User Groups</Text>
                            </Flex>
                          </Flex>
                        </MenuItem>
                        {isUserGroupsOpen && (
                          <MenuList
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "70%",
                            }}
                          >
                            {accessGroups.map((group, index) => (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  setValue(
                                    "accessType",
                                    AccessType.MembersOnly,
                                    {
                                      shouldDirty: true,
                                    },
                                  );
                                  setSelectedValue(group);
                                }}
                              >
                                <Flex direction="column">
                                  <Flex align="center" gap="2">
                                    <Text fontSize="sm">{group}</Text>
                                  </Flex>
                                </Flex>
                              </MenuItem>
                            ))}
                          </MenuList>
                        )}
                      </MenuList>
                    </Menu>

                    {/* <Flex gap="2">
                    <Select
                      fontWeight="400"
                      fontSize="sm"
                      value={projectAccessType}
                      placeholder="All Company Users"
                      onChange={(e) => setProjectAccessType(e.target.value)}
                      pointerEvents="none"
                    >
                      <option value="All">Anyone</option>
                      <option value="MembersOnly">All Company Users</option>
                    </Select>
                  </Flex> */}
                  </Grid>

                  <Grid
                    templateColumns="200px minmax(200px, 70%)"
                    alignItems="center"
                  >
                    <Text color="primary.4" fontWeight="600" fontSize="md">
                      Project Category
                    </Text>
                    <Flex ref={dropdownRef as any}>
                      {formData.categories.map((category, i) => (
                        <CustomTag
                          key={i}
                          name={category}
                          menuItem={false}
                          onDelete={onDeleteCategory}
                        />
                      ))}
                      {/*<CustomTag name="Museum" />*/}
                      <Menu isOpen={categoryMenuOpen}>
                        <MenuButton
                          width="0px"
                          visibility="hidden"
                        ></MenuButton>
                        <Input
                          ref={categoryRef}
                          display="none"
                          placeholder="type in a category"
                          ml="-8px"
                          value={newCategoryName}
                          width="140px"
                          size="sm"
                          borderRadius="10"
                          onClick={() => setCategoryMenuOpen(true)}
                          onChange={(e) => setNewCategoryName(e.target.value)}
                        />
                        <MenuList>
                          <Text
                            fontSize="xs"
                            color="primary.4"
                            fontWeight="600"
                            ml="4"
                            my="1"
                          >
                            Select or create Category
                          </Text>
                          {debouncedNewCategoryName &&
                            !formData.categories.includes(
                              debouncedNewCategoryName,
                            ) && (
                              <MenuItem onClick={() => addCategory()}>
                                <Flex align="center" gap="2" ml="1" mt="2">
                                  <Text
                                    fontSize="xs"
                                    fontWeight="600"
                                    color="primary.4"
                                  >
                                    Create
                                  </Text>
                                  <CustomTag
                                    name={debouncedNewCategoryName}
                                    menuItem={true}
                                  />
                                </Flex>
                              </MenuItem>
                            )}
                          {formData.categories.map((category, i) => (
                            <MenuItem key={i}>
                              <Flex align="center">
                                <Flex justify="center">
                                  <Icon as={FiMoreVertical} />
                                  <Icon as={FiMoreVertical} ml="-2" />
                                </Flex>
                                <CustomTag name={category} menuItem={true} />
                              </Flex>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                      <IconButton
                        aria-label="Add"
                        size="sm"
                        variant="outline"
                        icon={<IoAddSharp />}
                        onClick={handleAddCategory}
                        borderRadius="10"
                      />
                    </Flex>
                  </Grid>

                  <Grid templateColumns="200px minmax(200px, 70%)">
                    <Text color="primary.4" fontWeight="600" fontSize="md">
                      Description
                    </Text>
                    <Textarea
                      fontWeight="400"
                      fontSize="sm"
                      placeholder="What is the project about?"
                      {...register("description")}
                    />
                  </Grid>
                  {project.anchorType === AnchorType.Image && (
                    <Grid templateColumns="200px minmax(200px, 70%)">
                      <Text color="primary.4" fontWeight="600" fontSize="md">
                        Navigation Mode
                      </Text>
                      <Menu>
                        {/*// @ts-ignore*/}
                        <MenuButton
                          as={Button}
                          variant="outline"
                          borderRadius="10"
                          rightIcon={<BsChevronDown />}
                        >
                          <Flex align="center" gap="2">
                            <Icon as={BiShuffle} />
                            <Text fontWeight="400" fontSize="sm">
                              {formData.type === ProjectType.Ordered
                                ? "Step by step"
                                : "Random"}
                            </Text>
                          </Flex>
                        </MenuButton>
                        <MenuList width="100%">
                          <MenuItem
                            onClick={() =>
                              setValue("type", ProjectType.Ordered, {
                                shouldDirty: true,
                              })
                            }
                            width="100%"
                          >
                            <Flex direction="column">
                              <Flex align="center" gap="2">
                                <Icon as={IoArrowForwardSharp} />
                                <Text
                                  fontSize="sm"
                                  fontWeight="600"
                                  color="black"
                                >
                                  Step by step
                                </Text>
                              </Flex>
                              <Text
                                color="primary.4"
                                fontSize="sm"
                                fontWeight="500"
                              >
                                Users can view chapters only in a certain order
                              </Text>
                            </Flex>
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              setValue("type", ProjectType.Multiple, {
                                shouldDirty: true,
                              })
                            }
                          >
                            <Flex direction="column">
                              <Flex align="center" gap="2">
                                <Icon as={BiShuffle} />
                                <Text
                                  fontSize="sm"
                                  fontWeight="600"
                                  color="black"
                                >
                                  Random
                                </Text>
                              </Flex>
                              <Text
                                color="primary.4"
                                fontSize="sm"
                                fontWeight="500"
                              >
                                Users can view chapters in any order
                              </Text>
                            </Flex>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Grid>
                  )}
                  {project.anchorType === AnchorType.Image && (
                    <Grid templateColumns="200px minmax(200px, 70%)">
                      <Text color="primary.4" fontWeight="600" fontSize="md">
                        App Link
                      </Text>

                      <Flex
                        borderRadius="10"
                        border="1px solid #E2E8F0"
                        p="3"
                        direction="row"
                        align="center"
                        width="100%"
                        gap="4"
                      >
                        <Box flex="0 0 auto" alignSelf="start">
                          {projectQrCodes.length === 0 ? (
                            <IconButton
                              aria-label="Add QR code"
                              icon={<IoAddSharp />}
                              variant="outline"
                              size="sm"
                              borderRadius="10"
                              onClick={loadQrCode}
                            />
                          ) : (
                            projectQrCodes.map((config) => (
                              <div key={config.id}>
                                <Image
                                  src={config.sasUri!}
                                  width="100px"
                                  height="100%"
                                  objectFit="cover"
                                />
                              </div>
                            ))
                          )}
                        </Box>

                        {projectQrCodes.length > 0 && (
                          <>
                            <Box flex="1" textAlign="left">
                              <Text
                                color="primary.6"
                                fontWeight="600"
                                fontSize="sm"
                              >
                                Download QR Code or copy link
                              </Text>
                              <Text
                                color="primary.4"
                                fontWeight="600"
                                fontSize="sm"
                              >
                                Available for everybody who has access
                              </Text>
                              <Box
                                borderRadius="10"
                                border="1px solid #E2E8F0"
                                p="2"
                                mt="2"
                              >
                                <Text
                                  color="primary.4"
                                  fontWeight="400"
                                  fontSize="sm"
                                >
                                  {projectQrCodes[0].qrLink}
                                </Text>
                              </Box>
                            </Box>

                            <Box flex="0 0 auto">
                              <Flex direction="column" align="center">
                                {projectQrCodes.length > 0 &&
                                  projectQrCodes.map((config) => (
                                    <div key={config.id}>
                                      <IconButton
                                        aria-label="Download QR code"
                                        icon={<FiDownload />}
                                        variant="outline"
                                        size="md"
                                        onClick={() =>
                                          downloadQrCode(config.sasUri)
                                        }
                                      />
                                    </div>
                                  ))}

                                {projectQrCodes.length > 0 && (
                                  <>
                                    <IconButton
                                      aria-label="Copy QR code link"
                                      icon={<FiCopy />}
                                      variant="outline"
                                      size="md"
                                      mt="3"
                                      onClick={() =>
                                        copyQrLinkToClipboard(
                                          projectQrCodes[0].qrLink,
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </Flex>
                            </Box>
                          </>
                        )}
                      </Flex>
                    </Grid>
                  )}
                  <Grid
                    templateColumns="200px minmax(200px, 70%)"
                    alignItems="center"
                  >
                    <Text color="primary.4" fontWeight="600" fontSize="md">
                      Offline Option
                    </Text>
                    <Menu>
                      <MenuButton
                        as={Button}
                        variant="outline"
                        rightIcon={<BsChevronDown />}
                        borderRadius="10"
                      >
                        <Flex align="center" gap="2">
                          <Text fontWeight="400" fontSize="sm">
                            {project.configurations?.offline
                              ? "1 Day"
                              : "Never"}
                          </Text>
                        </Flex>
                      </MenuButton>
                      <MenuList width="100%" style={{ position: "relative" }}>
                        <MenuItem
                          onClick={() => {
                            setValue("offline", false, {
                              shouldDirty: true,
                            });
                          }}
                        >
                          <Flex direction="column">
                            <Flex align="center" gap="2">
                              <Text fontSize="sm">Never</Text>
                            </Flex>
                          </Flex>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setValue("offline", true, {
                              shouldDirty: true,
                            });
                          }}
                        >
                          <Flex direction="column">
                            <Flex align="center" gap="2">
                              <Text fontSize="sm">1 Day</Text>
                            </Flex>
                          </Flex>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Grid>
                </Flex>
              </GridItem>
            </GridItem>
          </Grid>

          <OfflineContentWarning project={project} />
        </GridItem>
      </Grid>
      <Chapters />

      {/* <Chapters chapters={chapters} /> */}
      <HelpIconButton
        link="https://www.nextcreate.com/help/project-settings"
        isFloating={true}
      />
    </Flex>
  );
};
