import { FC, ReactNode } from "react";
import { Layout } from "layout/Layout";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Error } from "pages/Error";
import { Home } from "pages/Home/Home";
import { Project } from "pages/Project/Project";
import { Users } from "pages/Users/Users";
import { Chapter } from "pages/Chapter/Chapter";
import { Register } from "pages/Register/Register";
import { UserBean } from "api/model";
import { useQueryMe } from "state/remoteState";
import { Analytics } from "pages/analytics/Analytics";
import { DisplayQrContent } from "../pages/DisplayQrContent/DisplayQrContent";
import { Library } from "pages/Library/Library";

interface RequireAuthProps {
  children?: ReactNode;
}

const RequireAuth: FC<RequireAuthProps> = ({ children }) => {
  const { data: user } = useQueryMe();
  if (user) {
    if (
      (user.roles?.indexOf("Author") ?? -1) >= 0 ||
      window.location.href.indexOf("qrContent") >= 0
    ) {
      return <>{children}</>;
    }
  }
  if (window.location.hostname == "localhost") {
    window.location.href = "https://admin.stage.nextcreate.com";
    return;
  }
  window.location.href =
    window.location.protocol +
    "//" +
    window.location.hostname.replace("editor", "admin");
  return;
};

interface IsRegisteredUserProps {
  children?: ReactNode;
  user?: UserBean;
}

const IsRegisteredUser: FC<IsRegisteredUserProps> = ({ children, user }) => {
  const navigate = useNavigate();
  if (user) {
    navigate(-1);
    return null;
  }
  return <>{children}</>;
};

const createRouter = () =>
  createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <Error />,
      children: [
        {
          path: "projects/:id/chapters/:chapterId",
          element: (
            <RequireAuth>
              <Chapter />
            </RequireAuth>
          ),
        },
        {
          path: "/",
          element: (
            <RequireAuth>
              <Home />
            </RequireAuth>
          ),
        },
        {
          path: "projects/:id",
          element: (
            <RequireAuth>
              <Project />
            </RequireAuth>
          ),
        },
        {
          path: "users",
          element: (
            <RequireAuth>
              <Users />
            </RequireAuth>
          ),
        },
        {
          path: "analytics",
          element: (
            <RequireAuth>
              <Analytics />
            </RequireAuth>
          ),
        },
        {
          path: "library",
          element: (
            <RequireAuth>
              <Library />
            </RequireAuth>
          ),
        },
        {
          path: "register",
          element: (
            <IsRegisteredUser>
              <Register />
            </IsRegisteredUser>
          ),
        },
      ],
    },
    {
      path: "/qrContent/:id/c/:chapterId",
      element: <DisplayQrContent />,
    },
  ]);

export const AuthenticatedApp: FC = () => {
  if (
    sessionStorage.getItem("lastPageVisitedProject") != undefined &&
    window.location.href.indexOf("qrContent") == -1
  ) {
    window.location.href =
      "/qrContent/" +
      sessionStorage.getItem("lastPageVisitedProject") +
      "/c/" +
      sessionStorage.getItem("lastPageVisitedChapter");
  }
  return <RouterProvider router={createRouter()} />;
};
