import { Canvas, useThree } from "@react-three/fiber";
import { GizmoHelper, GizmoViewport, OrbitControls } from "@react-three/drei";
import { MarkerImage } from "pages/Chapter/components/Canvas/CanvasContent/MarkerImage";
import {
  useProjectChapterId,
  useStateActiveAnchor,
  useStateActiveContent,
} from "state/localState";
import { Selector } from "pages/Chapter/components/Canvas/CanvasContent/Selector";
import { Lighting } from "./Lighting";
import { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { ControlButtons } from "./ControlButtons";
import { Transformer } from "./CanvasContent/Transformer";
import {
  updateContentLocally,
  useCurrentChapter,
  useMutationUpdateContent,
} from "state/remoteState";
import { CameraPosition, Content, ContentType, Orientation } from "api/model";
import { Configurations } from "api/Configurations";
import { useQueryClient } from "@tanstack/react-query";
import { useDebouncedCallback } from "use-debounce";

const Body = () => {
  const { projectId, chapterId } = useProjectChapterId();
  const { data: chapter } = useCurrentChapter();
  const content = useStateActiveContent();
  const queryClient = useQueryClient();
  const mutationUpdateContent = useMutationUpdateContent();
  const updateContentDebounced = useDebouncedCallback((content: Content) => {
    mutationUpdateContent.mutate({ projectId, chapterId, content });
  }, 500);
  const [activeAnchor] = useStateActiveAnchor();
  const { camera } = useThree();
  const [enableOrbitControls, setEnableOrbitControls] = useState(false);

  useEffect(() => {
    const cameraPosition = chapter?.cameraPosition ?? CameraPosition.Default;

    if (!camera) return;
    const distanceMultiplier = 2.8;
    switch (cameraPosition) {
      case "top":
        setEnableOrbitControls(false);
        camera.position.set(0, 3 * distanceMultiplier, 0);
        camera.lookAt(0, 0, 0);
        break;
      case "front":
        setEnableOrbitControls(false);
        camera.position.set(0, 0.5, 3 * distanceMultiplier);
        camera.lookAt(0, 0.5, 0);
        break;
      case "side":
        setEnableOrbitControls(false);
        camera.position.set(3 * distanceMultiplier, 0.5, 0);
        camera.lookAt(0, 0.5, 0);
        break;
      default:
        setEnableOrbitControls(true);
        camera.position.set(
          1.75 * distanceMultiplier,
          1 * distanceMultiplier,
          2 * distanceMultiplier,
        );
        camera.lookAt(0, 0.5, 0);
        break;
    }
  }, [camera, chapter?.cameraPosition]);

  const updateContentConfigurations = (configurations: Configurations) => {
    if (!content) return;
    const updatedConfigurations = {
      ...content.configurations,
      ...configurations,
    };
    const updatedContent = {
      ...content,
      configurations: updatedConfigurations,
    };
    updateContentLocally(queryClient, projectId, chapterId, updatedContent);
    updateContentDebounced(updatedContent);
  };

  // Write Environment change here
  if (!chapter) return null;
  return (
    <>
      <Lighting lightingType={content?.configurations?.lightingType} />
      <gridHelper args={[10, 10]} />
      {activeAnchor && (
        <Transformer
          configurations={activeAnchor.configurations as Configurations}
          orientation={chapter.orientation ?? Orientation.Flat}
        >
          <MarkerImage marker={activeAnchor} />
        </Transformer>
      )}
      {content && (
        <Transformer
          configurations={content.configurations as Configurations}
          orientation={chapter?.orientation ?? Orientation.Flat}
          onConfigurationsChange={
            content?.type === ContentType.Value3DModel
              ? updateContentConfigurations
              : undefined
          }
        >
          <Selector content={content} />
        </Transformer>
      )}
      {enableOrbitControls && <OrbitControls makeDefault />}
      <color attach="background" args={["white"]} />
      <GizmoHelper
        alignment="bottom-left" // widget alignment within scene
        margin={[80, 80]} // widget margins (X, Y)
      >
        <GizmoViewport
          axisColors={["#6D7D84", "#6D7D84", "#6D7D84"]}
          hideNegativeAxes={true}
        />
      </GizmoHelper>
    </>
  );
};

export const CanvasARViewer = () => {
  return (
    <Box position="relative">
      <Canvas
        id="canvas"
        shadows
        gl={{
          powerPreference: "low-power",
          antialias: true,
          stencil: true,
          depth: true,
          preserveDrawingBuffer: true,
        }}
        camera={{
          aspect: 0.1,
          fov: 50,
          near: 0.1,
          far: 100,
        }}
        style={{ border: "1px solid #E2E8F0", borderRadius: "6px" }}
      >
        <Body />
      </Canvas>
      <Box
        pos="absolute"
        left="50%"
        top="2"
        style={{ transform: "translateX(-50%)" }}
        zIndex={100}
      >
        <ControlButtons />
      </Box>
    </Box>
  );
};
