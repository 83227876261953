import { Box, chakra, Flex, Text, useRadio } from "@chakra-ui/react";

export const CustomRadio = (props) => {
  // eslint-disable-next-line react/prop-types
  const { icon, value, children, ...radioProps } = props;

  const { getInputProps, getRadioProps, htmlProps, getLabelProps } =
    useRadio(radioProps);

  const styles = {
    input: {
      position: "relative",
      "&:checked": {
        content: "\f00c",
        color: "red",
        backgroundColor: "red",
      },
    },
  };

  // useEffect(() => {
  //   console.log(icon, value, children, ...radioProps)
  // },[])

  return (
    <chakra.label
      {...htmlProps}
      cursor="pointer"
      width="100%"
      style={{ display: "flex", justifyContent: "space-between" }}
      paddingRight="2"
      paddingLeft="2"
    >
      <Box {...getRadioProps()} p={1} rounded="full">
        <Flex {...getLabelProps()} width="100%" gap="1" align="center">
          {icon}
          <Text fontWeight="500" fontSize="sm">
            {children}
          </Text>
        </Flex>
      </Box>
      {/*// @ts-ignore*/}
      <input {...getInputProps()} style={styles.input} value={value} />
    </chakra.label>
  );
};
