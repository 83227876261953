import { defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: "50",
    padding: "10px 23px",
  },
  variants: {
    primary: {
      backgroundColor: "primary.6",
      color: "white",
      _hover: {
        backgroundColor: "primary.5",
      },
      _active: {
        backgroundColor: "primary.7",
      },
      _disabled: {
        backgroundColor: "#AEAEAE",
      },
    },
    secondary: {
      backgroundColor: "white",
      color: "primary.6",
      borderColor: "primary.6",
      borderWidth: "1px",
      _hover: {
        backgroundColor: "primary.0",
      },
      _active: {
        backgroundColor: "primary.2",
      },
      _disabled: {
        color: "#AEAEAE",
        borderColor: "#AEAEAE",
      },
    },
    warning: {
      backgroundColor: "red.50",
      color: "red.500",
      borderColor: "red.500",
      borderWidth: "1px",
      _hover: {
        backgroundColor: "white",
      },
      _active: {
        backgroundColor: "red.100",
      },
      _disabled: {
        backgroundColor: "#AEAEAE",
        color: "white",
      },
    },
    ghost: {
      backgroundColor: "transparent",
      color: "primray.4",
      _hover: {
        backgroundColor: "transparent",
        color: "secondary.3",
      },
      _active: {
        color: "primary.6",
        backgroundColor: "primary.2",
        borderRadius: "50",
      },
    },
  },
  defaultProps: {
    variant: "primary",
  },
});
