import { FC } from "react";
import { Content, ContentType } from "api/model";
import { ContentImage } from "pages/Chapter/components/Canvas/CanvasContent/ContentImage";
import { ContentVideo } from "pages/Chapter/components/Canvas/CanvasContent/ContentVideo";
import { ContentThreeDModel } from "pages/Chapter/components/Canvas/CanvasContent/ContentThreeDModel";
import { ContentWebsite } from "./ContentWebsite";
import { ContentAudio } from "./ContentAudio";
import { ContentVideoURL } from "./ContentVideoURL";
import { ContentSubtitles } from "./ContentSubtitles";
import {ContentWebEditor} from "./ContentWebEditor";

interface Props {
  content: Content;
}

// CanvasContent is a wrapper for content of any type on the canvas.
export const Selector: FC<Props> = ({ content }) => {
  switch (content.type) {
    case ContentType.Image:
      return <ContentImage content={content} />;
    case ContentType.UrlEndpoint:
      return <ContentWebsite content={content} />;
    case ContentType.EditorJs:
      return <ContentWebEditor content={content} />
      //return null;
    case ContentType.Video:
      return <ContentVideo content={content} />;
    case ContentType.Youtube:
    case ContentType.Vimeo:
      return <ContentVideoURL content={content} />;
    case ContentType.Value3DModel:
      return <ContentThreeDModel content={content} />;
    case ContentType.Audio:
      return <ContentAudio />;
    case ContentType.Text2Speech:
      return <ContentAudio />;
    case ContentType.Subtitles:
      return <ContentSubtitles content={content} />;
    default:
      throw new Error("Unknown content type");
  }
};
