import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Selector } from "pages/Chapter/components/Canvas/CanvasContent/Selector";
import { Lighting } from "./Lighting";
import { useEffect, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { useCurrentChapter } from "state/remoteState";
import { Content, ContentType, Orientation } from "api/model";
import { Euler, Vector3 } from "three";
import { ContentImage } from "./CanvasContent/ContentImage";
import { contentTypesVisibleInOnScreenMode } from "../ControlPanel/PanelContent/contentTypeDetails";
import { Transformer } from "./CanvasContent/Transformer";
import { Configurations } from "api/Configurations";
import { rToD } from "utils/utils";

const Body = () => {
  const { data: chapter } = useCurrentChapter();

  const { camera, scene } = useThree();

  const contentToRender = useMemo(
    () =>
      chapter?.content?.filter((content) =>
        contentTypesVisibleInOnScreenMode.includes(content.type!),
      ) ?? [],
    [chapter],
  );

  useEffect(() => {
    if (!camera) return;
    camera.lookAt(0, 0, 0);
    camera.position.set(0, contentToRender.length, 0);
    scene.rotation.set(0, -Math.PI / 2, 0);
  }, [camera, scene, contentToRender]);

  const backgroundImageContent = useMemo(
    () =>
      chapter?.content?.find(
        (content) =>
          content.type === ContentType.Background && content.sourceObjectId,
      ),
    [chapter],
  );

  const backgroundPosition = useMemo(() => {
    switch (backgroundImageContent?.configurations?.alignment) {
      case "top":
        return new Vector3((1 - contentToRender.length) / 2, -0.001, 0);
      case "bottom":
        return new Vector3((1 + contentToRender.length) / 2 - 1, -0.001, 0);
      default:
        return new Vector3(0, -0.001, 0);
    }
  }, [
    contentToRender.length,
    backgroundImageContent?.configurations?.alignment,
  ]);

  const onScreenConfigurations = (content: Content): Configurations => {
    const c = content.configurations!;
    switch (content.type) {
      case ContentType.Image:
      case ContentType.Video:
      case ContentType.Youtube:
      case ContentType.Vimeo:
      case ContentType.UrlEndpoint:
        return {
          ...content.configurations,
          rotation: { ...c.rotation, z: rToD(Math.PI / 2) },
          scale: { x: c.scale.x / 6, y: c.scale.y / 6, z: c.scale.z / 6 },
        };
      default:
        return content.configurations as Configurations;
    }
  };

  if (!chapter) return null;

  return (
    <>
      <Lighting />
      <OrbitControls enableZoom={true} enablePan={false} enableRotate={false} />
      {/* <gridHelper args={[objectsToRender.length, objectsToRender.length]} /> */}
      <color
        attach="background"
        args={[
          chapter.content?.find(
            (content) => content.type === ContentType.Background,
          )?.configurations?.color ?? "white",
        ]}
      />
      {backgroundImageContent && (
        // TOP
        <group
          position={backgroundPosition}
          rotation={new Euler(-Math.PI / 2, 0, Math.PI / 2)}
        >
          <ContentImage content={backgroundImageContent} />
        </group>
      )}
      {contentToRender.map((content, i) => (
        <group key={i} position={[i - contentToRender.length / 2 + 0.5, 0, 0]}>
          <Transformer
            configurations={onScreenConfigurations(content)}
            orientation={Orientation.Flat}
          >
            <Selector content={content as Content} />;
          </Transformer>
        </group>
      ))}
    </>
  );
};

export const CanvasOnscreenViewer = () => {
  return (
    <Box position="relative">
      <Canvas
        id="canvas"
        shadows
        gl={{
          powerPreference: "low-power",
          antialias: true,
          stencil: true,
          depth: true,
          preserveDrawingBuffer: true,
        }}
        style={{ border: "1px solid #E2E8F0", borderRadius: "6px" }}
      >
        <Body />
      </Canvas>
      <Box
        pos="absolute"
        left="50%"
        top="2"
        style={{ transform: "translateX(-50%)" }}
      ></Box>
    </Box>
  );
};
