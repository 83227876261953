import { Select, Box, Text } from "@chakra-ui/react";
import { Content } from "api/model";
import { TransformInputs } from "./TransformInputs";
import { ChangeEvent, FC } from "react";
import { useMutationUpdateContent } from "state/remoteState";
import { useProjectChapterId } from "state/localState";

interface ContentTab3DDetailsProps {
  content: Content;
}

export const ContentList3DDetails: FC<ContentTab3DDetailsProps> = ({
  content,
}) => {
  const contentMutation = useMutationUpdateContent();
  const { projectId, chapterId } = useProjectChapterId();

  const handleAnimationSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const newAnimation = e.target.value;
    // setSelectedAnimation(newAnimation);
    content.configurations!.threeD!.selectedAnimation = newAnimation;
    console.log("Selected animation", newAnimation);
    contentMutation.mutate({
      projectId: projectId,
      chapterId: chapterId,
      content: content,
    });
  };

  const animations = content.configurations?.threeD?.animations as string[];
  const selectedAnimation = content.configurations?.threeD
    ?.selectedAnimation as string;

  return (
    <Box mt="2">
      {animations && animations.length > 0 && (
        <>
          <Text fontSize="sm" pb="2">
            Animation
          </Text>
          <Select
            value={selectedAnimation}
            fontSize="sm"
            _hover={{ borderColor: "gray.500" }}
            onChange={handleAnimationSelect}
          >
            <option value="">None</option>
            {animations.map((animation) => (
              <option key={animation} value={animation}>
                {animation === "" ? "None" : animation}
              </option>
            ))}
          </Select>
        </>
      )}
      <TransformInputs content={content} />
    </Box>
  );
};
