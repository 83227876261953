import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Content, ContentType } from "api/model";
import {
  useMutationCreateContent,
  useMutationUpdateContent,
  useQueryChapter,
} from "state/remoteState";
import { useProjectChapterId } from "state/localState";
import {
  FC,
  SyntheticEvent,
  useRef,
  useState,
  DragEvent,
  useEffect,
} from "react";
import { WebIO } from "@gltf-transform/core";
import { KHRMaterialsPBRSpecularGlossiness } from "@gltf-transform/extensions";
import { metalRough } from "@gltf-transform/functions";
import { Preview } from "./Preview";
import {
  contentTypeDetails,
  contentTypeToFileType,
  fileTypeToContentType,
} from "../contentTypeDetails";
import { extensionWithDot } from "utils/utils";
import { Configurations } from "api/Configurations";
import { CreateOrUpdateContentProps } from "./DialogCreateOrUpdateContent";
import {
  translateText,
  translatePage,
} from "../../../../../../layout/translatePage";
import { useTranslation } from "../../../../../../layout/TranslationContext";
import { ResourceLibrary } from "./ResourceLibrary";
import { contentCategoryDetailsByContentType } from "pages/Library/ContentCategoryEnum";

// TODO: this one need to be refactored. Too much state.
export const DialogCreateOrUpdateContentUniversal: FC<
  CreateOrUpdateContentProps
> = (props) => {
  const { projectId, chapterId } = useProjectChapterId();
  const { onClose, isOpen } = props;
  const { contentType } = props;
  const [name, setName] = useState<string>(props.content?.name ?? "");
  const [file, setFile] = useState<File | undefined>(props.file);
  const [configurationsFromPreview, setConfigurationsFromPreview] =
    useState<Configurations>({});
  const [dragActive, setDragActive] = useState<boolean>(false);
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const chapter = useQueryChapter(projectId, chapterId).data;
  const [previewURL, setPreviewURL] = useState<string>();
  const toast = useToast();
  const content = props.content ?? ({ type: contentType } as Content);
  const mode = props.content ? "update" : "create";
  const { language, setLanguage } = useTranslation();
  const showTranslatedToast = async (
    message1: string,
    message: string,
    language: string,
  ) => {
    const translatedMessage1 = await translateText(message1, language);
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage1,
      description: translatedMessage,
    });
  };
  const hasTranslated = useRef(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    console.log(props.isOpen);
    if (props.isOpen) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (modalIsOpen && modalRef.current) {
      translatePage(modalRef.current!, language);
    }
  }, [modalIsOpen]);
  useEffect(() => {
    if (file) {
      const u = URL.createObjectURL(file);
      if (previewURL) URL.revokeObjectURL(previewURL);
      setPreviewURL(u);
      return () => URL.revokeObjectURL(u);
    }
    if (mode === "update" && content?.sources?.main) {
      setPreviewURL(content.sources.main);
    }
  }, [file, content?.sources?.main, mode]);

  // TODO: add loading
  if (!chapter) return null;

  const handleFileUpload = () => {
    hiddenFileInput.current?.click();
  };

  const checkAndSetFile = (f: File | null | undefined) => {
    if (!f) return;
    // Browsers don't know all file types, so we need to check the extension when it happens.
    const fileContentType = fileTypeToContentType(
      f.type != "" ? f.type : extensionWithDot(f.name),
    );
    if (fileContentType !== getContentType()) {
      alert(
        `Wrong content type. Expected ${getContentType()}, but got ${fileContentType}`,
      );
      return;
    }
    if (fileContentType === ContentType.Value3DModel) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const data = event.target?.result;
          if (!data) return;
          // Assert that data is an ArrayBuffer
          if (!(data instanceof ArrayBuffer)) {
            throw new Error(
              `Expected ArrayBuffer, but received: ${typeof data}`,
            );
          }
          const io = new WebIO().registerExtensions([
            KHRMaterialsPBRSpecularGlossiness,
          ]);
          const document = await io.readBinary(new Uint8Array(data));
          await document.transform(metalRough());
          const processedGLB = await io.writeBinary(document);
          const blob = new Blob([processedGLB]);
          const processedFile = new File([blob], f.name);
          setFile(processedFile);
        } catch (e) {
          console.error("Couldn't convert the model:", e);
          setFile(f); // Fallback to the original file
        }
      };
      reader.readAsArrayBuffer(f);
      return;
    }
    setFile(f);
  };

  const handleFileSelected = () => {
    const f = hiddenFileInput.current?.files?.item(0);
    checkAndSetFile(f);
  };

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const f = e.dataTransfer.files.item(0);
    checkAndSetFile(f);
  };

  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleCreateContent = () => {
    if (!file) return;
    const newContent: Content = {
      name: name,
      description: file.name,
      type: contentType,
      configurations: configurationsFromPreview,
    };
    // Initial configuration
    mutationCreateContent.mutate(
      {
        projectId,
        chapterId,
        content: newContent,
        file,
      },
      {
        onSuccess: () => {
          showTranslatedToast(
            "Content added",
            `${content.type ? content.type.charAt(0).toUpperCase() + content.type.slice(1) : "Unknown"} content "${name}" has been added`,
            language,
          );
          // toast({
          //   status: "success",
          //   title: "Content added",
          //   description: `${content.type ? content.type.charAt(0).toUpperCase() + content.type.slice(1) : "Unknown"} content "${name}" has been added`,
          // });
          onClose();
        },
      },
    );
  };

  const handleUpdateContent = () => {
    content.name = name;
    if (file) {
      // If the user updates the file of the content, then update its description an spatial configuration
      // obtained from the preview.
      content.description = file.name;
    }
    content.configurations = {
      ...content.configurations,
      ...configurationsFromPreview,
    };
    mutationUpdateContent.mutate(
      {
        projectId,
        chapterId,
        content,
        file,
      },
      {
        onSuccess: () => {
          showTranslatedToast(
            "Content updated",
            `${content.type ? content.type.charAt(0).toUpperCase() + content.type.slice(1) : "Unknown"} content "${name}" has been updated`,
            language,
          );
          // toast({
          //   status: "success",
          //   title: "Content updated",
          //   description: `${content.type ? content.type.charAt(0).toUpperCase() + content.type.slice(1) : "Unknown"} content "${name}" has been updated`,
          // });
          onClose();
        },
      },
    );
  };

  const getContentType = () => {
    return contentType ?? content.type!;
  };

  const { typeName, icon, extensions, info } =
    contentTypeDetails(getContentType());

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="3xl"
      initialFocusRef={modalRef}
    >
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef}>
        <ModalHeader fontSize="36px" fontWeight="700" pt="10">
          {`Add ${typeName}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs variant="soft-rounded">
            <TabList
              backgroundColor="primary.0"
              p="1"
              borderRadius="50"
              gap="4"
              width="fit-content"
              height="40px"
            >
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Upload
              </Tab>
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Library
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px="0" py="6">
                <Grid templateColumns="1fr 1fr" gap="2" minHeight="250px">
                  <GridItem>
                    <>
                      <Button
                        position="relative"
                        onClick={handleFileUpload}
                        width="100%"
                        height="100%"
                        backgroundColor="primary.0"
                        borderRadius="6"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        _hover={{ backgroundColor: "primary.0" }}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                      >
                        <Flex direction="column" align="center">
                          {!dragActive ? (
                            previewURL ? (
                              <Box
                                position="relative"
                                maxHeight="250px"
                                overflow="hidden"
                                px="2"
                              >
                                <Preview
                                  src={previewURL}
                                  contentType={getContentType()}
                                  fileName={
                                    file?.name ??
                                    content.description ??
                                    undefined
                                  }
                                  setConfigurations={
                                    setConfigurationsFromPreview
                                  }
                                />
                                <Text pt="6" color="primary.3">
                                  Drag & Drop or click here
                                </Text>
                              </Box>
                            ) : (
                              <>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  backgroundColor="primary.1"
                                  w="50px"
                                  h="50px"
                                  borderRadius="50"
                                >
                                  <Icon
                                    as={icon}
                                    color="primary.6"
                                    boxSize="6"
                                  />
                                </Box>
                                <Text
                                  color="primary.6"
                                  pt="6"
                                  pb="2"
                                  fontWeight="700"
                                >
                                  Upload {typeName}
                                </Text>
                                <Text color="primary.4">
                                  Supports: {extensions}
                                </Text>
                                <Text color="primary.3" pt="4">
                                  Drag & Drop or click here
                                </Text>
                              </>
                            )
                          ) : (
                            <Text color="primary.6">Drop here</Text>
                          )}
                        </Flex>
                      </Button>
                      <Input
                        type="file"
                        style={{ display: "none" }}
                        ref={hiddenFileInput}
                        accept={contentTypeToFileType(getContentType())}
                        onChange={handleFileSelected}
                      />
                    </>
                  </GridItem>
                  <Flex direction="column" gap="4" pl="2">
                    <Alert
                      status="info"
                      color="secondary.2"
                      backgroundColor="rgba(77, 136, 140, 0.05)"
                      border="1px solid #4D888C"
                      borderRadius="8"
                    >
                      <AlertIcon color="secondary.2" />
                      <Text fontWeight="500" fontSize="xs">
                        {info}
                      </Text>
                    </Alert>
                    <Flex align="center">
                      <Text
                        fontSize="sm"
                        fontWeight="600"
                        width="45%"
                        color="primary.4"
                        pr="1"
                      >
                        Content Name
                      </Text>
                      <Input
                        placeholder="Name"
                        borderRadius="32"
                        size="sm"
                        value={name ?? ""}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Flex>
                  </Flex>
                </Grid>
                <Flex justifyContent="flex-end">
                  {mode === "update" && (
                    <Button
                      _hover={{ backgroundColor: "primary.3" }}
                      variant="primary"
                      onClick={handleUpdateContent}
                      isLoading={mutationUpdateContent.isPending}
                    >
                      Update Content
                    </Button>
                  )}

                  {mode === "create" && (
                    <Button
                      _hover={{ backgroundColor: "primary.3" }}
                      variant="primary"
                      onClick={handleCreateContent}
                      isDisabled={!getContentType() || !file}
                      isLoading={mutationCreateContent.isPending}
                    >
                      Add Content
                    </Button>
                  )}
                </Flex>
              </TabPanel>
              <TabPanel>
                <ResourceLibrary
                  content={content}
                  category={
                    contentCategoryDetailsByContentType(content.type!).category
                  }
                  onSuccess={onClose}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
