import React, {useEffect, useRef} from "react";
import {
  Card,
  CardBody,
  Flex,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Image,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Icon,
  Menu,
  Box,
  useToast,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { BiCamera } from "react-icons/bi";
import { FiMoreHorizontal } from "react-icons/fi";
import { IoOpenOutline } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";
import { MdPhoneIphone } from "react-icons/md";
import { BsImage } from "react-icons/bs";
import { useProjectId } from "state/localState";
import { useMutationDeleteChapter } from "state/remoteState";
import { Container } from "api/model";
import { useTranslation } from './../../../layout/TranslationContext';
import { translatePage, translateText } from './../../../layout/translatePage';
interface ChapterCardProps {
  chapter: Container;
}

export const ChapterCard: React.FC<ChapterCardProps> = ({
  chapter: { id, anchors, name, sortOrder, displayMode },
}) => {
  const projectId = useProjectId();
  const mutationDeleteChapter = useMutationDeleteChapter();
  const toast = useToast();
  const showTranslatedToast = async (message: string, language: string) => {
    const translatedMessage = await translateText(message, language);
    toast({
      description: translatedMessage,
    });
  };
  const handleDeleteChapter = () => {
    mutationDeleteChapter.mutate(
      { projectId, chapterId: id! },
      {
        onSuccess: () => {
          showTranslatedToast("Chapter has been successfully deleted", language)
          // toast({
          //   status: "success",
          //   title: "",
          //   description: "Chapter has been successfully deleted",
          // });
        },
      },
    );
  };
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    const sessionKey = `translated_${language}`;
    if (!sessionStorage.getItem(sessionKey)) {
      translatePage(document.body, language);
      sessionStorage.setItem(sessionKey, 'true'); 
      hasTranslated.current = true; 
    } else {
      translatePage(document.body, language);
    }
  }, [language]);
  const imageSrc = anchors?.at(-1)?.sources?.["main_720p"] ?? "";
  return (
    <Box position="relative" display="flex">
      <Card
        as={NavLink}
        to={"chapters/" + id}
        width="190px"
        borderRadius="8"
        _hover={{
          cursor: "pointer",
          transform: "scale(1.05)",
          transition: "transform .2s ease-in-out",
        }}
      >
        <Box
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="white"
          width="28px"
          height="28px"
          borderRadius="50%"
          top="5px"
          left="5px"
        >
          <Text fontWeight="700" fontSize="sm">
            {(sortOrder ?? 0) + 1}
          </Text>
        </Box>
        <CardBody p="0">
          {imageSrc ? (
            <Image
              borderRadius="8"
              borderBottomLeftRadius="0"
              borderBottomRightRadius="0"
              src={imageSrc}
              fit="cover"
              height="126px"
              width="100%"
              alt="Last Anchor used"
            />
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="126px"
              backgroundColor="primary.0"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="primary.1"
                width="16px"
                height="16px"
                borderRadius="50%"
              >
                <Icon as={BsImage} w={8} h={8} />
              </Box>
            </Box>
          )}

          <Flex direction="column" ml="4" mt="2" mb="2" gap="2"  data-nontranslatable>
            <Text fontSize="md" fontWeight="700" data-nontranslatable={name}
            >
              {name}
            </Text>
            <Tag
              size="sm"
              backgroundColor="gray.300"
              borderRadius="50"
              p="2px 10px"
              maxWidth="max-content"
            >
              <TagLeftIcon
                as={displayMode === "Web" ? MdPhoneIphone : BiCamera}
              />
              <TagLabel fontSize="xs" fontWeight="600" data-nontranslatable={displayMode}>
                {displayMode === "Web" ? "On Screen" : displayMode}
              </TagLabel>
            </Tag>
            <Text fontSize="xs" fontWeight="400" color="blackAlpha.700" data-nontranslatable="Anchor">
              {anchors?.length} Anchor
            </Text>
          </Flex>
        </CardBody>
      </Card>
      <Menu>
        <MenuButton
          position="absolute"
          right="5px"
          top="127px"
          as={IconButton}
          aria-label="Options"
          icon={<FiMoreHorizontal />}
          variant="link"
          color="black"
          zIndex={9999}
          onClick={(e) => e.stopPropagation()}
        />
        <MenuList borderRadius="12px" width="50px">
          <MenuItem
            as={NavLink}
            to={"chapters/" + id}
            icon={<Icon as={IoOpenOutline} boxSize="4" />}
            color="black"
            fontSize="12px"
            fontWeight="500"
            _hover={{ backgroundColor: "secondary.0" }}
          >
            Open
          </MenuItem>
          <MenuItem
            icon={<Icon as={AiOutlineDelete} boxSize="4" />}
            color="black"
            fontSize="12px"
            fontWeight="500"
            _hover={{ backgroundColor: "secondary.0" }}
            onClick={handleDeleteChapter}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
