import * as React from "react";
import {
  Flex,
  Box,
  Image,
  Divider,
  Button,
  Icon,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { NavLink as ReachLink } from "react-router-dom";
import { MdOutlineDashboard, MdOutlineQuestionMark } from "react-icons/md";
import { IoBarChartSharp, IoSettingsOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { BiLibrary } from "react-icons/bi";
import { BsChevronLeft } from "react-icons/bs";

export const Navigator = () => {
  const [minimSideBar, setMinimSideBar] = React.useState<boolean>(false);
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      w={minimSideBar ? "90px" : "270px"}
      transition="width 0.125s ease-in"
    >
      <Box
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="25px"
        height="40px"
        backgroundColor="blackAlpha.50"
        right={minimSideBar ? "-25px" : "0"}
        top="25px"
        borderRadius={"30% 0% 0% 30%"}
        _hover={{ cursor: "pointer" }}
        transform={minimSideBar ? "rotate(180deg)" : "rotate(0)"}
        transition="transform 0.125s ease-in, right 0.125s ease-in"
        onClick={() => setMinimSideBar(!minimSideBar)}
      >
        <Icon as={BsChevronLeft} />
      </Box>
      {minimSideBar ? (
        <>
          <Box
            as={ReachLink}
            to="/"
            width="100%"
            height="90px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              boxSize="30px"
              objectFit="contain"
              alt="nextCreate logo"
              src={`${process.env.PUBLIC_URL}/icons/next_small.png`}
            />
          </Box>
          <Divider color="gray.300" borderBottomWidth="2px" />
          <Flex
            mt="2.5rem"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            width="60px"
            gap="15"
          >
            <IconButton
              aria-label="My Projects"
              icon={<MdOutlineDashboard size="20" />}
              as={ReachLink}
              to="/"
              variant="outline"
              color="primary.4"
              border="0px"
              _hover={{ color: "secondary.3" }}
              _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
            />
            <IconButton
              aria-label="Analytics"
              icon={<IoBarChartSharp size="20" />}
              as={ReachLink}
              to="/analytics"
              variant="outline"
              color="primary.4"
              border="0px"
              _hover={{ color: "secondary.3" }}
              _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
            />
            <IconButton
              aria-label="Users"
              icon={<FiUsers size="20" />}
              as={ReachLink}
              to="#"
              variant="outline"
              color="primary.4"
              border="0px"
              _hover={{ color: "secondary.3" }}
              _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
              isDisabled
            />
            <IconButton
              aria-label="Library"
              icon={<BiLibrary size="20" />}
              as={ReachLink}
              to="/library"
              variant="outline"
              color="primary.4"
              border="0px"
              _hover={{ color: "secondary.3" }}
              _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
            />
            <IconButton
              aria-label="Settings"
              icon={<IoSettingsOutline size="20" />}
              as={ReachLink}
              to="#"
              variant="outline"
              color="primary.4"
              border="0px"
              _hover={{ color: "secondary.3" }}
              _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
              isDisabled
            />
            <IconButton
              aria-label="Support"
              icon={<MdOutlineQuestionMark size="20" />}
              as={ReachLink}
              onClick={() => {
                const windowFeatures = "width=800,height=840,left=200,top=200";
                window.open(
                  "https://www.nextcreate.com/help/support",
                  "newwindow",
                  windowFeatures,
                );
              }}
              variant="outline"
              _hover={{ color: "secondary.3" }}
              color="primary.4"
              border="0px"
              _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
            />
          </Flex>{" "}
        </>
      ) : (
        <>
          <Box
            as={ReachLink}
            to="/"
            w="100%"
            h="90px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              boxSize="60px"
              objectFit="contain"
              alt="nextCreate logo"
              src={`${process.env.PUBLIC_URL}/icons/next.png`}
            />
          </Box>
          <Divider color="gray.300" borderBottomWidth="2px" />
          <Flex
            mt="2.5rem"
            justify="center"
            flexDirection="column"
            align="center"
            gap="15"
          >
            <Button
              variant="ghost"
              leftIcon={<MdOutlineDashboard size="20" />}
              as={ReachLink}
              to="/"
              width="200px"
              height="44px"
              padding="10px 10px 10px 15px"
              css={{ justifyContent: "flex-start", alignItems: "center" }}
              fontSize="sm"
              fontWeight="600"
              color="primary.4"
              _activeLink={{ color: "primary.6" }}
            >
              My Projects
            </Button>
            <Button
              variant="ghost"
              leftIcon={<IoBarChartSharp size="20" />}
              as={ReachLink}
              to="/analytics"
              width="200px"
              height="44px"
              color="primary.4"
              fontSize="sm"
              fontWeight="600"
              padding="10px 10px 10px 15px"
              css={{ justifyContent: "flex-start", alignItems: "center" }}
              _activeLink={{ color: "primary.6" }}
            >
              Analytics
            </Button>
            <Button
              variant="ghost"
              leftIcon={<FiUsers size="20" />}
              as={ReachLink}
              to="/users"
              width="200px"
              height="44px"
              color="primary.4"
              fontSize="sm"
              fontWeight="600"
              padding="10px 10px 10px 15px"
              css={{ justifyContent: "flex-start", alignItems: "center" }}
              _activeLink={{ color: "primary.6" }}
            >
              Users
            </Button>
            <Tooltip label="Coming soon" fontSize="md">
              <Button
                variant="ghost"
                leftIcon={<BiLibrary size="20" />}
                as={ReachLink}
                to="/library"
                width="200px"
                height="44px"
                color="primary.4"
                fontSize="sm"
                fontWeight="600"
                padding="10px 10px 10px 15px"
                css={{ justifyContent: "flex-start", alignItems: "center" }}
              >
                Library
              </Button>
            </Tooltip>
            {/* { me?.email?.includes('@interlake.net') ? */}

            {/* : null } */}
            <Tooltip label="Coming soon" fontSize="md">
              <Button
                variant="ghost"
                leftIcon={<IoSettingsOutline size="20" />}
                as={ReachLink}
                to="#"
                width="200px"
                height="44px"
                color="primary.4"
                fontSize="sm"
                fontWeight="600"
                padding="10px 10px 10px 15px"
                css={{ justifyContent: "flex-start", alignItems: "center" }}
                isDisabled
              >
                Settings
              </Button>
            </Tooltip>

            <Button
              variant="ghost"
              leftIcon={<MdOutlineQuestionMark size="20" />}
              onClick={() => {
                const windowFeatures = "width=800,height=840,left=200,top=200";
                window.open(
                  "https://www.nextcreate.com/help/support",
                  "newwindow",
                  windowFeatures,
                );
              }}
              width="200px"
              height="44px"
              color="primary.4"
              fontSize="sm"
              fontWeight="600"
              padding="10px 10px 10px 15px"
              css={{ justifyContent: "flex-start", alignItems: "center" }}
              _activeLink={{ color: "primary.6" }}
            >
              Support
            </Button>
            {/* <Button
              colorScheme="yellow"
              onClick={() =>
                instance.logoutRedirect({ postLogoutRedirectUri: "/" })
              }
            >
              Signout
            </Button> */}
          </Flex>
        </>
      )}
    </Box>
  );
};
