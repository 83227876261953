// Import the necessary Editor.js tools
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import Quote from "@editorjs/quote";
import ImageTool from "@editorjs/image";
import VideoTool from "@weekwood/editorjs-video";
import axios from "../../../../../../../api/axios";

export const EDITOR_JS_TOOLS = {
  header: {
    class: Header,
    shortcut: "CMD+SHIFT+H",
    config: {
      placeholder: "Enter a header",
      levels: [2, 3, 4],
      defaultLevel: 3,
    },
  },
  list: {
    class: List,
    config: {
      defaultStyle: "unordered",
    },
    inlineToolbar: true,
  },
  embed: {
    class: Embed,
  },
  table: {
    class: Table,
    inlineToolbar: true,
  },
  quote: {
    class: Quote,
    shortcut: "CMD+SHIFT+O",
    config: {
      quotePlaceholder: "Enter a quote",
      captionPlaceholder: "Quote's author",
    },
    inlineToolbar: true,
  },
  image: {
    class: ImageTool,
    config: {
      uploader: {
        uploadByFile: async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          
          
          const response = await axios.post('/resources/webEditor', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          const data = response.data;
          
          return data;
        },
      },
    },
  },
  video: {
    class: VideoTool,
    config: {
      uploader: {
        uploadByFile: async (file) => {
          const formData = new FormData();
          formData.append("file", file);


          const response = await axios.post('/resources/webEditor', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          const data = response.data;

          return data;
        },
      },
      player: {
        controls: true,
        autoplay: false,
      },
    },
  },
};
