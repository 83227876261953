import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Icon,
  IconButton,
  Input,
  Select,
  Spacer,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Content, ContentType } from "api/model";
import { useProjectChapterId } from "state/localState";
import {
  useMutationCreateContent,
  useMutationUpdateContent,
} from "state/remoteState";
import { MdArrowBack, MdSettingsInputComponent } from "react-icons/md";
import { TfiDownload } from "react-icons/tfi";
import axios from "axios";
import {translatePage, translateText} from "../../../../../../../layout/translatePage";
import { useTranslation } from '../../../../../../../layout/TranslationContext';
type FormData = {
  name: string;
  text: string;
  voiceName: string;
  voiceRate: string;
  voiceVolume: string;
  voicePitch: string;
  voiceStyle: string;
};

interface Props {
  content: Content;
  mode: "create" | "update";
  onClose: () => void;
}

const accessToken = "8564249d37e14f5fb0a5beecef431313";
const ttsGeneratorURL =
  "https://germanywestcentral.tts.speech.microsoft.com/cognitiveservices/v1";
const ttsVoicesListURL =
  "https://germanywestcentral.tts.speech.microsoft.com/cognitiveservices/voices/list";
const voicesToUse = [
  "de-DE-KatjaNeural",
  "de-DE-KillianNeural",
  "de-DE-KlarissaNeural",
  "de-DE-KlausNeural",
  "en-US-JennyNeural",
  "en-US-DavisNeural",
  "en-GB-SoniaNeural",
  "en-GB-RyanNeural",
  "it-IT-IsabellaNeural",
  "it-IT-CalimeroNeural",
  "fr-FR-DeniseNeural",
  "fr-FR-HenriNeural",
  "es-ES-AbrilNeural",
  "es-ES-ArnauNeural",
];

interface VoiceProfile {
  Name: string;
  DisplayName: string;
  LocalName: string;
  ShortName: string;
  Gender: string;
  Locale: string;
  LocaleName: string;
  StyleList?: string[]; // Optional field
  SampleRateHertz: string;
  VoiceType: string;
}

function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
// TODO:
// Extract the voice settings into a separate component
// Create a second form in the VoiceSettings component and reset
// the first form when switching back to the main form
export const TabTextToSpeech: FC<Props> = ({ content, mode, onClose }) => {
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const { projectId, chapterId } = useProjectChapterId();
  const [showVoiceSettings, setShowVoiceSettings] = useState(false);
  const [file, setFile] = useState<File>();
  const [fileURL, setFileURL] = useState<string>();
  const [voices, setVoices] = useState<Map<string, VoiceProfile>>(new Map());
  const { language, setLanguage } = useTranslation();
  const showTranslatedToast = async (message: string, language: string) => {
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage,
    });
  };
  // Form
  const {
    register,
    formState: { isValid },
    watch,
    reset,
  } = useForm<FormData>({
    defaultValues: async () => {
      const response = await axios.get(ttsVoicesListURL, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Ocp-Apim-Subscription-Key": accessToken,
        },
      });
      const allVoices = response.data;
      const filteredVoices = allVoices.filter((voice: VoiceProfile) =>
        voicesToUse.includes(voice.ShortName),
      );
      console.log(filteredVoices);

      // Add the default voice to each voice profile. Add StyleList if it's not present.
      // Apparently Microsoft API doesn't return the default style for each voice.
      filteredVoices.forEach((voice: VoiceProfile) => {
        if (!voice.StyleList) {
          voice.StyleList = [];
        }
        voice.StyleList.unshift("default");
      });

      const voicesMap = filteredVoices.reduce(
        (map: Map<string, VoiceProfile>, voice: VoiceProfile) => {
          map.set(voice.ShortName, voice);
          return map;
        },
        new Map<string, VoiceProfile>(),
      );
      console.log(voicesMap);
      setVoices(voicesMap);
      const tts = content.configurations?.textToSpeech;
      return {
        name: content.name ?? "",
        text: tts?.text ?? "",
        voiceName: tts?.voiceNme ?? "en-US-JennyNeural",
        voiceRate: tts?.voiceRate ?? "default",
        voiceVolume: tts?.voiceVolume ?? "default",
        voicePitch: tts?.voicePitch ?? "default",
        voiceStyle:
          tts?.voiceStyle ??
          voicesMap.get("en-US-JennyNeural")?.StyleList?.at(0),
      };
    },
  });

  const formData = watch();
  const toast = useToast();

  const synthesizeSpeech = async () => {
    let ssmlText =
      "<speak version='1.0' xml:lang='en-US' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts='https://www.w3.org/2001/mstts'>";
    ssmlText += `<voice name='${formData.voiceName}'>`;
    ssmlText += `<prosody rate='${formData.voiceRate}' volume='${formData.voiceVolume}' pitch='${formData.voicePitch}'>`;
    ssmlText += `<mstts:express-as style='${formData.voiceStyle}'>`;
    ssmlText += formData.text;
    ssmlText += "</mstts:express-as></prosody></voice></speak>";

    try {
      const response = await axios.post(ttsGeneratorURL, ssmlText, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Ocp-Apim-Subscription-Key": accessToken,
          "Content-Type": "application/ssml+xml",
          "User-Agent": "MyApp/1.0",
          "X-Microsoft-OutputFormat": "audio-16khz-32kbitrate-mono-mp3",
        },
        responseType: "blob",
      });

      const audioFile = new File([response.data], "speech.mp3", {
        type: "audio/mpeg",
      });
      setFile(audioFile);
    } catch (error) {
      console.error("Error fetching audio:", error);
    }
  };

  useEffect(() => {
    if (fileURL) {
      URL.revokeObjectURL(fileURL);
    }
    if (file) {
      const url = URL.createObjectURL(file);
      setFileURL(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
    if (content.sources?.main) {
      setFileURL(content.sources.main);
    }
  }, [file, content.sources?.main]);

  const handleDownload = () => {
    if (!fileURL) return;
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = "speech.mp3";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleCreateContent = () => {
    if (!file) return;
    const newContent = {
      type: ContentType.Text2Speech,
      name: formData.name,
      configurations: {
        textToSpeech: {
          text: formData.text,
          voiceName: formData.voiceName,
          voiceRate: formData.voiceRate,
          voiceVolume: formData.voiceVolume,
          voicePitch: formData.voicePitch,
          voiceStyle: formData.voiceStyle,
        },
      },
    };
    content = { ...content, ...newContent };
    mutationCreateContent.mutate(
      { projectId, chapterId, content, file },
      {
        onSuccess: () => {
          showTranslatedToast(`Text to speech content "${content.name}" has been added`, language)
          // toast({
          //   status: "success",
          //   title: "Content added",
          //   description: `Text to speech content "${content.name}" has been added`,
          // });
          onClose();
        },
      },
    );
  };

  useEffect(() => {
    translatePage(document.getElementById('showVoiceSettingsId')!, language);
  }, [showVoiceSettings]);
  
  const handleUpdateContent = () => {
    if (!file && mode === "create") return;
    const newContent = {
      type: ContentType.Text2Speech,
      name: formData.name,
      configurations: {
        textToSpeech: {
          text: formData.text,
          voiceName: formData.voiceName,
          voiceRate: formData.voiceRate,
          voiceVolume: formData.voiceVolume,
          voicePitch: formData.voicePitch,
          voiceStyle: formData.voiceStyle,
        },
      },
    };
    content = { ...content, ...newContent };
    mutationUpdateContent.mutate(
      { projectId, chapterId, content, file },
      {
        onSuccess: () => {
          showTranslatedToast(`Text to speech content "${content.name}" has been added`, language)
          // toast({
          //   status: "success",
          //   title: "Content updated.",
          //   description: `Text to speech content "${content.name}" has been updated.`,
          // });
          onClose();
        },
      },
    );
  };

  return (
    <>
      {showVoiceSettings ? (
        <Flex direction="column" gap="6" px="0" py="4" id="showVoiceSettingsId">
          <Flex alignItems="center" gap="4">
            <IconButton
              aria-label="Close settings"
              icon={<MdArrowBack />}
              onClick={() => setShowVoiceSettings(false)}
              variant="solid"
            />
            <Text fontSize="lg" fontWeight="700">
              Voice Settings
            </Text>
          </Flex>
          <Alert
            status="info"
            color="secondary.2"
            backgroundColor="rgba(77, 136, 140, 0.05)"
            border="1px solid #4D888C"
            borderRadius="8"
          >
            <AlertIcon color="secondary.2" />
            <Text fontWeight="500" fontSize="xs">
              The change of emotion is currently only available for English.
            </Text>
          </Alert>

          <Grid templateColumns="1fr 1fr" gap="10" gridColumn="span 2">
            <Flex direction="column" gap="4">
              <Flex align="center" gap="2">
                <Text
                  fontSize="sm"
                  fontWeight="600"
                  width="45%"
                  color="primary.4"
                >
                  Language/Voice
                </Text>
                <Select className="doNotTranslate"
                  {...register("voiceName", {
                    onChange: (e) => {
                      const voice = voices.get(e.target.value);
                      console.log(voice?.StyleList?.[0] ?? "");
                      // reset({ voiceStyle: voice?.StyleList?.[0] ?? "" });
                    },
                  })}
                  borderRadius="32"
                  fontSize="sm"
                >
                  {Array.from(voices).map(([key, voice]) => (
                    <option key={key} value={voice.ShortName} className="doNotTranslate">
                      {voice.DisplayName} {voice.LocaleName}
                    </option>
                  ))}
                </Select>
              </Flex>
              <Flex align="center" gap="2">
                <Text
                  fontSize="sm"
                  fontWeight="600"
                  width="45%"
                  color="primary.4"
                >
                  Emotion
                </Text>
                <Select
                  {...register("voiceStyle")}
                  borderRadius="32"
                  fontSize="sm"
                  disabled={!voices.get(formData.voiceName)?.StyleList}
                >
                  {voices.get(formData.voiceName)?.StyleList &&
                    voices.get(formData.voiceName)?.StyleList!.map((style) => (
                      <option key={style} value={style} className="doNotTranslate">
                        {capitalize(style)}
                      </option>
                    ))}
                </Select>
              </Flex>
              <Flex align="center" gap="2">
                <Text
                  fontSize="sm"
                  fontWeight="600"
                  width="45%"
                  color="primary.4"
                >
                  Pitch
                </Text>
                <Select
                  {...register("voicePitch")}
                  borderRadius="32"
                  fontSize="sm"
                >
                  <option value="x-low">Very Low</option>
                  <option value="low">Low</option>
                  <option value="default">Normal</option>
                  <option value="high">High</option>
                  <option value="x-high">Very High</option>
                </Select>
              </Flex>
            </Flex>
            <Flex direction="column" gap="4">
              <Flex align="center" gap="2">
                <Text
                  fontSize="sm"
                  fontWeight="600"
                  width="45%"
                  color="primary.4"
                >
                  Speaking Speed
                </Text>
                <Select
                  {...register("voiceRate")}
                  borderRadius="32"
                  fontSize="sm"
                >
                  <option value="x-slow">Very Slow</option>
                  <option value="slow">Slow</option>
                  <option value="default">Normal</option>
                  <option value="fast">Fast</option>
                  <option value="x-fast">Very fast</option>
                </Select>
              </Flex>
              <Flex align="center" gap="2">
                <Text
                  fontSize="sm"
                  fontWeight="600"
                  width="45%"
                  color="primary.4"
                >
                  Volume
                </Text>
                <Select
                  {...register("voiceVolume")}
                  borderRadius="32"
                  fontSize="sm"
                >
                  <option value="x-soft">Very Soft</option>
                  <option value="soft">Soft</option>
                  <option value="default">Normal</option>
                  <option value="loud">Loud</option>
                  <option value="x-loud">Very loud</option>
                </Select>
              </Flex>
            </Flex>
          </Grid>
          <Flex alignItems="center" gap="4" width="100%">
            <Box flex="1">
              <audio
                key={fileURL}
                controls
                style={
                  !fileURL
                    ? { opacity: 0.5, pointerEvents: "none", width: "100%" }
                    : { width: "100%" }
                }
              >
                <source src={fileURL} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </Box>
            <Button
              isDisabled={formData.text === ""}
              size="sm"
              onClick={synthesizeSpeech}
            >
              Generate
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Box>
          <Grid templateColumns="1fr 1fr" gap="2" minHeight="250px" py="2">
            <Flex direction="column">
              <Textarea
                {...register("text", { required: true })}
                minHeight="100%"
                placeholder="Type in your text here..."
                overflowY="auto"
                resize="none"
                borderRadius="6"
                p="2"
                _placeholder={{ opacity: 0.7 }}
              />
            </Flex>
            <Flex direction="column" gap="4" pl="2">
              <Flex align="center" gap="2">
                <Text
                  fontSize="sm"
                  fontWeight="600"
                  width="45%"
                  color="primary.4"
                  pr="1"
                >
                  Content Name
                </Text>
                <Input
                  placeholder="Name"
                  borderRadius="32"
                  size="sm"
                  {...register("name")}
                />
              </Flex>
              <Flex align="center" gap="2">
                <Text
                  fontSize="sm"
                  fontWeight="600"
                  width="45%"
                  color="primary.4"
                >
                  Language/Voice
                </Text>
                <Select
                  {...register("voiceName", {
                    onChange: (e) => {
                      const voice = voices.get(e.target.value);
                      console.log(voice?.StyleList?.[0] ?? "");
                      // reset({ voiceStyle: voice?.StyleList?.[0] ?? "" });
                    },
                  })}
                  borderRadius="32"
                  fontSize="sm"
                >
                  {Array.from(voices).map(([key, voice]) => (
                    <option key={key} value={voice.ShortName}>
                      {voice.DisplayName} {voice.LocaleName}
                    </option>
                  ))}
                </Select>
              </Flex>
              <Flex align="center" gap="2">
                <Text
                  fontSize="sm"
                  fontWeight="600"
                  width="45%"
                  color="primary.4"
                >
                  Emotion
                </Text>
                <Select
                  {...register("voiceStyle")}
                  borderRadius="32"
                  fontSize="sm"
                  disabled={!voices.get(formData.voiceName)?.StyleList}
                >
                  {voices.get(formData.voiceName)?.StyleList &&
                    voices.get(formData.voiceName)?.StyleList!.map((style) => (
                      <option key={style} value={style}>
                        {capitalize(style)}
                      </option>
                    ))}
                </Select>
              </Flex>
              <Center>
                <Button
                  fontSize="sm"
                  px="8"
                  leftIcon={
                    <Icon
                      as={MdSettingsInputComponent}
                      color="primary.6"
                      boxSize="4"
                    />
                  }
                  variant="solid"
                  onClick={() =>{ setShowVoiceSettings(true); }}
                >
                  Voice Settings
                </Button>
              </Center>
            </Flex>
          </Grid>
          <Flex alignItems="center" gap="2" mt="3">
            <audio
              key={fileURL}
              controls
              style={!fileURL ? { opacity: 0.5, pointerEvents: "none" } : {}}
            >
              <source src={fileURL} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            <IconButton
              isDisabled={!file && !content.sources?.main}
              variant="solid"
              aria-label="download"
              size="sm"
              icon={<TfiDownload />}
              onClick={handleDownload}
            />
            <Button
              isDisabled={formData.text === ""}
              size="sm"
              onClick={synthesizeSpeech}
            >
              Generate
            </Button>
          </Flex>
          <Flex width="100%" justifyContent="flex-end">
            <Spacer />
            {mode === "create" && (
              <Button
                isDisabled={!file || !isValid}
                _hover={{ backgroundColor: "primary.3" }}
                variant="primary"
                mt="4"
                onClick={handleCreateContent}
                isLoading={mutationCreateContent.isPending}
              >
                Add Content
              </Button>
            )}
            {mode === "update" && (
              <Button
                isDisabled={!isValid}
                variant="primary"
                onClick={handleUpdateContent}
                mt="4"
                isLoading={mutationUpdateContent.isPending}
              >
                Update Content
              </Button>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};
