import React, { SyntheticEvent, useState } from "react";
import {
  Flex,
  Text,
  Input,
  IconButton,
  Select,
  Box,
  Menu,
  Icon,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FiMoreVertical, FiUpload } from "react-icons/fi";
import { MdOutlineEdit } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import {
  useCurrentChapter,
  useMutationCreateContent,
  useMutationUpdateContent,
  useMutationDeleteContent,
} from "state/remoteState";
import { useProjectChapterId } from "state/localState";
import { ContentType } from "api/model";

// TODO: review this component. Consider cleaning up.
function OnScreenBackgroundPicker() {
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const chapter = useCurrentChapter().data;
  const { projectId, chapterId } = useProjectChapterId();
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const mutationDeleteContent = useMutationDeleteContent();

  const createOrUpdateBackgroundData = async (data: {
    color?: string;
    textColor?: string;
    alignment?: string;
    image?: File;
  }) => {
    // Check if the chapter has a content with type background
    if (!chapter) return;
    let content = chapter.content?.find(
      (content) => content.type === ContentType.Background,
    );
    console.log(content);
    if (!content) {
      // Create a new content with type background
      content = {
        type: ContentType.Background,
      };
      content.configurations = {};
      if (data.color) content.configurations.color = data.color;
      if (data.textColor) content.configurations.textColor = data.textColor;
      if (data.alignment) content.configurations.alignment = data.alignment;
      if (!chapter.content) chapter.content = [];
      chapter.content.push(content);
      mutationCreateContent.mutate({
        projectId,
        chapterId,
        content,
        file: data.image,
      });
      console.log("Creating new content");
      return;
    }
    // Update the background content
    if (!content.configurations) content.configurations = {};
    if (data.color) content.configurations.color = data.color;
    if (data.textColor) content.configurations.textColor = data.textColor;
    if (data.alignment) content.configurations.alignment = data.alignment;
    mutationUpdateContent.mutate({
      projectId,
      chapterId,
      content,
      file: data.image,
    });
    console.log("Updating content");
    console.log(data.color, data.textColor, data.alignment);
  };

  const handleBackgroundColorChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    createOrUpdateBackgroundData({ color: event.target.value });
  };

  const handleBackgroundTextColorChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    createOrUpdateBackgroundData({ textColor: event.target.value });
    console.log("bkg text color changed", event.target.value);
  };

  const handleAlignmentChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    createOrUpdateBackgroundData({ alignment: event.target.value });
    console.log("bkg alignment changed", event.target.value);
  };

  const handleBackgroundImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0]) {
      setIsImageUploaded(true);
      createOrUpdateBackgroundData({ image: event.target.files[0] });
    } else {
      setIsImageUploaded(false);
    }
    console.log("bkg image changed", event.target.files);
  };

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  console.log(fileInputRef);
  const handleDeleteContent = async (contentId: string) => {
    mutationDeleteContent.mutate({
      projectId: projectId!,
      chapterId: chapterId!,
      contentId: contentId,
    });
    setIsImageUploaded(false);
  };

  return (
    <Flex
      direction="column"
      border="1px solid #E2E8F0"
      style={{zIndex: 999, backgroundColor: "#FFFFFF", position: "relative"}}
      borderRadius="6"
      p="4"
      mt="4"
      gap="4"
    >
      <Flex gap="2" direction="column">
        <Flex gap="4" align="center">
          <Text fontSize="sm">Background Color Picker:</Text>
          <Input
            p="1"
            width="16"
            type="color"
            border="1px solid #cbd5e0"
            value={
              chapter?.content?.find(
                (content) => content.type === ContentType.Background,
              )?.configurations?.color ?? "#ffffff"
            }
            onChange={handleBackgroundColorChange}
          />
        </Flex>
        <Flex gap="4" align="center">
          <Text fontSize="sm">Text Color Picker:</Text>
          <Input
            p="1"
            width="16"
            type="color"
            border="1px solid #cbd5e0"
            value={
              chapter?.content?.find(
                (content) => content.type === ContentType.Background,
              )?.configurations?.textColor ?? "#000000"
            }
            onChange={handleBackgroundTextColorChange}
          />
        </Flex>
      </Flex>
      <Flex gap="2" direction="column">
        <Text fontSize="sm">Background Image:</Text>
        <Flex gap="4" align="center">
          <Box
            backgroundColor="primary.0"
            width="120px"
            height="60px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
          >
            <IconButton
              aria-label="Upload Image"
              icon={<FiUpload />}
              onClick={handleUploadClick}
              variant="outline"
            />
            <input
              type="file"
              ref={fileInputRef}
              accept="image/png, image/jpg, image/jpeg"
              onChange={(e) => {
                handleBackgroundImageChange(e);
              }}
              style={{ display: "none" }}
            />
          </Box>
          <Flex direction="column" gap="2" width="100%">
            <Select
              fontSize="sm"
              value={
                chapter?.content?.find(
                  (content) => content.type === ContentType.Background,
                )?.configurations?.alignment ?? "center"
              }
              onChange={handleAlignmentChange}
              isDisabled={!isImageUploaded}
            >
              <option value="top">Top</option>
              <option value="center">Center</option>
              <option value="bottom">Bottom</option>
            </Select>
          </Flex>
          <Menu>
            <MenuButton>
              <Icon as={FiMoreVertical} />
            </MenuButton>
            <MenuList>
              <MenuItem
                icon={<MdOutlineEdit />}
                onClick={(e: SyntheticEvent) => {
                  e.stopPropagation();
                  fileInputRef.current?.click();
                }}
                isDisabled={!isImageUploaded}
              >
                Edit
              </MenuItem>
              <MenuItem
                icon={<AiOutlineDelete />}
                onClick={(e: React.SyntheticEvent) => {
                  e.stopPropagation();
                  handleDeleteContent(
                    chapter?.content?.find(
                      (content) => content.type === ContentType.Background,
                    )?.id!,
                  );
                }}
                isDisabled={!isImageUploaded}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default OnScreenBackgroundPicker;
