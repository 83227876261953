import * as THREE from "three";
import { FC, ReactNode, useRef } from "react";
import { Orientation } from "api/model";
import { TransformControls } from "@react-three/drei";
import { useStateTransformControlMode } from "state/localState";
import { dToR, rToD } from "utils/utils";
import { Configurations } from "api/Configurations";

interface Props {
  children: ReactNode;
  configurations: Configurations;
  orientation: Orientation;
  onConfigurationsChange?: (c: Configurations) => void;
}

export const Transformer: FC<Props> = ({
  children,
  configurations,
  orientation,
  onConfigurationsChange,
}) => {
  const ref = useRef<THREE.Mesh>(null);
  const [transformControlMode, setTransformControlMode] =
    useStateTransformControlMode();

  let position: THREE.Vector3;
  let rotation: THREE.Euler;
  let scale: THREE.Vector3;

  const c = configurations ?? {};
  position = configurationsToPosition(c.position);
  rotation = configurationsToRotation(c.rotation);
  scale = configurationsToScale(c.scale);

  function handleObjectChange(): void {
    if (!ref?.current || !onConfigurationsChange) return;
    configurations.position = positionToConfiguration(ref.current.position);
    configurations.rotation = rotationToConfiguration(ref.current.rotation);
    configurations.scale = scaleToConfiguration(ref.current.scale);
    onConfigurationsChange(configurations);
  }

  function handleClick(): void {
    if (transformControlMode) return;
    setTransformControlMode("translate");
  }

  const orientationRotation =
    orientation === Orientation.Upright ? Math.PI / 2 : 0;

  return (
    <>
      <group rotation-x={orientationRotation}>
        <mesh
          ref={ref}
          position={position}
          rotation={rotation}
          scale={scale}
          onClick={handleClick}
        >
          {children}
        </mesh>
      </group>
      {transformControlMode && onConfigurationsChange && (
        <TransformControls
          // @ts-ignore
          object={ref}
          mode={transformControlMode}
          onObjectChange={handleObjectChange}
        />
      )}
    </>
  );
};

// Convert spacial configurations from Three.js format to
// Storage format (see Configurations.ts) and back.
const configurationsToPosition = ({ x = 0, y = 0, z = 0 } = {}) => {
  return new THREE.Vector3(x, y, z);
};

const configurationsToRotation = ({ x = 0, y = 0, z = 0 } = {}) => {
  return new THREE.Euler(...[dToR(x), dToR(y), dToR(z)]);
};

const configurationsToScale = ({ x = 1, y = 1, z = 1 } = {}) => {
  return new THREE.Vector3(x, y, z);
};

const positionToConfiguration = ({ x, y, z }: THREE.Vector3) => {
  return { x, y, z };
};

const rotationToConfiguration = ({ x, y, z }: THREE.Euler) => {
  let result = { x: rToD(x), y: rToD(y), z: rToD(z) };
  return result;
};

const scaleToConfiguration = ({ x, y, z }: THREE.Vector3) => {
  return { x, y, z };
};
