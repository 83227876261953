import ReactDOM from "react-dom/client";
import App from "./app/App";
import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react";
import { theme } from "./theme/theme";
import { MsalProvider } from "@azure/msal-react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RecoilRoot } from "recoil";
import { msalInstance } from "app/authConfig";
import { TranslationProvider } from "layout/TranslationContext";
//export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const { ToastContainer, toast } = createStandaloneToast();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      // The data is not updated on the server immediately,
      // so we set 1 minute stale time for all queries to avoid flickering.
      staleTime: 60000,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      toast({
        title: "Error occurred",
        description: `Something went wrong: ${error.message}`,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    },
  }),
});

root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <ChakraProvider
        theme={theme}
        toastOptions={{
          defaultOptions: {
            duration: 9000,
            position: "top-right",
            isClosable: true,
          },
        }}
      >
        <MsalProvider instance={msalInstance}>
        <TranslationProvider>
              <App />
        </TranslationProvider>
          <ToastContainer />
        </MsalProvider>
      </ChakraProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </RecoilRoot>
  </QueryClientProvider>,
);
