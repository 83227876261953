import * as React from "react";
import {useParams} from "react-router-dom";
import {useCallback, useEffect, useRef, useState} from "react";
import axios from "../../api/axios";
import {Content, ContentType, ProjectBean} from "../../api/model";
import {
    EDITOR_JS_TOOLS
} from "../Chapter/components/ControlPanel/PanelContent/ContentEditorWizard/Website/reactEditorJSTools";
import {createReactEditorJS} from "react-editor-js";
import {Spinner} from "@chakra-ui/react";
import {useMsal} from "@azure/msal-react";
export const DisplayQrContent = () => {
    const ReactEditorJS = createReactEditorJS();
    const { id: projectId, chapterId: chapterId } = useParams();
    if(projectId) {
        sessionStorage.setItem("lastPageVisitedProject", projectId);
    }
    if(chapterId) {
        sessionStorage.setItem("lastPageVisitedChapter", chapterId);
    }
    
    const [showLoader, setShowLoader] = useState(true);
    
    const { instance } = useMsal();
    const [project, setProject] = useState<ProjectBean | null>(null)
    //const [chapter, setChapter] = useState<Container | null>(null)
    const [content, setContent] = useState<Content | null>(null)
    const editorCore = useRef<any>(null);

    useEffect(() => {
        axios.get("/projects/" + projectId + "?status=Published").then((response) => {
            setProject(response.data);
            sessionStorage.removeItem("lastPageVisitedProject");
            sessionStorage.removeItem("lastPageVisitedChapter");
        }).catch(
            (error) => {
                console.error(error);
                if(error.response.status == 401){
                    instance.loginRedirect();
                }
            }
        )
    }, []);

    useEffect(() => {
        const chap = project?.containers?.find(x => x.id == chapterId);
        //setChapter(chap ?? null);
        const con = chap?.content?.find(x => x.type === ContentType.EditorJs);
        console.log(con);
        if(!con){
            const urlCon = chap?.content?.find(x => x.type === ContentType.UrlEndpoint);
            console.log(urlCon);
            if(urlCon?.description?.startsWith("http")){
                window.location.href = urlCon.description;
            }
        }
        setContent(con ?? null);
        setShowLoader(false);
        
    }, [project]);
    const handleInitialize = useCallback((instance) => {
        editorCore.current = instance;
    }, []);
    return (
        showLoader ? <Spinner size='xl' />  :
        <div style={{
            padding: "1em"
        }}>
                {content ? 
                <ReactEditorJS
                    tools={EDITOR_JS_TOOLS}
                    onInitialize={handleInitialize}
                    defaultValue={content?.configurations?.editorJS?.data || {}}
                    readOnly={true}
                /> : null}
        </div>
    );
}