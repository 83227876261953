import { Content } from "api/model";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  HStack,
} from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { useMutationUpdateContent } from "state/remoteState";
import { useParams } from "react-router-dom";

interface Props {
  content: Content;
}

export const ContentListVideoDetails: FC<Props> = ({ content }) => {
  const mutationUpdateContent = useMutationUpdateContent();
  const { id: projectId, chapterId } = useParams<{
    id: string;
    chapterId: string;
  }>();
  if (!projectId || !chapterId) {
    throw new Error("Missing projectId or chapterId in URL.");
  }

  const videoRef = useRef<HTMLVideoElement>(null);

  // Srtart and end time in seconds
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);

  const startRef = useRef(start);
  const endRef = useRef(end);

  useEffect(() => {
    startRef.current = start; // Update the ref when the `start` state changes.
    endRef.current = end; // Update the ref when the `end` state changes.
  }, [start, end]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    // Handler to track if video is playing
    const loadMetadataHandler = () => {
      setDuration(video.duration);
    };

    const timeUpdateHandler = () => {
      if (video.paused) return;
      if (video.currentTime < startRef.current) {
        video.currentTime = startRef.current;
      }
      if (video.currentTime >= endRef.current) {
        video.currentTime = endRef.current;
        video.pause();
      }
    };

    const playHandler = () => {
      if (video.currentTime < startRef.current) {
        video.currentTime = startRef.current;
      } else if (video.currentTime >= endRef.current) {
        video.currentTime = startRef.current;
      }
    };

    video.addEventListener("loadedmetadata", loadMetadataHandler);
    video.addEventListener("timeupdate", timeUpdateHandler);
    video.addEventListener("play", playHandler);
    return () => {
      video.removeEventListener("loadedmetadata", loadMetadataHandler);
      video.removeEventListener("timeupdate", timeUpdateHandler);
      video.removeEventListener("play", playHandler);
    };
  }, [content, videoRef, startRef, endRef]);

  // Set start and end time from content and duration
  useEffect(() => {
    setStart(content?.configurations?.video?.start ?? 0);
    setEnd(content?.configurations?.video?.end ?? duration ?? 0);
  }, [content, duration]);

  // Set frame to start time
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    video.currentTime = start;
  }, [start]);

  // Set frame to end time
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    video.currentTime = end;
  }, [end]);

  const updateContent = () => {
    console.log("updateContent");
    if (!content.configurations) {
      content.configurations = {};
    }
    if (!content.configurations.video) {
      content.configurations.video = {};
    }
    content.configurations.video.start = start;
    content.configurations.video.end = end;
    mutationUpdateContent.mutate({ projectId, chapterId, content });
  };

  return (
    <>
      <video
        ref={videoRef}
        src={content?.sources?.main ?? ""}
        preload="metadata"
        muted
        controls
      />
      <HStack>
        <RangeSlider
          mt="4"
          min={0}
          max={duration}
          onChange={(value) => {
            if (!videoRef.current) return;
            if (!videoRef.current.paused) {
              videoRef.current.pause();
            }
            setStart(value[0]);
            setEnd(value[1]);
          }}
          onChangeStart={updateContent}
          onChangeEnd={updateContent}
          aria-label={["min", "max"]}
          value={[start, end]}
        >
          <RangeSliderTrack>
            <RangeSliderFilledTrack />
          </RangeSliderTrack>
          <RangeSliderThumb index={0} />
          <RangeSliderThumb index={1} />
        </RangeSlider>
      </HStack>
      <Flex justifyContent="space-between" alignItems="center" gap="2" pt="3">
        <FormControl>
          <FormLabel fontWeight="400" fontSize="sm" htmlFor="startTime">
            Start
          </FormLabel>
          <Input
            isReadOnly={true}
            id="startTime"
            value={convertSecondsToTime(start)}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontWeight="400" fontSize="sm" htmlFor="endTime">
            End
          </FormLabel>
          <Input
            isReadOnly={true}
            id="endTime"
            value={convertSecondsToTime(end)}
          />
        </FormControl>
      </Flex>
      {/* <TransformInputs content={content} /> */}
    </>
  );
};

function convertSecondsToTime(seconds: number): string {
  // Round seconds first to avoid incorrect hour/minute calculation
  seconds = Math.round(seconds);

  // Calculate hours, minutes, and whole seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const wholeSeconds = seconds % 60; // Already rounded

  // Pad the minutes and seconds with leading zeros if needed
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = wholeSeconds.toString().padStart(2, "0");

  // Format the time string based on whether there are any hours
  if (hours > 0) {
    return `${hours}:${paddedMinutes}:${paddedSeconds}`;
  }
  return `${paddedMinutes}:${paddedSeconds}`;
}
