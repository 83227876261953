import { useGLTF } from "@react-three/drei";
import { Content } from "api/model";
import { FC, useEffect, useMemo } from "react";
import { AnimationMixer } from "three";
import { useFrame } from "@react-three/fiber";

interface Props {
  content: Content;
}

export const ContentThreeDModel: FC<Props> = ({ content }) => {
  const { scene, animations } = useGLTF(content.sources!.main);
  const mixer = useMemo(() => new AnimationMixer(scene), [scene]);

  useEffect(() => {
    const clip = animations.find(
      (clip) =>
        clip.name === content?.configurations?.threeD?.selectedAnimation,
    );
    if (clip) mixer.clipAction(clip).play();
    // Clean up
    return () => {
      if (mixer) {
        mixer.stopAllAction();
      }
    };
  }, [content, animations, mixer]);

  // We use useFrame because the drei way of playing animations is not working
  // in combination with old versions of three.js and newver versions of some of the libraries
  // TODO: make it a drei way after updating the trhee.js, fiber, and drei libraries.
  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return <primitive object={scene} />;
};
