import { FC, useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import { Content, ContentType } from "api/model";
import { TabURL } from "./TabURL";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { TabWebEditor } from "./TabWebEditor";
import { useTranslation } from '../../../../../../../layout/TranslationContext';
import { translatePage } from '../../../../../../../layout/translatePage';
export const contentEditorWebsiteSupportedContentTypes = [
  ContentType.UrlEndpoint,
  ContentType.EditorJs,
];

export const DialogContentEditorWebsite: FC<CreateOrUpdateContentProps> = (
  props,
) => {
  const onClose = props.onClose;
  const mode = props.contentType ? "create" : "update";
  const content =
    props.content ?? ({ type: ContentType.UrlEndpoint } as Content);
  let tabIndex = 0;
  switch (content.type) {
    case ContentType.UrlEndpoint:
      tabIndex = 0;
      break;
    case ContentType.EditorJs:
      tabIndex = 1;
      break;
    default:
      throw new Error("Unknown content type");
  }
  console.log(props);
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false); 
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    console.log(props.isOpen)
    if (props.isOpen) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false); 
    }
  }, [props.isOpen]);

useEffect(() => {

  if (modalIsOpen && modalRef.current) {
    translatePage(modalRef.current!, language);
  }
}, [modalIsOpen]);
  return (
    <Modal {...props} size="3xl" initialFocusRef={modalRef} >
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef} >
        <ModalHeader fontSize="36px" fontWeight="700" pt="10">
          {mode === "create" ? "Add Website" : "Update Website"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs variant="soft-rounded" defaultIndex={tabIndex}>
            <TabList
              backgroundColor="primary.0"
              p="1"
              borderRadius="50"
              gap="4"
              width="fit-content"
              height="40px"
            >
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                URL
              </Tab>
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Web-Editor
              </Tab>
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Library
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px="0" py="6">
                <TabURL {...props} />
              </TabPanel>
              <TabPanel px="0" py="6">
                <TabWebEditor content={content} mode={mode} onClose={onClose} />
              </TabPanel>
              <TabPanel pt="8" pb="10">
                <p>
                  Coming soon! You will be able to select and manage already
                  uploaded content here.
                </p>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
