import axios, { InternalAxiosRequestConfig } from "axios";
import {loginRequest, msalInstance, scopes} from "../app/authConfig";
import {InteractionRequiredAuthError} from "@azure/msal-browser";

const instance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    /*if (config.headers) {
      config.headers["Content-Type"] = "application/json";
    }*/

    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      try {
        const accessTokenResponse = await msalInstance.acquireTokenSilent({
          scopes: scopes,
          account: account,
        });

        if (accessTokenResponse) {
          const accessToken = accessTokenResponse.accessToken;

          if (config.headers && accessToken) {
            config.headers["Authorization"] = "Bearer " + accessToken;
          }
        }
      }catch(error){
        if (error instanceof InteractionRequiredAuthError) {
          // Fallback to interactive method
          await msalInstance.acquireTokenRedirect(loginRequest);
        } else {
          console.error("Token acquisition error: ", error);
          throw error;
        }
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default instance;
