import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Input,
  Icon,
  Flex,
  useToast,
  Box,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef, SyntheticEvent, DragEvent } from "react";
import { FiUpload } from "react-icons/fi";
import { useMutationCreateResource } from "state/remoteState";
import { useTranslation } from '../../layout/TranslationContext';
import { translatePage, translateText } from '../../layout/translatePage';
interface UploadFileLibraryProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UploadFileLibrary: React.FC<UploadFileLibraryProps> = ({
  isOpen,
  onClose,
}) => {
  const mutationCreateResource = useMutationCreateResource();
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState<string>("");
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);
  const showTranslatedToast = async (message: string, language: string) => {
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage,
    });
  };
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false); 

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    if (isOpen) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false); 
    }
  }, [isOpen]);

useEffect(() => {

  if (modalIsOpen && modalRef.current) {
    translatePage(modalRef.current!, language);
  }
}, [modalIsOpen]);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];
    if (fileUploaded) {
      setFile(fileUploaded);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSave = () => {
    if (!file) {
      return;
    }
    const filename = name !== "" ? name : file.name;
    mutationCreateResource.mutate(
      {
        file,
        filename,
      },
      {
        onSuccess: () => {
          showTranslatedToast("File has been successfully uploaded", language)
          // toast({
          //   status: "success",
          //   title: "",
          //   description: "File has been successfully uploaded",
          // });
          handleClose();
        },
        onError: () => {
          showTranslatedToast("An error occurred while uploading the file", language)
          // toast({
          //   status: "error",
          //   title: "",
          //   description: "An error occurred while uploading the file",
          // });
        },
      },
    );
  };

  const resetState = () => {
    setFile(null);
    setName("");
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const fileUploaded = e.dataTransfer.files[0];
    if (fileUploaded) {
      setFile(fileUploaded);
    }
  };

  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <div id="library">
    <Modal isOpen={isOpen} onClose={handleClose} initialFocusRef={modalRef} size="3xl">
      <ModalOverlay />
      <ModalContent ref={modalRef} p="2">
        <ModalHeader fontSize="36px" fontWeight="700">
          Upload File
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody minHeight="250px">
          <Flex
            direction="column"
            width="100%"
            height="100%"
            align="center"
            justify="center"
            backgroundColor="primary.0"
            border="2px dashed"
            borderColor="gray.300"
            borderRadius="6"
            p="4"
            mb="4"
            onClick={handleClick}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            cursor="pointer"
          >
            <Flex
              align="center"
              justify="center"
              gap="4"
              p="4"
              direction="column"
              width="100%"
            >
              <Box
                backgroundColor="primary.1"
                width="45px"
                height="45px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
              >
                <Icon as={FiUpload} />
              </Box>
              <Flex direction="column" align="center">
                <Text
                  textAlign="left"
                  fontWeight="700"
                  fontSize="md"
                  color="black"
                >
                  Upload Content
                </Text>
                <Text color="primary.4" fontWeight="600">
                  {file ? file.name : <>Drag & Drop or click here</>}
                </Text>
              </Flex>
            </Flex>

            <Input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </Flex>
          <Flex align="center" mt="4">
            <Text fontWeight="600" color="primary.4" pr="2" width="auto">
              Content&nbsp;Name
            </Text>
            <Input
              onChange={handleNameChange}
              placeholder="Name"
              borderRadius="32"
              size="sm"
              value={name}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleSave}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            px="40px"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal></div>
  );
};