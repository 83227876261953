import { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import { ContentType } from "api/model";
import { Box, Icon, Image, Text } from "@chakra-ui/react";
import { contentTypeDetails } from "../contentTypeDetails";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import { Box3, Vector3 } from "three";
import {
  Configurations,
  initial2DConfigurations,
  initial3DConfigurations,
} from "api/Configurations";

interface PreviewProps {
  src: string;
  contentType: ContentType;
  fileName?: string;
  setConfigurations?: (configurations: Configurations) => void;
}

export const Preview: FC<PreviewProps> = ({
  src,
  contentType,
  fileName,
  setConfigurations,
}) => {
  // console.log("Rerendering Preview", src, contentType);
  switch (contentType) {
    case ContentType.Image:
      return <ImagePreview src={src} setConfigurations={setConfigurations!} />;
    case ContentType.Value3DModel:
      return (
        <ThreeDModelPreview src={src} setConfigurations={setConfigurations!} />
      );
    case ContentType.Subtitles:
      return <SubtitlesPreview src={src} />;
    default:
      return <DefaultPreview contentType={contentType} fileName={fileName} />;
  }
};

interface DefaultPreviewProps {
  contentType: ContentType;
  fileName?: string;
}

const DefaultPreview: FC<DefaultPreviewProps> = ({ contentType, fileName }) => {
  const icon = contentTypeDetails(contentType).icon;
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="primary.1"
        w="50px"
        h="50px"
      >
        <Icon as={icon} color="primary.6" boxSize="6" />
      </Box>

      <Text color="primary.4" pt="6" pb="2">
        {fileName}
      </Text>
    </>
  );
};

export interface ImagePreviewProps {
  src: string;
  setConfigurations: (configurations: Configurations) => void;
}

export const ImagePreview: FC<ImagePreviewProps> = ({
  src,
  setConfigurations,
}) => {
  return (
    <Image
      src={src}
      onLoad={(e) => {
        const img = e.target as HTMLImageElement;
        const size = { x: img.naturalWidth, y: img.naturalHeight };
        setConfigurations(initial2DConfigurations(size));
      }}
    />
  );
};

interface GLBPreviewProps {
  src: string;
  setConfigurations: (configurations: Configurations) => void;
  prescale?: boolean;
}

// TODO: merge with ThreeDModelPreview.
const Model: FC<GLBPreviewProps> = ({
  src,
  setConfigurations,
  prescale = false,
}) => {
  const { scene, animations } = useGLTF(src);
  const controlsRef = useRef<any>();
  const { camera } = useThree();

  // Here we setup camera, prescale the model to fit the viewport,
  // and collect animations names.
  useEffect(() => {
    const box = new Box3();
    const bbox = box.setFromObject(scene);
    // Ensure size coordinates are between 1 and 3
    bbox.max.x = Math.max(0.5, Math.min(bbox.max.x, 1));
    bbox.max.y = Math.max(1, Math.min(bbox.max.y, 1));
    bbox.max.z = Math.max(1, Math.min(bbox.max.z, 1));
    /////
    bbox.min.x = Math.min(-0.5, Math.max(bbox.min.x, -1));
    bbox.min.y = Math.min(-0.5, Math.max(bbox.min.y, -1));
    bbox.min.z = Math.min(-0.5, Math.max(bbox.min.z, -1));

    const size = bbox.getSize(new Vector3());
    console.log(bbox);

    // Rescale the object to normalized space
    const maxAxis = Math.max(size.x, size.y, size.z);
    let scale = 1 / maxAxis;
    if (scale <= 0.5) {
      scale = 0.25;
    }
    scene.scale.multiplyScalar(scale);
    console.log(scale);
    // Collect animations names
    const animationNames = animations.map((animation) => animation.name);

    // Report initial 3D configurations
    setConfigurations(initial3DConfigurations(size, animationNames, prescale));

    // Set the camera to look at the center of the object
    const center = bbox.getCenter(new Vector3());
    camera.lookAt(center);
    if (controlsRef.current) {
      controlsRef.current.target.copy(center);
      controlsRef.current.update();
    }
  }, [scene, animations]);

  return (
    <>
      <primitive object={scene} />
      <OrbitControls ref={controlsRef} />
    </>
  );
};

const ThreeDModelPreview: FC<GLBPreviewProps> = ({
  src,
  setConfigurations,
}) => {
  return (
    <Canvas
      camera={{ fov: 40, near: 0.1, far: 1000, position: [1, -0.5, 1] }}
      style={{ border: "1px solid #E2E8F0" }}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      <Model src={src} setConfigurations={setConfigurations} prescale={true} />
      <ambientLight intensity={2} />
    </Canvas>
  );
};

interface SubtitlesPreviewProps {
  src: string;
}

export const SubtitlesPreview: FC<SubtitlesPreviewProps> = ({ src }) => {
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    fetch(src)
      .then((response) => response.text())
      .then((data) => {
        setContent(data);
      })
      .catch((error) => {
        console.error("Error fetching subtitle content:", error);
      });
  }, [src]);

  return (
    <Text whiteSpace="pre-line" fontSize="sm" color="primary.4" align="left">
      {content}
    </Text>
  );
};
