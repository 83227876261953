import { Environment } from "@react-three/drei";
import React from "react";

const IndustrialLighting = () => (
  <>
    <ambientLight intensity={1.6} color="#ccd1d5" />
    <directionalLight
      castShadow
      color="#ffffff"
      position={[5, 10, 5]}
      intensity={4}
      shadow-mapSize-width={1024}
      shadow-mapSize-height={1024}
      shadow-camera-far={100}
      shadow-camera-left={-30}
      shadow-camera-right={30}
      shadow-camera-top={30}
      shadow-camera-bottom={-30}
    />
    <Environment background={false} files={"img/lighting_2.hdr"} path="/" />
  </>
);

const ForestLighting = () => (
  <>
    <ambientLight intensity={3} color="#b2cc8f" />
    <directionalLight
      castShadow
      color="#ffffff"
      position={[5, 10, 5]}
      intensity={3}
      shadow-mapSize-width={1024}
      shadow-mapSize-height={1024}
      shadow-camera-far={100}
      shadow-camera-left={-30}
      shadow-camera-right={30}
      shadow-camera-top={30}
      shadow-camera-bottom={-30}
    />
    <Environment
      background={false} // can be true, false or "only" (which only sets the background) (default: false)
      files={"img/lighting_1.hdr"}
      path="/"
    />
  </>
);

const SimpleLighting = () => (
  <>
    <ambientLight intensity={1} color="#ffffff" />
    <directionalLight
      castShadow
      color="#ffffff"
      position={[0, 10, 0]}
      intensity={3}
      shadow-mapSize-width={1024}
      shadow-mapSize-height={1024}
      shadow-camera-far={50}
      shadow-camera-left={-10}
      shadow-camera-right={10}
      shadow-camera-top={10}
      shadow-camera-bottom={-10}
    />
    <Environment
      background={false} // can be true, false or "only" (which only sets the background) (default: false)
      files={"img/lighting_3.hdr"}
      path="/"
    />
  </>
);

// Lighting comnponent is the Industrial, Forest, or Simple lighting,
// depending on the props passed to it.

interface LightingProps {
  lightingType?: "industrial" | "forest" | "simple" | undefined;
}

export const Lighting: React.FC<LightingProps> = ({ lightingType }) => {
  switch (lightingType) {
    case "industrial":
      return <IndustrialLighting />;
    case "forest":
      return <ForestLighting />;
    case "simple":
    default:
      return <SimpleLighting />;
  }
};
