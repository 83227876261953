import { useLoader } from "@react-three/fiber";
import { useMemo } from "react";
import { Euler, TextureLoader, Vector3 } from "three";

export const ContentAudio = () => {
  const texture = useLoader(
    TextureLoader,
    `${process.env.PUBLIC_URL}/icons/audio_content_icon.png`,
  );
  const { scale, rotation } = useMemo(() => {
    const maxD = Math.max(texture.image.width, texture.image.height);
    const scaleFactor = 0.3;
    return {
      scale: new Vector3(
        (texture.image.width / maxD) * scaleFactor,
        (texture.image.height / maxD) * scaleFactor,
        0,
      ),
      rotation: new Euler(-Math.PI / 2, 0, Math.PI / 2),
    };
  }, [texture]);
  return (
    <mesh rotation={rotation} scale={scale}>
      <planeGeometry attach="geometry" />
      <meshBasicMaterial attach="material" map={texture} transparent={true} />
    </mesh>
  );
};
