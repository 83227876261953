import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { BiSidebar } from "react-icons/bi";
import { AnchorType, Content, ContentType } from "api/model";
import { useProjectChapterId } from "state/localState";
import {
  useMutationCreateAnchor,
  useMutationCreateContent,
  useMutationCreateQRCodeForChapter,
  useMutationUpdateContent,
  useQueryProject,
} from "state/remoteState";
import { Configurations } from "api/Configurations";
import { ImagePreview, ImagePreviewProps } from "../Preview";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { useDebouncedCallback } from "use-debounce";
import axios from "../../../../../../../api/axios";
import { translateText } from "../../../../../../../layout/translatePage";
import { useTranslation } from '../../../../../../../layout/TranslationContext';
type FormData = {
  name: string;
  url: string;
};

export const TabURL: FC<CreateOrUpdateContentProps> = (props) => {
  const { projectId, chapterId } = useProjectChapterId();
  const project = useQueryProject(projectId).data;
  const mutationCreateAnchor = useMutationCreateAnchor();
  const [previewImage, setPreviewImage] = useState<File>();
  const [previewImageURL, setPreviewImageURL] = useState<string>();
  const [isImageLoading, setImageLoading] = useState<boolean>(false);
  const [isImageLoadingError, setImageLoadingError] = useState<boolean>(false);
  const [configurationsFromPreview, setConfigurationsFromPreview] =
    useState<Configurations>({});
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const mutationCreateQRCodeForChapter = useMutationCreateQRCodeForChapter();
  const toast = useToast();
  const content = props.content ?? {
    type: ContentType.UrlEndpoint,
  };
  const mode = props.content ? "update" : "create";
  // Form
  const { register, watch, formState } = useForm<FormData>({
    values: {
      name: content?.name ?? "",
      url: content?.description ?? "",
    },
  });
  const formData = watch();
  const { language, setLanguage } = useTranslation();
  const showTranslatedToast = async (message1: string, message: string, language: string) => {
    const translatedMessage1 = await translateText(message1, language);
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage1,
      description: translatedMessage,
    });
  };
  useEffect(() => {
    // Clenup when a new preview image is set
    if (previewImageURL) {
      console.log("Destroying previewImageURL");
      URL.revokeObjectURL(previewImageURL);
    }
    if (previewImage) {
      setPreviewImageURL(URL.createObjectURL(previewImage));
    } else {
      setPreviewImageURL(undefined);
    }
    return () => {
      // Cleanup the last preview image that was set
      if (previewImageURL) {
        console.log("Destroying the last previewImageURL");
        URL.revokeObjectURL(previewImageURL);
      }
    };
  }, [previewImage]);

  const makeWebsiteSnapshot = useDebouncedCallback((url: string) => {
    setImageLoadingError(false);
    setImageLoading(true);
    const urlWithSchema = encodeURIComponent(
      url.startsWith("http") ? url : `https://${url}`,
    );
    const previewAPIURL = axios.get("/resources/WebScreenshot/?url=" + urlWithSchema, {responseType: "blob"})
      .then((response) => {
        if (response.status != 200) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      })
      .then((blob) => {
        const file = new File([blob], `preview.${IMAGE_TYPE}`, {
          type: IMAGE_MIME_TYPE,
        });
        setPreviewImage(file);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
        setImageLoadingError(true);
      })
      .finally(() => {
        setImageLoading(false);
      });
  }, 500);

  // Load preview image
  useEffect(() => {
    if (!formData.url) {
      return;
    }
    makeWebsiteSnapshot(formData.url);
  }, [formData.url]);

  const handleCreateContent = () => {
    const newContent: Content = {
      name: formData.name,
      description: formData.url,
      configurations: configurationsFromPreview,
      type: ContentType.UrlEndpoint,
    };
    mutationCreateContent.mutate(
      {
        projectId,
        chapterId,
        content: newContent,
        file: previewImage,
      },
      {
        onSuccess: () => {
          if (project!.anchorType === AnchorType.QrCode) {
            mutationCreateQRCodeForChapter.mutate({
              projectId,
              chapterId,
            });
          }
          showTranslatedToast( "Content added", `Website content "${formData.name}" has been added`, language)
          // toast({
          //   status: "success",
          //   title: "Content added",
          //   description: `Website content "${formData.name}" has been added`,
          // });
          props.onSuccess();
        },
      },
    );
  };

  async function dataURLtoFile(dataURL, filename) {
    // Convert data URL to Blob
    const response = await fetch(dataURL);
    const blob = await response.blob();

    // Convert Blob to File
    const file = new File([blob], filename, {
      type: "image/png",
      lastModified: Date.now(),
    });

    return file;
  }

  const handleUpdateContent = () => {
    if (!content) {
      throw new Error("Content is not defined");
    }
    content.name = formData.name;
    content.description = formData.url;
    content.type = ContentType.UrlEndpoint;
    content.configurations = {
      ...content.configurations,
      ...configurationsFromPreview,
    };

    mutationUpdateContent.mutate(
      {
        projectId,
        chapterId,
        content: content,
        file: previewImage,
      },
      {
        onSuccess: () => {
          console.log("Content updated");
          showTranslatedToast( "Content updated", `Website "${formData.name}" has been updated`, language)
          // toast({
          //   status: "success",
          //   title: "Content updated",
          //   description: `Website "${formData.name}" has been updated`,
          // });
          props.onSuccess();
        },
        onError: (error) => {
          console.error(error);
          showTranslatedToast( "Content update failed", `Website could not be updated`, language)
          // toast({
          //   status: "error",
          //   title: "Content update failed",
          //   description: `Website could not be updated`,
          // });
        },
      },
    );
  };

  return (
    <>
      <Grid templateColumns="1fr 1fr" gap="2" minHeight="250px">
        <GridItem>
          <Button
            position="relative"
            width="100%"
            height="100%"
            backgroundColor="primary.0"
            borderRadius="6"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{ backgroundColor: "primary.0" }}
          >
            <Flex direction="column" align="center">
              <URLPreview
                src={previewImageURL ?? content?.sources?.main ?? ""}
                isLoading={isImageLoading}
                isError={isImageLoadingError}
                setConfigurations={setConfigurationsFromPreview}
              />
            </Flex>
          </Button>
        </GridItem>
        <Flex direction="column" pl="2">
          <Flex align="center" gap="2" mb="6">
            <Text
              fontSize="sm"
              fontWeight="600"
              width="45%"
              color="primary.4"
              pr="1"
            >
              Content Name
            </Text>
            <Input
              placeholder="Name"
              borderRadius="32"
              size="sm"
              {...register("name")}
            />
          </Flex>
          <Text
            fontSize="sm"
            fontWeight="600"
            width="45%"
            color="primary.4"
            mb="2"
          >
            URL
          </Text>
          <Input
            placeholder="URL"
            borderRadius="32"
            size="sm"
            {...register("url", { required: true })}
          />
        </Flex>
      </Grid>
      <Flex justifyContent="flex-end">
        {mode === "create" && (
          <Button
            isLoading={mutationCreateContent.isPending}
            isDisabled={
              !formState.isValid || isImageLoading || isImageLoadingError
            }
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleCreateContent}
          >
            Add Content
          </Button>
        )}
        {mode === "update" && (
          <Button
            isLoading={mutationUpdateContent.isPending}
            isDisabled={
              !formState.isValid ||
              isImageLoading ||
              isImageLoadingError ||
              !formState.isDirty
            }
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleUpdateContent}
          >
            Update Content
          </Button>
        )}
      </Flex>
    </>
  );
};

const IMAGE_TYPE = "png";
const IMAGE_MIME_TYPE = "image/png";

interface URLPreviewProps extends ImagePreviewProps {
  isLoading?: boolean;
  isError?: boolean;
}

const URLPreview: FC<URLPreviewProps> = ({
  src,
  isLoading,
  isError,
  setConfigurations,
}) => {
  if (isLoading) {
    return <Spinner color="black" />;
  }

  if (!isLoading && !isError && src !== "") {
    return <ImagePreview src={src} setConfigurations={setConfigurations} />;
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="primary.1"
        w="50px"
        h="50px"
        borderRadius="50"
      >
        <Icon as={BiSidebar} color="primary.6" boxSize="6" />
      </Box>
      {isError && (
        <Text color="primary.6" mt="1">
          Couldn&apos;t load the Website preview.
          <br />
          Check that the URL is correct.
        </Text>
      )}
      <Text color="primary.6" pt="6">
        Website preview
      </Text>
    </>
  );
};
