import { Content, ContentType } from "api/model";
import { ComponentProps, FC, useRef, useState, useEffect } from "react";
import { contentTypesWithOwnEditor } from "../contentTypeDetails";
import { DialogCreateOrUpdateContentUniversal } from "./DialogCreateOrUpdateContentUniversal";
import { Modal } from "@chakra-ui/react";
import {
  DialogContentEditorAudio,
  contentEditorAudioSupportedContentTypes,
} from "./Audio/DialogContentEditorAudio";
import {
  DialogContentEditorVideo,
  contentEditorVideoSupportedContentTypes,
} from "./Video/DialogContentEditorVideo";
import {
  DialogContentEditorWebsite,
  contentEditorWebsiteSupportedContentTypes,
} from "./Website/DialogContentEditorWebsite";
import { useTranslation } from '../../../../../../layout/TranslationContext';
import { translatePage } from '../../../../../../layout/translatePage';
export type CreateOrUpdateContentProps = Omit<
  ComponentProps<typeof Modal>,
  "children"
> & {
  contentType?: ContentType;
  content?: Content;
  file?: File;
  onSuccess: () => void;
};

export const DialogCreateOrUpdateContent: FC<CreateOrUpdateContentProps> = (
  props,
) => {
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false); 
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    console.log(props.isOpen)
    if (props.isOpen) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false); 
    }
  }, [props.isOpen]);

useEffect(() => {

  if (modalIsOpen && modalRef.current) {
    translatePage(modalRef.current!, language);
  }
}, [modalIsOpen]);
  const contentType = props.contentType ?? props.content?.type;
  if (!contentType) {
    return null;
  }
  if (!contentTypesWithOwnEditor.includes(contentType)) {
    return <DialogCreateOrUpdateContentUniversal {...props} initialFocusRef={modalRef} 
/>;
  }
  if (contentEditorAudioSupportedContentTypes.includes(contentType)) {
    return <DialogContentEditorAudio {...props} initialFocusRef={modalRef} 
/>;
  }
  if (contentEditorVideoSupportedContentTypes.includes(contentType)) {
    return <DialogContentEditorVideo {...props} initialFocusRef={modalRef} 
/>;
  }
  if (contentEditorWebsiteSupportedContentTypes.includes(contentType)) {
    return <DialogContentEditorWebsite {...props} initialFocusRef={modalRef} 
/>;
  }
};
