import * as React from "react";
import { Tag, TagLabel, TagRightIcon } from "@chakra-ui/react";
import { IoCloseOutline } from "react-icons/io5";

interface CustomTagProps {
  name: string;
  menuItem: boolean;
  onDelete?: (name: string) => void;
}

export const CustomTag: React.FC<CustomTagProps> = ({
  name,
  menuItem,
  onDelete,
}) => {
  return (
    <Tag
      size="lg"
      variant="solid"
      backgroundColor="gray.100"
      borderRadius="20"
      pl="4"
      pr="4"
    >
      <TagLabel fontWeight="500" fontSize="sm" color="blue.800" className="doNotTranslate">
        {name}
      </TagLabel>
      {!menuItem && (
        <TagRightIcon
          as={IoCloseOutline}
          color="blue.800"
          onClick={() => onDelete && onDelete(name)}
          _hover={{ cursor: "pointer" }}
        />
      )}
    </Tag>
  );
};
