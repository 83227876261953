import * as React from "react";
import {
  Button,
  Card,
  CardBody,
  Flex,
  Image,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Stack,
  Icon,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from '../../layout/TranslationContext';
import { translatePage } from '../../layout/translatePage';
import { ProjectCard } from "./components/ProjectCard";
import { Categories } from "./components/Categories";
import { IoQrCode } from "react-icons/io5";
import { BsImage } from "react-icons/bs";
import { BiMapPin } from "react-icons/bi";
// @ts-ignore
import QuickstartThumbnail from "./components/Quickstart_thumbnail3small.jpg";
import {
  useMutationCreateProject,
  useQueryMe,
  useQueryProjects,
} from "state/remoteState";
import { AccessType, AnchorType, ProjectBean, ProjectType } from "api/model";
import { HelpIconButton } from "pages/HelpIconButton";
import {useEffect} from "react";
export const Home = () => {
  const {
    isOpen: isNewProjectOpen,
    onOpen: onNewProjectOpen,
    onClose: onNewProjectClose,
  } = useDisclosure();
  const { data: me } = useQueryMe();
  const { data: projects } = useQueryProjects();
  const mutationCreateProject = useMutationCreateProject();
  const [categories, setCategories] = React.useState([""]);
  const navigate = useNavigate();
  const [filteredProjects, setFilteredProjects] = React.useState<ProjectBean[]>(
    [],
  );
  const [activeCategory, setActiveCategory] = React.useState("all");
  const { language, setLanguage } = useTranslation();
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);
  React.useEffect(() => {
    if (!projects) {
      return;
    }
    setFilteredProjects(projects);
    // Collect all unique categories from all projects
    const allCategories = new Set<string>();
    projects.forEach((project) => {
      if (!project.categories) return;
      project.categories.forEach((category) => {
        allCategories.add(category);
      });
    });
    setCategories(Array.from(allCategories));
  }, [projects]);
  const modalRef = React.useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const hasTranslated = React.useRef(false);

  React.useEffect(() => {
    if (isNewProjectOpen) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false); 
    }
  }, [isNewProjectOpen]);

  React.useEffect(() => {

  if (modalIsOpen && modalRef.current) {
    translatePage(modalRef.current!, language);
  }
}, [modalIsOpen]);
  // React.useEffect(() => {
  //   if (isNewProjectOpen) {
  //   translatePage(document.body,language);

  //   }
  // }, [isNewProjectOpen, language]);
  React.useEffect(() => {
    const sessionKey = `translated_${language}`;
    if (!sessionStorage.getItem(sessionKey)) {
      translatePage(document.body,language);
      sessionStorage.setItem(sessionKey, 'true');
      hasTranslated.current = true;
    } else {
      translatePage(document.body,language);

    }
  }, [language, filteredProjects]);
  
  if (!projects || !me) {
    return null;
  }

  const onCategorySelected = function (category: string) {
    setActiveCategory(category);
    if (category === "all") {
      setFilteredProjects(projects ?? []);
      return;
    }
    if (!projects) return;
    if (category === "Published" || category === "Draft") {
      setFilteredProjects(
        projects.filter((x) => {
          if (category === "Draft") {
            return x.status === "Draft" || x.status === "Created";
          }
          return x.status === category;
        }),
      );
    } else {
      setFilteredProjects(
        projects.filter((x) => {
          if (!x.categories) return false;
          return x.categories.indexOf(category) >= 0;
        }),
      );
    }
  };

  function handleCreateProject(anchorType: AnchorType): void {
    const newProject: ProjectBean = {
      type: ProjectType.Multiple,
      name: "Untitled Project",
      subscriptionId: process.env.REACT_APP_subscription_ID,
      editors: [
        {
          name: me?.name,
          email: me?.email,
        },
      ],
      accessType: AccessType.MembersOnly,
      anchorType: anchorType,
    };

    mutationCreateProject.mutate(newProject, {
      onSuccess(data) {
        navigate(`/projects/${data.id}`);
      },
      onError(error) {
        console.error(error);
      },
    });
  }

  const showQuickStartVideoInNewWindow = () => {
    const width = 700;
    const height = 620;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const windowFeatures = `width=${width},height=${height},left=${left},top=${top}`;
    window.open(
      "https://www.nextcreate.com/de/hilfe/quickstart-de",
      "HelpWindow",
      windowFeatures,
    );
  };

  return (
    <>
      <Grid mt="2rem" ml="60px" gap="8">
        <GridItem>
          <Heading size="xl">My Projects</Heading>
        </GridItem>
        <GridItem>
          <Grid
            width="100%"
            justifyContent="space-between"
            templateColumns={"1fr 150px"}
            alignItems="center"
            pr="50px"
          >
            <div>
              <Categories
                categories={categories}
                onCategorySelected={onCategorySelected}
                activeCategory={activeCategory}
              />
            </div>
            <Flex width="100%" justifyContent="flex-end">
              <Button onClick={onNewProjectOpen} width="152px">
                New Project
              </Button>
            </Flex>
          </Grid>
        </GridItem>
        <GridItem>
          <Grid
            templateColumns="repeat(auto-fill, minmax(266px,1fr))"
            gap="8"
            pr="50px"
          >
            <Card 
              _hover={{
              cursor: "pointer",
              transform: "scale(1.05)",
              transition: "transform .2s ease-in-out",
              zIndex: "9999",
               }}
              borderRadius="8"
              height="350px"
              width="100%"
              maxW="sm"
              backgroundColor="#FAFAFA"
              position="relative"
              onClick={showQuickStartVideoInNewWindow}
            >
              <Image
                src={QuickstartThumbnail}
                alt="Card Image"
                objectFit="cover"
                height="50%"
                width="100%"
              />
              <CardBody>
                <Flex direction="column" gap="2">
                  <Text fontSize="xl" mt="5" fontWeight="bold" data-nontranslatable="Quickstart Video Tutorial">
                    Quickstart Video Tutorial
                  </Text>
                  <Text fontSize="md" mt="-2">
                    Das Wichtigste in 5 Minuten!
                  </Text>
                </Flex>
              </CardBody>
            </Card>

            {filteredProjects.map((project, i) => {
              return (
                <ProjectCard
                data-nontranslatable={`${project.name} ${project.description}`}
                  key={i}
                  // authors={post.subscription.subscriptionName}
                  date={project.dateLastModified ?? "Unknown"}
                  type={project.type}
                  description={project.description ?? ""}
                  published={project.status === "Published"}
                  title={project.name}
                  users={
                    project.editors?.map(
                      (editor) =>
                        editor.name ?? editor.email ?? "Unknown editor",
                    ) ?? []
                  }
                  accessType={project.accessType}
                  id={project.id ?? ""}
                  thumbnail={project.thumbnailUri ?? ""}
          
                />
              );
            })}
          </Grid>
        </GridItem>
      </Grid>

      <Modal isOpen={isNewProjectOpen} onClose={onNewProjectClose} initialFocusRef={modalRef} size="4xl">
        <ModalOverlay />
        <ModalContent ref={modalRef} p="24px 12px" borderRadius="29" mt="52">
          <ModalHeader>
            <Heading color="black">Select project type</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack gap="4">
              <Text fontWeight="400" lineHeight="7" color="black">
                For now you can only create Image Marker & QR Code projects.
                Spatial Anchor support is coming soon!
              </Text>
              <Grid
                templateColumns="repeat(auto-fit, minmax(233px,1fr))"
                gap="8"
              >
                <Card
                  maxW="sm"
                  _hover={{
                    cursor: "pointer",
                    transform: "scale(1.05)",
                    transition: "transform .2s ease-in-out",
                    zIndex: "9999",
                  }}
                  borderRadius="8"
                  onClick={() => handleCreateProject(AnchorType.QrCode)}
                >
                  <CardBody p="0">
                    <Image
                      src="./img/ProjectType_qr.png"
                      alt="Green double couch with wooden legs"
                      borderTopLeftRadius="8"
                      borderTopRightRadius="8"
                      loading={"eager"}
                    />
                    <Stack p="4">
                      <Flex align="center" gap="2">
                        <Icon as={IoQrCode} />
                        <Text
                          fontSize="md"
                          fontWeight="semibold"
                          color="black"
                          lineHeight="6"
                          data-nontranslatable="Image Marker"
                        >
                          QR Code
                        </Text>
                      </Flex>
                      <Text
                        fontSize="xs"
                        fontWeight="medium"
                        color="primary.4"
                        lineHeight="4"
                      >
                        Create an On Screen Project with our Web Editor which is
                        triggered by a QR Code
                      </Text>
                    </Stack>
                  </CardBody>
                </Card>
                <Card
                  maxW="sm"
                  _hover={{
                    cursor: "pointer",
                    transform: "scale(1.05)",
                    transition: "transform .2s ease-in-out",
                    zIndex: "9999",
                  }}
                  borderRadius="8"
                  onClick={() => handleCreateProject(AnchorType.Image)}
                >
                  <CardBody p="0">
                    <Image
                      src="./img/ProjectType_ImageMarker.png"
                      alt="Green double couch with wooden legs"
                      borderTopLeftRadius="8"
                      borderTopRightRadius="8"
                    />
                    <Stack p="4">
                      <Flex align="center" gap="2">
                        <Icon as={BsImage} />
                        <Text
                          fontSize="md"
                          fontWeight="semibold"
                          color="black"
                          lineHeight="6"
                          data-nontranslatable="Image Marker"
                        >
                          Image Marker
                        </Text>
                      </Flex>
                      <Text
                        fontSize="xs"
                        fontWeight="medium"
                        color="primary.4"
                        lineHeight="4"
                      >
                        Create an AR or On Screen Project which is triggered by
                        an Image Marker
                      </Text>
                    </Stack>
                  </CardBody>
                </Card>
                <Card
                  maxW="sm"
                  _hover={{
                    zIndex: "9999",
                    cursor: "not-allowed",
                  }}
                  borderRadius="8"
                >
                  <CardBody p="0">
                    <Image
                      src="./img/ProjectType_SpatialAnchors.png"
                      alt="Green double couch with wooden legs"
                      borderTopLeftRadius="8"
                      borderTopRightRadius="8"
                    />
                    <Stack p="4">
                      <Flex align="center" gap="2">
                        <Icon as={BiMapPin} />
                        <Text
                          fontSize="md"
                          fontWeight="semibold"
                          color="black"
                          lineHeight="6"
                          data-nontranslatable="Spatial Anchors"
                        >
                          Spatial Anchors
                        </Text>
                      </Flex>
                      <Text
                        fontSize="xs"
                        fontWeight="medium"
                        color="primary.4"
                        lineHeight="4"
                      >
                        Create an AR Project which is triggered by a 3D Anchor
                        in the room
                      </Text>
                    </Stack>
                  </CardBody>
                </Card>
              </Grid>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <HelpIconButton
        link="https://www.nextcreate.com/help/project-overview"
        isFloating={true}
      />
    </>
  );
};
