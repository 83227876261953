import { FC, useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { TabVideoUpload } from "./TabVideoUpload";

import { Content, ContentType } from "api/model";
import { TabVideoURL } from "./TabVideoURL";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { useTranslation } from "../../../../../../../layout/TranslationContext";
import { translatePage } from "../../../../../../../layout/translatePage";
import { ResourceLibrary } from "../ResourceLibrary";
import { ContentCategory } from "pages/Library/ContentCategoryEnum";

export const contentEditorVideoSupportedContentTypes = [
  ContentType.Video,
  ContentType.Youtube,
  ContentType.Vimeo,
];

export const DialogContentEditorVideo: FC<CreateOrUpdateContentProps> = (
  props,
) => {
  const onClose = props.onClose;
  const content =
    props.content ??
    ({
      type: ContentType.Video,
    } as Content);
  const mode = content ? "update" : "create";
  let tabIndex = 0;
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    console.log(props.isOpen);
    if (props.isOpen) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (modalIsOpen && modalRef.current) {
      translatePage(modalRef.current!, language);
    }
  }, [modalIsOpen]);
  switch (content.type) {
    case ContentType.Video:
      tabIndex = 0;
      break;
    case ContentType.Youtube:
    case ContentType.Vimeo:
      tabIndex = 1;
      break;
    default:
      throw new Error("Unknown content type");
  }

  return (
    <Modal {...props} size="3xl" initialFocusRef={modalRef}>
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef}>
        <ModalHeader fontSize="36px" fontWeight="700" pt="10">
          {mode === "create" ? "Add Video" : "Update Video"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs variant="soft-rounded" defaultIndex={tabIndex}>
            <TabList
              backgroundColor="primary.0"
              p="1"
              borderRadius="50"
              gap="4"
              width="fit-content"
              height="40px"
            >
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Upload
              </Tab>
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                URL
              </Tab>
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Library
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px="0" py="6">
                <TabVideoUpload {...props} />
              </TabPanel>
              <TabPanel px="0" py="6">
                <TabVideoURL {...props} />
              </TabPanel>
              <TabPanel pt="8" pb="10">
                <ResourceLibrary
                  content={content}
                  category={ContentCategory.Videos}
                  onSuccess={onClose}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
