import {
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useRadioGroup,
} from "@chakra-ui/react";
import { TbBorderRight, TbResize } from "react-icons/tb";
import { BsArrowsMove, BsLightbulb, BsTree } from "react-icons/bs";
import {
  MdOutlineLight,
  MdOutlineRotate90DegreesCcw,
  MdViewInAr,
} from "react-icons/md";
import { BiCamera } from "react-icons/bi";
import { CustomRadio } from "./CustomRadio";
import { GrSun } from "react-icons/gr";
import { GiFlatPlatform } from "react-icons/gi";
import {
  useProjectChapterId,
  useStateActiveContent,
  useStateTransformControlMode,
} from "state/localState";
import { FC, ReactElement } from "react";
import {
  useMutationUpdateChapter,
  useMutationUpdateContent,
  useQueryChapter,
} from "state/remoteState";
import { CameraPosition, ContentType, Orientation } from "api/model";

export const ControlButtons = () => {
  const { projectId, chapterId } = useProjectChapterId();
  const { data: chapter } = useQueryChapter(projectId, chapterId);
  const content = useStateActiveContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const mutationUpdateChapter = useMutationUpdateChapter();

  const handleLightingTypeChange = (lightingType: string) => {
    if (!content) return;
    if (!content.configurations) content.configurations = {};
    content.configurations.lightingType = lightingType;
    mutationUpdateContent.mutate({ projectId, chapterId, content });
  };

  const handleOrientationChange = (value: Orientation) => {
    if (!chapter) return;
    const chapterToUpdate = { ...chapter, orientation: value };
    mutationUpdateChapter.mutate({
      projectId,
      chapter: chapterToUpdate,
    });
  };

  const handleCameraPosition = (value: CameraPosition) => {
    if (!chapter) return;
    const chapterToUpdate = { ...chapter, cameraPosition: value };
    mutationUpdateChapter.mutate({
      projectId,
      chapter: chapterToUpdate,
    });
  };

  // Lighting radio group
  const { getRadioProps: LightingRadioProps, getRootProps: LightingRootProps } =
    useRadioGroup({
      value: content?.configurations?.lightingType ?? "simple",
      onChange: handleLightingTypeChange,
    });

  // Orientation radio group
  const {
    getRadioProps: OrientationRadioProps,
    getRootProps: OrientationRootProps,
  } = useRadioGroup({
    value: chapter?.orientation ?? Orientation.Flat,
    onChange: handleOrientationChange,
  });

  // Camera radio group
  const { getRadioProps: CameraRadioProps, getRootProps: CameraRootProps } =
    useRadioGroup({
      value: chapter?.cameraPosition ?? CameraPosition.Default,
      onChange: handleCameraPosition,
    });

  return (
    <Flex
      border="1px solid #E2E8F0"
      borderRadius="50"
      align="center"
      backgroundColor="white"
      pl="1"
      pr="1"
    >
      <TransformControlModeButton
        mode="translate"
        isActive={content?.type === ContentType.Value3DModel}
      />
      <TransformControlModeButton
        mode="rotate"
        isActive={content?.type === ContentType.Value3DModel}
      />
      <TransformControlModeButton
        mode="scale"
        isActive={content?.type === ContentType.Value3DModel}
      />
      <Divider
        orientation="vertical"
        height="28px"
        backgroundColor="gray.200"
        ml="1"
      />
      <Menu>
        <MenuButton
          aria-disabled={content === undefined}
          as={IconButton}
          aria-label="Light"
          icon={<BsLightbulb />}
          variant="transparent"
          isDisabled={content?.type !== ContentType.Value3DModel}
        />
        <MenuList>
          <Flex direction="column" gap="0" {...LightingRootProps()}>
            <Text
              fontSize="sm"
              fontWeight="600"
              color="primary.4"
              paddingLeft="3"
              paddingRight="3"
            >
              Lighting
            </Text>
            <CustomRadio
              icon={<GrSun fontWeight="500" />}
              {...LightingRadioProps({ value: "simple" })}
            >
              Simple
            </CustomRadio>
            <CustomRadio
              icon={<MdOutlineLight fontWeight="500" />}
              {...LightingRadioProps({ value: "industrial" })}
            >
              Industrial
            </CustomRadio>
            <CustomRadio
              icon={<BsTree fontWeight="500" />}
              {...LightingRadioProps({ value: "forest" })}
            >
              Forest
            </CustomRadio>
          </Flex>
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Orientation"
          icon={<MdViewInAr />}
          variant="transparent"
        />
        <MenuList>
          <Flex direction="column" gap="0" {...OrientationRootProps()}>
            <Text
              fontSize="sm"
              fontWeight="600"
              color="primary.4"
              paddingLeft="3"
              paddingRight="3"
            >
              Orientation
            </Text>
            <CustomRadio
              icon={<GiFlatPlatform fontWeight="500" />}
              {...OrientationRadioProps({ value: Orientation.Flat })}
            >
              Flat
            </CustomRadio>
            <CustomRadio
              icon={<TbBorderRight fontWeight="500" />}
              {...OrientationRadioProps({ value: Orientation.Upright })}
            >
              Upright
            </CustomRadio>
          </Flex>
        </MenuList>
      </Menu>
      <Menu>
        {/*// @ts-ignore*/}
        <MenuButton
          // aria-disabled={content === undefined}
          as={IconButton}
          aria-label="Scale"
          icon={<BiCamera />}
          variant="transparent"
        />
        <MenuList>
          <Flex direction="column" gap="0" {...CameraRootProps()}>
            <Text
              fontSize="sm"
              fontWeight="600"
              color="primary.4"
              paddingLeft="3"
              paddingRight="3"
            >
              Camera
            </Text>
            <CustomRadio
              {...CameraRadioProps({ value: CameraPosition.Default })}
            >
              Default view
            </CustomRadio>
            <CustomRadio {...CameraRadioProps({ value: CameraPosition.Front })}>
              Front view
            </CustomRadio>
            <CustomRadio {...CameraRadioProps({ value: CameraPosition.Side })}>
              Side view
            </CustomRadio>
            <CustomRadio {...CameraRadioProps({ value: CameraPosition.Top })}>
              Top view
            </CustomRadio>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
};

type transformConstrolModeType = Exclude<
  ReturnType<typeof useStateTransformControlMode>[0],
  undefined
>;

interface TransformControlModeButtonProps {
  mode: transformConstrolModeType;
  isActive: boolean;
}

const TransformControlModeButton: FC<TransformControlModeButtonProps> = ({
  mode,
  isActive,
}) => {
  const [transformControlMode, setTransformControlMode] =
    useStateTransformControlMode();
  const content = useStateActiveContent();

  const icons: Record<transformConstrolModeType, ReactElement> = {
    translate: <BsArrowsMove />,
    rotate: <MdOutlineRotate90DegreesCcw />,
    scale: <TbResize />,
  };

  return (
    <IconButton
      aria-label="Translate"
      size="sm"
      variant="outline"
      border="0px"
      onClick={() => {
        if (transformControlMode === mode) {
          setTransformControlMode(undefined);
          return;
        }
        setTransformControlMode(mode);
      }}
      isDisabled={!isActive || !content}
      isActive={transformControlMode === mode}
      icon={icons[mode]}
    />
  );
};
