import {
  AuthenticationResult,
  type Configuration,
  EventMessage,
  EventType,
  type PopupRequest,
} from "@azure/msal-browser";
import * as msal from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID ?? "",
    authority: process.env.REACT_APP_AUTHORITY,
    // eslint-disable-next-line no-undef
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: "/",
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES ?? ""],
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

const scopes = ["openid", process.env.REACT_APP_SCOPE ?? ""];

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest: PopupRequest = {
  scopes: scopes,
};

const passwordForgotRequest: PopupRequest = {
  scopes: scopes,
  authority: msalConfig.auth.authority?.replace(
    "B2C_1A_SIGNUP_SIGNIN",
    "B2C_1A_PASSWORDRESET",
  ),
};

const msalInstance = new msal.PublicClientApplication(msalConfig);
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
  
  if(event.eventType == EventType.LOGIN_FAILURE && event.error && event.error.message.indexOf("AADB2C90077")){
      msalInstance.loginRedirect();
  }
  
  if (
    event.eventType === EventType.LOGIN_FAILURE &&
    event.error &&
    event.error.message.indexOf("AADB2C90118") > -1
  ) {
    msalInstance.loginRedirect(passwordForgotRequest);
  }
});
msalInstance.initialize();

export { msalInstance, loginRequest, passwordForgotRequest, scopes };
