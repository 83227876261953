import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  useQueryMe,
  useQueryStatistics,
  useQueryAllStatistics,
  useQueryFilteredStatistics,
  useQueryFilteredAllStatistics,
} from "state/remoteState";
import PropTypes from "prop-types";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import {
  Box,
  Text,
  Flex,
  Grid,
  Heading,
  GridItem,
  Tabs,
  TabList,
  Tab,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import DateRangePicker from "./Calendar";
import { theme } from "theme/theme";
import { useTranslation } from '../../layout/TranslationContext';
import { translatePage, translateText } from '../../layout/translatePage';
type Color = string;
const primaryValues = Object.values(theme.colors.primary) as Color[];
const COLORS: Color[] = primaryValues.slice(8, 12);
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  chartType,
  data,
  index,
}) => {
  // Calculate label position
  const labelRadius =
    chartType === "projects"
      ? innerRadius + (outerRadius - innerRadius) * 0.55
      : outerRadius * 1.33;
  const labelX = cx + labelRadius * Math.cos(-midAngle * (Math.PI / 180));
  const labelY = cy + labelRadius * Math.sin(-midAngle * (Math.PI / 180));
  return (
    <text
    data-nontranslatable
      x={labelX + 5}
      y={labelY + 5}
      fill="black"
      fontSize={10}
      fontWeight="bold"
      textAnchor="end"
      dominantBaseline={midAngle < 20 ? "end" : "start"}
    >
      {`${(percent * 100).toFixed(0)}%`}
      {midAngle < 5 ? (
        <tspan x={labelX} y={labelY + 10} data-nontranslatable>
          [{data[index].value}]
        </tspan>
      ) : (
        <tspan x={labelX + 25} y={labelY} data-nontranslatable>
          [{data[index].value}]
        </tspan>
      )}
    </text>
  );
};

renderCustomizedLabel.propTypes = {
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  midAngle: PropTypes.number.isRequired,
  innerRadius: PropTypes.number.isRequired,
  outerRadius: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  chartType: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  index: PropTypes.number.isRequired,
};

export const Analytics = () => {
  const [allAccounts, setAllAccounts] = React.useState(false);
  const toast = useToast();
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [timeRange, setTimeRange] = React.useState<string>("");

  const [errorDisplayed, setErrorDisplayed] = React.useState(false);

  const { data: me } = useQueryMe();
  const {
    data: statistics,
    isSuccess: isSuccessStatistics,
    refetch: refetchStatistics,
  } = useQueryStatistics();
  const {
    data: allStatistics,
    isSuccess: isSuccessAllStatistics,
    refetch: refetchAllStatistics,
  } = useQueryAllStatistics();
  const actualStartDate = startDate ?? new Date();
  const actualEndDate = endDate ?? new Date();
  const {
    data: filteredStatistics,
    refetch: refetchFilteredStatistics,
    isSuccess: isSuccessFilteredStatistics,
  } = useQueryFilteredStatistics(actualStartDate, actualEndDate);
  const {
    data: filteredAllStatistics,
    refetch: refetchAllFilteredStatistics,
    isSuccess: isSuccessFilteredAllStatistics,
  } = useQueryFilteredAllStatistics(actualStartDate, actualEndDate);
  const location = useLocation();

  const showTranslatedToast = async (message: string, language: string) => {
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage,
    });
  };
  React.useEffect(() => {
    if (location.pathname === "/analytics") {
      refetchStatistics();
      refetchAllStatistics();
      refetchFilteredStatistics();
      refetchAllFilteredStatistics();
    }
  }, [location.pathname]);
  const { language, setLanguage } = useTranslation();
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    console.log('Translating to language:', language);
    translatePage(document.body, language);
  }, [language]);
  let switchData = allAccounts ? filteredAllStatistics : filteredStatistics;

  React.useEffect(() => {
    setErrorDisplayed(false);
  }, [startDate, endDate, allAccounts]);

  React.useEffect(() => {
    if (
      !errorDisplayed &&
      switchData?.projectsByType &&
      Object.keys(switchData.projectsByType).length === 0
    ) {
      showTranslatedToast("No statistics found for the selected time range", language)
      // toast({
      //   title: "Error",
      //   description: "No statistics found for the selected time range",
      //   status: "error",
      //   duration: 1000,
      //   isClosable: true,
      // });
      setErrorDisplayed(true);
    }
  }, [filteredStatistics, filteredAllStatistics, allAccounts]);
  const dateNow = new Date();
  const handleChangeStart = (date: Date) => {
    const accurateDate = new Date(date);
    accurateDate.setHours(
      dateNow.getHours(),
      dateNow.getMinutes(),
      dateNow.getSeconds(),
    );
    setStartDate(accurateDate);
  };
  const handleChangeEnd = (date: Date) => {
    const accurateDate = new Date(date);
    accurateDate.setHours(
      dateNow.getHours(),
      dateNow.getMinutes(),
      dateNow.getSeconds(),
    );
    setEndDate(accurateDate);
  };
  //console.log(startDate, endDate);
  const handleChangeTimeRange = (time: string) => {
    setTimeRange(time);
    let rangeStartDate;
    if (time) {
      const currentDate = new Date();
      switch (time) {
        case "Last Week":
          rangeStartDate = new Date(currentDate);
          rangeStartDate.setDate(currentDate.getDate() - 7);
          break;
        case "Last Month":
          rangeStartDate = new Date(currentDate);
          rangeStartDate.setMonth(currentDate.getMonth() - 1);
          break;
        case "Last 3 Months":
          rangeStartDate = new Date(currentDate);
          rangeStartDate.setMonth(currentDate.getMonth() - 3);
          break;
        case "Last 6 Months":
          rangeStartDate = new Date(currentDate);
          rangeStartDate.setMonth(currentDate.getMonth() - 6);
          break;
        case "Last Year":
          rangeStartDate = new Date(currentDate);
          rangeStartDate.setFullYear(currentDate.getFullYear() - 1);
          break;
        case "All Statistics":
        default:
          break;
      }
      setStartDate(rangeStartDate);
      setEndDate(currentDate);
      if (!rangeStartDate) {
        console.error(
          "Error determining start date for the selected time range",
        );
        return;
      }
    }
  };

  let dataByType;
  let dataByStatus;
  let dataByUsedContentTypes;

  if (!allAccounts && statistics) {
    dataByType =
      filteredStatistics?.projectsByType &&
      Object.keys(filteredStatistics.projectsByType).length !== 0
        ? filteredStatistics.projectsByType
        : statistics.projectsByType ?? {};
    dataByStatus =
      filteredStatistics?.projectsByStatus &&
      Object.keys(filteredStatistics.projectsByStatus).length !== 0
        ? filteredStatistics.projectsByStatus
        : statistics.projectsByStatus ?? {};
    dataByUsedContentTypes =
      filteredStatistics?.usedContentTypes &&
      Object.keys(filteredStatistics.usedContentTypes).length !== 0
        ? filteredStatistics.usedContentTypes
        : statistics.usedContentTypes ?? {};
  } else {
    dataByType =
      filteredAllStatistics?.projectsByType &&
      Object.keys(filteredAllStatistics.projectsByType).length !== 0
        ? filteredAllStatistics.projectsByType ?? {}
        : allStatistics?.projectsByType ?? {};
    dataByStatus =
      filteredAllStatistics?.projectsByStatus &&
      Object.keys(filteredAllStatistics.projectsByStatus).length !== 0
        ? filteredAllStatistics.projectsByStatus ?? {}
        : allStatistics?.projectsByStatus ?? {};
    dataByUsedContentTypes =
      filteredAllStatistics?.usedContentTypes &&
      Object.keys(filteredAllStatistics.usedContentTypes).length !== 0
        ? filteredAllStatistics.usedContentTypes ?? {}
        : allStatistics?.usedContentTypes ?? {};
  }

  const dataType = Object.entries(dataByType).map(([name, value]) => ({
    name:
      name === "Multiple"
        ? "Random"
        : name === "Ordered"
          ? "Step By Step"
          : "Single",
    value,
  }));

  const dataRoles = Object.entries(
    allAccounts
      ? allStatistics?.usersRoles ?? {}
      : statistics?.usersRoles ?? {},
  ).map(([name, value]) => ({
    name,
    value,
  }));

  const dataStatus = Object.entries(dataByStatus).map(([name, value]) => ({
    name,
    value,
  }));

  const CustomLegend = ({ data, justif }) => {
    const renderLegendItems = () => {
      return data.map((entry, index) => (
        <Box key={`legend-${index}`} display="flex" alignItems="center" mr={4}>
          <Box
            width="10px"
            height="10px"
            backgroundColor={COLORS[index % COLORS.length]}
            marginRight="4px"
          />
          <Text color="black">{entry.name}</Text>
        </Box>
      ));
    };

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent={justif}
        flexWrap="wrap"
      >
        {renderLegendItems()}
      </Box>
    );
  };

  CustomLegend.propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    justif: PropTypes.string.isRequired,
  };

  const CustomXAxisLabel = (props) => {
    const { x, y, payload } = props;
    return (
      <text
        x={x}
        y={y}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform={`rotate(-25, ${x}, ${y})`}
        fontSize="10"
        fontWeight="bold"
        style={{ fontSize: 8 }}
      >
        {payload.value}
      </text>
    );
  };

  CustomXAxisLabel.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  };

  const CustomSecondLegend = ({ content1, content2, color1, color2 }) => (
    <div
      style={{
        display: "flex",
        marginTop: "-5px",
        marginRight: "80px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: color1,
          marginLeft: "20px",
          marginRight: "5px",
        }}
      ></div>
      <Text color="black">{content1}</Text>

      <div
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: color2,
          marginLeft: "20px",
          marginRight: "5px",
        }}
      ></div>
      <Text color="black">{content2}</Text>
    </div>
  );

  CustomSecondLegend.propTypes = {
    content1: PropTypes.string.isRequired,
    content2: PropTypes.string.isRequired,
    color1: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
  };

  const contentTypesOneSubscriber =
    filteredStatistics?.usedContentTypes &&
    Object.keys(filteredStatistics.usedContentTypes).length !== 0
      ? filteredStatistics.usedContentTypes
      : statistics?.usedContentTypes ?? {};
  const contentTypesAllSubscribers =
    filteredAllStatistics?.usedContentTypes &&
    Object.keys(filteredAllStatistics.usedContentTypes).length !== 0
      ? filteredAllStatistics.usedContentTypes ?? {}
      : allStatistics?.usedContentTypes ?? {};
  const contentTypes = !allAccounts
    ? contentTypesOneSubscriber
    : contentTypesAllSubscribers;
  const contentTypesArray = [{}];
  Object.entries(contentTypes).forEach(([name, properties]) => {
    const displayModeArray = Object.getOwnPropertyNames(properties);
    const valuesArray = Object.values(properties);

    displayModeArray.forEach((item, index) => {
      contentTypesArray.push({
        name,
        AR:
          item === "Augmented Reality"
            ? (valuesArray[index] as number) || 0
            : 0,
        "On Screen":
          item === "Web Page" ? (valuesArray[index] as number) || 0 : 0,
      });
    });
  });

  contentTypesArray.splice(0, 1);
  function haveSameName(obj1: any, obj2: any): boolean {
    return obj1.name === obj2.name;
  }
  for (let i = 0; i < contentTypesArray.length; i++) {
    for (let j = i + 1; j < contentTypesArray.length; j++) {
      if (haveSameName(contentTypesArray[i], contentTypesArray[j])) {
        contentTypesArray[i]["AR"] += contentTypesArray[j]["AR"];
        contentTypesArray[i]["On Screen"] += contentTypesArray[j]["On Screen"];
        contentTypesArray.splice(j, 1);
      }
    }
  }

  return statistics || allStatistics ? (
    <Grid mt="2rem" gap="8">
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        {me?.email?.includes("@interlake.net") ? (
          <Tabs
            isFitted
            width="100%"
            marginTop="-45px"
            variant="line"
            color={theme.colors.primary[5]}
            colorScheme={"white"}
          >
            <TabList mb="-20px">
              <Tab
                style={{
                  minWidth: "150px",
                  fontWeight: "bold",
                  fontSize: "20",
                  color: "#2E464F",
                  transition: "color 0.3s",
                  cursor: "pointer",
                }}
                onClick={() => setAllAccounts(false)}
                onMouseEnter={(e) => {
                  const target = e.target as HTMLElement;
                  target.style.backgroundColor = `${theme.colors.primary[5]}`;
                  target.style.color = "white";
                }}
                onMouseLeave={(e) => {
                  const target = e.target as HTMLElement;
                  target.style.backgroundColor = "white";
                  target.style.color = `${theme.colors.primary[5]}`;
                }}
              >
                My Analytics
              </Tab>
              <Tab
                style={{
                  minWidth: "150px",
                  fontWeight: "bold",
                  color: "#2E464F",
                  transition: "color 0.3s",
                  cursor: "pointer",
                }}
                onClick={() => setAllAccounts(true)}
                onMouseEnter={(e) => {
                  const target = e.target as HTMLElement;
                  target.style.backgroundColor = "#2E464F";
                  target.style.color = "white";
                }}
                onMouseLeave={(e) => {
                  const target = e.target as HTMLElement;
                  target.style.backgroundColor = "";
                  target.style.color = "#2E464F";
                }}
              >
                All Analytics
              </Tab>
            </TabList>
          </Tabs>
        ) : (
          <GridItem>
            <Heading size="xl" ml="60px" minWidth={200}>
              My Analytics
            </Heading>
          </GridItem>
        )}
      </Flex>
      <DateRangePicker
        timeRange={timeRange}
        start={startDate}
        end={endDate}
        handleChangeTimeRange={handleChangeTimeRange}
        handleChangeStart={handleChangeStart}
        handleChangeEnd={handleChangeEnd}
      />
      <Grid mt="-45px" templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}>
        <GridItem py={10}>
          <div style={{ height: 250 }} >
            <Text textAlign="center" fontSize="20" fontWeight="bold">
              Projects by Type
            </Text>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={dataType}
                  labelLine={false}
                  label={(props) =>
                    renderCustomizedLabel({
                      ...props,
                      chartType: "projects",
                      data: dataType,
                    })
                  }
                  outerRadius={90}
                  fill="#5584d8"
                  dataKey="value"
                  stroke=""
                >
                  {dataType.map((entry, index) => (
                    <Cell
                      key={`cell-${index} [entry.name]`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <CustomLegend data={dataType} data-nontranslatable={dataType} justif="center" />
          </div>
        </GridItem>

        <GridItem py={10}>
          <div style={{ height: 250 }}>
            <Text textAlign="center" fontSize="20" fontWeight="bold">
              Users by Role
            </Text>
            <ResponsiveContainer>
              <PieChart width={400}>
                <Pie
                  data={dataRoles}
                  innerRadius={70}
                  outerRadius={88}
                  fill="#8884d8"
                  paddingAngle={3}
                  dataKey="value"
                  label={(props) =>
                    renderCustomizedLabel({ ...props, data: dataRoles })
                  }
                >
                  {dataRoles.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <CustomLegend data={dataRoles} justif="center" />
          </div>
        </GridItem>

        <GridItem py={8} style={{ marginTop: "30px" }}>
          <div style={{ height: 250 }}>
            <Text textAlign="center" fontSize="20" fontWeight="bold">
              Projects by Status
            </Text>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={dataStatus}
                  labelLine={false}
                  label={(props) =>
                    renderCustomizedLabel({
                      ...props,
                      chartType: "projects",
                      data: dataStatus,
                    })
                  }
                  outerRadius={90}
                  fill="black"
                  dataKey="value"
                  stroke=""
                >
                  {dataStatus.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <CustomLegend data={dataStatus} justif="center" />
          </div>
        </GridItem>

        <GridItem style={{ marginTop: "60px" }}>
          <div>
            <Text textAlign="center" fontSize="20" mb="5" fontWeight="bold">
              Used Content Types
            </Text>
            <ResponsiveContainer width="90%" height={240}>
              <BarChart 
                data={contentTypesArray}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  height={40}
                  tick={<CustomXAxisLabel />}
                  angle={-80}
                  textAnchor="end"
                />
                <YAxis data-nontranslatable/>
                <Tooltip />
                <Bar dataKey="On Screen" stackId="a" fill="#4D888C" />
                <Bar dataKey="AR" stackId="a" fill="#D6DD88" />
              </BarChart>
            </ResponsiveContainer>
            <CustomSecondLegend
              content1="On Screen"
              content2="AR"
              color1="#4D888C"
              color2="#D6DD88"
            />
          </div>
        </GridItem>
      </Grid>
    </Grid>
  ) : (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color={theme.colors.primary[5]}
      size="xl"
      style={{ marginLeft: "40%", marginTop: "30%" }}
    />
  );
};
