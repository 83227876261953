import React, { useState, useEffect, forwardRef } from "react";
import { VStack, Flex, Select, Box, Grid, GridItem } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from '../../layout/TranslationContext';
import { translatePage } from '../../layout/translatePage';
interface CustomInputProps {
  value: Date | string;
  onClick: () => void;
  placeholder: string;
}

interface DateRangePickerProps {
  timeRange: string;
  start: Date | null;
  end: Date | null;
  handleChangeTimeRange: (time: string) => void;
  handleChangeStart: (date: Date) => void;
  handleChangeEnd: (date: Date) => void;
}

const CustomDatePicker: React.FC<DateRangePickerProps> = ({
  timeRange,
  start,
  end,
  handleChangeTimeRange,
  handleChangeStart,
  handleChangeEnd,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
    start,
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(end);
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(timeRange);

  const handleStartDateChange = (date: Date) => {
    setSelectedStartDate(date);
    handleChangeStart(date);
  };
  const handleEndDateChange = (date: Date) => {
    setSelectedEndDate(date);
    handleChangeEnd(date);
  };
  const handleTimeRangeChange = (time: string) => {
    setSelectedTimeRange(time);
    handleChangeTimeRange(time);
  };
  const { language, setLanguage } = useTranslation();
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    translatePage(document.body, language);
  }, []);
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  //console.log(isSmallScreen);
  return !isSmallScreen ? (
    <VStack
      style={{
        marginLeft: "100px",
        maxHeight: "5px",
        marginTop: "-10px",
        marginBottom: "20px",
      }}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Flex>
        <Box
          position="relative"
          width="250px"
          marginLeft="50px"
          marginRight="150px"
        >
          <Select
            value={selectedTimeRange || ""}
            onChange={(e) => handleTimeRangeChange(e.target.value)}
            style={{
              width: "250px",
              height: "25px",
              borderRadius: "4px",
              fontSize: "1rem",
              marginLeft: "10px",
              marginRight: "10px",
              padding: "0 20px 0 10px",
              borderColor: "black",
              boxShadow: "none",
            }}
            focusBorderColor="black"
          >
            {/* <option value="All Statistics">Select Time Range</option> */}
            <option value="All Statistics">All Statistics</option>
            <option value="Last Week">Last Week</option>
            <option value="Last Month">Last Month</option>
            <option value="Last 3 Months">Last 3 Months</option>
            <option value="Last 6 Months">Last 6 Months</option>
            <option value="Last Year">Last Year</option>
          </Select>
        </Box>
        {!timeRange || timeRange === "All Statistics" ? (
          <Flex>
            <Box marginLeft="10px">
              <DatePicker
                selected={selectedStartDate}
                onChange={handleStartDateChange}
                placeholderText="Start Date"
                dateFormat="MMMM d, yyyy"
                customInput={
                  <CustomInput
                    value={selectedStartDate || ""}
                    onClick={() => {}}
                    placeholder={selectedStartDate?.toString() || ""}
                  />
                }
              />
            </Box>
            <Box marginLeft="10px">
              <DatePicker
                selected={selectedEndDate}
                onChange={handleEndDateChange}
                placeholderText="End Date"
                dateFormat="MMMM d, yyyy"
                customInput={
                  <CustomInput
                    value={selectedEndDate || ""}
                    onClick={() => {}}
                    placeholder={selectedEndDate?.toString() || ""}
                  />
                }
              />
            </Box>
          </Flex>
        ) : null}
      </Flex>
    </VStack>
  ) : (
    <Grid
      marginLeft={10}
      templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }}
    >
      <GridItem position="relative" width="200px">
        <Select
          value={selectedTimeRange || ""}
          onChange={(e) => handleTimeRangeChange(e.target.value)}
          style={{
            width: "200px",
            height: "25px",
            borderRadius: "4px",
            fontSize: "1rem",
            marginLeft: "10px",
            marginRight: "10px",
            padding: "0 20px 0 10px",
            borderColor: "black",
            boxShadow: "none",
          }}
          focusBorderColor="black"
        >
          {/* <option value="All Statistics">Select Time Range</option> */}
          <option value="All Statistics">All Statistics</option>
          <option value="Last Week">Last Week</option>
          <option value="Last Month">Last Month</option>
          <option value="Last 3 Months">Last 3 Months</option>
          <option value="Last 6 Months">Last 6 Months</option>
          <option value="Last Year">Last Year</option>
        </Select>
      </GridItem>

      {!timeRange || timeRange === "All Statistics" ? (
        <Grid templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }}>
          <GridItem marginLeft="10px">
            <DatePicker
              selected={selectedStartDate}
              onChange={handleStartDateChange}
              placeholderText="Start Date"
              dateFormat="MMMM d, yyyy"
              customInput={
                <CustomInput
                  value={selectedStartDate || ""}
                  onClick={() => {}}
                  placeholder={selectedStartDate?.toString() || ""}
                />
              }
            />
          </GridItem>
          <GridItem marginLeft="10px">
            <DatePicker
              selected={selectedEndDate}
              onChange={handleEndDateChange}
              placeholderText="End Date"
              dateFormat="MMMM d, yyyy"
              customInput={
                <CustomInput
                  value={selectedEndDate || ""}
                  onClick={() => {}}
                  placeholder={selectedEndDate?.toString() || ""}
                />
              }
            />
          </GridItem>
        </Grid>
      ) : null}
    </Grid>
  );
};

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  (props, ref) => (
    <input
      {...props}
      ref={ref}
      value={
        props.value instanceof Date
          ? props.value.toLocaleDateString()
          : props.value.toString()
      }
      onClick={props.onClick}
      style={{
        width: "200px",
        height: "25px",
        border: "1px solid black",
        borderRadius: "4px",
        padding: "0.5rem",
      }}
      placeholder={props.placeholder}
    />
  ),
);

CustomInput.displayName = "CustomInput";

export default CustomDatePicker;
