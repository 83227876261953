import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { PanelContent } from "./PanelContent/PanelContent";
import { PanelChapterDetails } from "./PanelChapterDetails/PanelChapterDetails";
import { PanelMarker } from "./PanelMarker/PanelMarker";
import { useQueryChapter, useQueryProject } from "state/remoteState";
import { useProjectChapterId } from "state/localState";
import { AnchorType } from "api/model";
import { PanelContentQRCode } from "./PanelContent/PanelContentQRCode";
import { PanelQRCodes } from "./PanelMarker/PanelQRCodes";

export const ControlPanel = () => {
  const { projectId, chapterId } = useProjectChapterId();
  const project = useQueryProject(projectId).data;
  const chapter = useQueryChapter(projectId, chapterId).data;
  if (!chapter || !project) return null;
  
  
  return (
    <Tabs
      variant="soft-rounded"
      size="sm"
      border="1px solid #E2E8F0"
      borderRadius="6px"
      p="0 12px 24px 12px"
      overflow="auto"
      h="100%"
    >
      <TabList
        gap="2"
        justifyContent="center"
        position="sticky"
        top="0"
        pt="24px"
        pb="12px"
        backgroundColor="var(--chakra-colors-chakra-body-bg)"
      >
        <Tab _selected={{ backgroundColor: "primary.1" }}>
          Chapter&nbsp;details
        </Tab>
        <Tab _selected={{ backgroundColor: "primary.1" }}>
          {project.anchorType === AnchorType.Image && <>Image&nbsp;Marker</>}
          {project.anchorType === AnchorType.QrCode && <>QR&nbsp;Code</>}
        </Tab>
        <Tab _selected={{ backgroundColor: "primary.1" }}>Content</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <PanelChapterDetails chapter={chapter} />
        </TabPanel>
        <TabPanel>
          {project.anchorType === AnchorType.Image && <PanelMarker />}
          {project.anchorType === AnchorType.QrCode && <PanelQRCodes />}
        </TabPanel>
        <TabPanel>
          {project.anchorType === AnchorType.Image && <PanelContent />}
          {project.anchorType === AnchorType.QrCode && <PanelContentQRCode />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
