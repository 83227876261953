import {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import { Html } from "@react-three/drei";
import { Euler, Vector3 } from "three";
import { Content } from "api/model";
import {EDITOR_JS_TOOLS} from "../../ControlPanel/PanelContent/ContentEditorWizard/Website/reactEditorJSTools";
import {createReactEditorJS} from "react-editor-js";
import {EditorCore} from "@react-editor-js/core"
interface Props {
  content: Content;
}

export const ContentWebEditor: FC<Props> = ({ content }) => {
  const [data, setData] = useState(content.configurations?.editorJS?.data || {});
  //const ReactEditorJS = createReactEditorJS();
  const EditorJs = createReactEditorJS();
  const editorCore = useRef(null)
  
  useEffect(() => {
    setData(content.configurations?.editorJS.data ?? {})
  }, [content]);

  useEffect(() => {
    if(editorCore.current != null) {
      //@ts-ignore
      editorCore.current!._editorJS.isReady.then(() => {
        //@ts-ignore
        editorCore.current!._editorJS.render(data);
      });
    }
  }, [data]);
  //const [editorSize, setEditorSize] = useState({width: "400px", height: "776px"})
  const editorRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (editorRef != null) {
      handleResize();
    }
    if (editorRef != null && editorRef.current) {
      editorRef.current.addEventListener('wheel', handleScroll, { passive: false });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
      if (editorRef.current) {
        editorRef.current.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);
  const handleScroll = (e: WheelEvent) => {
    e.preventDefault();
    if(e.deltaY > 0) {
      editorRef.current!.scrollTop += 10; // Modify "10" to adjust the speed if needed.
    } else {
      editorRef.current!.scrollTop -= 10; // Modify "10" to adjust the speed if needed.
    }
  }
  const handleResize = () => {
    if(editorRef.current != null){
      const canvas = editorRef.current.parentElement!.parentElement!.parentElement!;
      editorRef.current!.style.width = canvas.clientWidth -12 +"px";
      editorRef.current!.style.height = canvas.clientHeight -12 +"px";
    }
  }
  const handleInitialize = useCallback((instance: EditorCore) => {
    // @ts-ignore
    editorCore.current = instance;
    handleResize();
  }, [])
  
  const { scale, rotation } = useMemo(() => {
    return {
      scale: new Vector3(
        1,
        1,
        0,
      ),
      rotation: new Euler(-Math.PI / 2, 0, Math.PI / 2),
    };
  }, []);
  return (
      <Html center={true} >
        <div ref={editorRef} style={{padding: "10px",overflowY: "scroll", overflowX: "hidden"}}>
        {data != null ?
              <EditorJs
                  tools={EDITOR_JS_TOOLS}
                  onInitialize={handleInitialize}
                  defaultValue={data}
                  readOnly={true}
              />
            : null}
        </div>
      </Html>
  );
};
