import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./reactEditorJSTools";
import {
  useMutationCreateContent,
  useMutationUpdateContent,
  useMutationCreateQRCodeForChapter,
  useQueryProject,
} from "state/remoteState";
import { useProjectChapterId } from "state/localState";
import { AnchorType, Content, ContentType } from "api/model";
import { translateText } from "../../../../../../../layout/translatePage";
import { useTranslation } from '../../../../../../../layout/TranslationContext';
interface Props {
  content: Content;
  mode: "create" | "update";
  onClose: () => void;
}

const ReactEditorJS = createReactEditorJS();

export const TabWebEditor: FC<Props> = ({ content, mode, onClose }) => {
  const [data, setData] = useState(
    content.configurations?.editorJS?.data || {},
  );
  const { projectId, chapterId } = useProjectChapterId();
  const project = useQueryProject(projectId).data;
  const editorCore = useRef<any>(null);
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const mutationCreateQRCodeForChapter = useMutationCreateQRCodeForChapter();
  const toast = useToast();
  const { language, setLanguage } = useTranslation();
  const showTranslatedToast = async (message1: string, message: string, language: string) => {
    const translatedMessage1 = await translateText(message1, language);
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage1,
      description: translatedMessage,
    });
  };
  useEffect(() => {
    if (content.configurations?.editorJS?.data) {
      setData(content.configurations.editorJS.data);
    }
  }, [content.configurations?.editorJS?.data]);

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const handleCreateContent = async () => {
    if (!editorCore.current || !projectId || !chapterId) return;
    const savedData = await editorCore.current.save();

    const newContent = {
      name: "Web-Editor",
      configurations: {
        urlEndpoint: {},
        editorJS: { data: savedData },
      },
      type: ContentType.EditorJs,
    };

    mutationCreateContent.mutate(
      {
        projectId,
        chapterId,
        content: newContent,
      },
      {
        onSuccess: () => {
          if (project!.anchorType === AnchorType.QrCode) {
            mutationCreateQRCodeForChapter.mutate({
              projectId,
              chapterId,
            });
          }
          showTranslatedToast( "Content added", `Website ${newContent.name} was added`, language)
          // toast({
          //   status: "success",
          //   title: "Content added",
          //   description: `Website ${newContent.name} was added`,
          // });
          onClose();
        },
      },
    );
  };

  const handleUpdateContent = async () => {
    if (!editorCore.current || !projectId || !chapterId) return;
    const savedData = await editorCore.current.save();

    const updatedContent = {
      ...content,
      configurations: {
        ...content.configurations,
        editorJS: { data: savedData },
      },
    };

    mutationUpdateContent.mutate(
      {
        projectId,
        chapterId,
        content: updatedContent,
      },
      {
        onSuccess: () => {
          showTranslatedToast( "Content updated", `Website ${updatedContent.name} was updated`, language)
          // toast({
          //   status: "success",
          //   title: "Content updated",
          //   description: `Website ${updatedContent.name} was updated`,
          // });
          onClose();
        },
        onError: (error) => {
          console.error("Update failed:", error);
          showTranslatedToast( "Content update failed",  `Website could not be updated`, language)
          // toast({
          //   status: "error",
          //   title: "Content update failed",
          //   description: `Website could not be updated`,
          // });
        },
      },
    );
  };

  return (
    <>
      <Box
        border="1px solid"
        borderColor="gray.200"
        overflowY="auto"
        maxHeight="100%"
        maxWidth="100%"
        p="4"
      >
        <ReactEditorJS
          tools={EDITOR_JS_TOOLS}
          onInitialize={handleInitialize}
          defaultValue={data}
        />
      </Box>
      <Flex justifyContent="flex-end">
        {mode === "create" && (
          <Button
            isLoading={mutationCreateContent.isPending}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleCreateContent}
          >
            Add Content
          </Button>
        )}
        {mode === "update" && (
          <Button
            isLoading={mutationUpdateContent.isPending}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleUpdateContent}
          >
            Update Content
          </Button>
        )}
      </Flex>
    </>
  );
};
