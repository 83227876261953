import {
  createAnchor,
  createChapter,
  createContent,
  createProject,
  createQRCode,
  createResource,
  deleteAnchor,
  deleteChapter,
  deleteContent,
  deleteProject,
  deleteResource,
  publishProject,
  readChapter,
  readMe,
  readProject,
  readProjects,
  readResources,
  updateAnchor,
  updateChapter,
  updateContent,
  updateProject,
  readStatistics,
  readAllStatistics,
  readFilteredStatistics,
  readFilteredAllStatistics,
  readUsers,
  readUser,
  updateUser,
  deleteUser,
  readUsersGroups,
  readUsersGroup,
  createUserGroup,
  updateUsersGroup,
  deleteUsersGroup,
  createQRCodeForChapter,
} from "api/calls";
//import axios, { AxiosError } from "axios";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Anchor,
  Container,
  Content,
  ContentLibraryBean,
  ProjectBean,
  UserBean,
  UserGroup,
} from "api/model";
import { useProjectChapterId } from "./localState";

// =============== Me ===============
export const useQueryMe = () => {
  return useQuery({ queryKey: ["users/me"], queryFn: () => readMe() });
};

// =============== Resources ===============
export const useQueryResources = () => {
  return useQuery({ queryKey: ["resources"], queryFn: () => readResources() });
};

export const useMutationCreateResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ file, filename }: { file: File; filename?: string }) =>
      createResource(file, filename),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["resources"] });
    },
  });
};

// =============== Projects ===============
export const useMutationCreateProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (project: ProjectBean) => createProject(project),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
  });
};

export const useQueryProjects = () => {
  return useQuery({ queryKey: ["projects"], queryFn: () => readProjects() });
};

export const useQueryProject = (id: string) => {
  return useQuery({
    queryKey: ["projects", id],
    queryFn: () => readProject(id),
  });
};

export const useMutationUpdateProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (project: ProjectBean) => updateProject(project.id!, project),
    onMutate(variables) {
      queryClient.cancelQueries({ queryKey: ["projects", variables.id] });
      queryClient.setQueryData(["projects", variables.id], variables);
    },
    onSuccess: (_data, _project) => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.invalidateQueries({ queryKey: ["chapters"] });
      // queryClient.invalidateQueries({
      //   queryKey: ["projects", project.id],
      // });
    },
  });
};

export const useMutationUpdateProjectThumbnail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      projectId,
      thumbnail,
    }: {
      projectId: string;
      thumbnail: File;
    }) => {
      const resource = await createResource(thumbnail);
      const project = await readProject(projectId);
      project.thumbnailObjectId = resource.id;
      project.thumbnailUri = resource.sasUri;
      try {
        return updateProject(project.id!, project);
      } catch (err) {
        deleteResource(resource.id!);
        throw err;
      }
    },
    onSuccess: (_data, { projectId }) => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId],
      });
    },
  });
};

export const useMutationDeleteProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteProject(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
  });
};

export const useMutationPublishProject = () => {
  //use this
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (project: ProjectBean) => publishProject(project.id!),
    onSuccess: (_data, id) => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.invalidateQueries({
        queryKey: ["projects", id],
      });
    },
  });
};

// =================== Chapters ===================
export const useMutationCreateChapter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      projectId,
      chapter,
    }: {
      projectId: string;
      chapter: Container;
    }) => createChapter(projectId, chapter),
    onSuccess: (_data, { projectId }) => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.invalidateQueries({ queryKey: ["projects", projectId] });
    },
  });
};

export const useQueryChapter = (projectId: string, chapterId: string) => {
  return useQuery({
    queryKey: ["projects", projectId, "chapters", chapterId],
    queryFn: () => readChapter(projectId, chapterId),
    structuralSharing: (o, n) => {
      const oldData = o as Container;
      const newData = n as Container;
      // Do not update anchors[].sources and content[].sources
      // because they have new values every time we fetch the chapter (sas).
      // This causes the UI to re-render unnecessarily.
      if (oldData?.anchors && newData?.anchors) {
        newData.anchors.forEach((newAnchor, index) => {
          // Find anchors with the same id and if the sourceObjectId is the same,
          // then replace new "sources" field with the old one.
          const oldAnchor = oldData.anchors!.find(
            (oldAnchor) => oldAnchor.id === newAnchor.id,
          );
          if (!oldAnchor) return;
          if (oldAnchor.sourceObjectId === newAnchor.sourceObjectId) {
            newData.anchors![index].sources = oldAnchor.sources;
          }
        });
      }
      if (oldData?.content && newData?.content) {
        newData.content.forEach((newContent, index) => {
          // Find content with the same id and if the sourceObjectId is the same,
          // then replace new "sources" field with the old one.
          const oldContent = oldData.content?.find(
            (oldContent) => oldContent.id === newContent.id,
          );
          if (!oldContent) return;
          if (oldContent.sourceObjectId === newContent.sourceObjectId) {
            newData.content![index].sources = oldContent.sources;
          }
        });
      }
      return newData;
    },
  });
};
export const updateChapterLocally = (
  queryClient: QueryClient,
  projectId: string,
  chapter: Container,
) => {
  queryClient.setQueryData(
    ["projects", projectId, "chapters", chapter.id],
    (oldChapter: Container) => {
      console.log(chapter.name, chapter.sortOrder);
      return { ...oldChapter, ...chapter };
    },
  );
};
interface UpdateChapterParams {
  projectId: string;
  chapter: Container;
}

interface MutationContext {
  previousData?: Container[];
}

export const useMutationUpdateChapters = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      projectId,
      chapters,
    }: {
      projectId: string;
      chapters: Container[];
    }) => {
      const results: Container[] = [];
      for (const chapter of chapters) {
        results.push(await updateChapter(projectId, chapter));
      }
      return results;
    },
    onSuccess: (_data, { projectId }) => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId],
      });
    },
  });
};

export const useMutationUpdateChapter = () => {
  const queryClient = useQueryClient();
  return useMutation<Container, Error, UpdateChapterParams, MutationContext>({
    mutationFn: async ({ projectId, chapter }) => {
      return updateChapter(projectId, chapter);
    },
    onMutate: async ({ projectId, chapter }) => {
      await queryClient.cancelQueries({
        queryKey: ["projects", projectId, "chapters"],
      });

      const previousData = queryClient.getQueryData<Container[]>([
        "projects",
        projectId,
        "chapters",
      ]);

      queryClient.setQueryData<Container[]>(
        ["projects", projectId, "chapters"],
        (oldData) => {
          if (!oldData) {
            return [chapter];
          }
          return oldData.map((ch) => (ch.id === chapter.id ? chapter : ch));
        },
      );

      return { previousData };
    },
    onError: (error, variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          ["projects", variables.projectId, "chapters"],
          context.previousData,
        );
      }
      console.error("Mutation failed:", error);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["projects", variables.projectId],
      }); //console.log(data);
      console.log(variables.chapter.name, variables.chapter.sortOrder);
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["projects", variables.projectId, "chapters"],
      });
    },
  });
};

export const useMutationDeleteChapter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      projectId,
      chapterId,
    }: {
      projectId: string;
      chapterId: string;
    }) => deleteChapter(projectId, chapterId),
    onSuccess: (_data, { projectId, chapterId }) => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.invalidateQueries({ queryKey: ["projects", projectId] });
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "chapter", chapterId],
      });
    },
  });
};

// =================== Anchors ===================
export const useMutationCreateAnchor = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      projectId,
      chapterId,
      anchor,
      file,
    }: {
      projectId: string;
      chapterId: string;
      anchor: Anchor;
      file: File;
    }) => {
      let resource: ContentLibraryBean = await createResource(file);
      anchor.sourceObjectId = resource.id;
      try {
        return await createAnchor(projectId, chapterId, anchor);
      } catch (err) {
        deleteResource(resource.id ?? "");
        throw err;
      }
    },
    onSuccess: (_data, { projectId, chapterId }) => {
      queryClient.invalidateQueries({ queryKey: ["resources"] });
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "chapters", chapterId],
      });
    },
  });
};

// useMutationUpdateAnchor is for updating the anchor optimisticly.
export const useMutationUpdateAnchor = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      projectId,
      chapterId,
      anchor,
      file,
    }: {
      projectId: string;
      chapterId: string;
      anchor: Anchor;
      file?: File;
    }) => {
      let resource: ContentLibraryBean | undefined | null;
      if (file) {
        resource = await createResource(file);
        anchor.sourceObjectId = resource.id;
      }
      return updateAnchor(projectId, chapterId, anchor);
    },
    onMutate: async ({ projectId, chapterId, anchor }) => {
      await queryClient.cancelQueries({
        queryKey: ["projects", projectId, "chapters", chapterId],
      });
      const previousChapter = queryClient.getQueryData([
        "projects",
        projectId,
        "chapters",
        chapterId,
      ]);
      queryClient.setQueryData(
        ["projects", projectId, "chapters", chapterId],
        (oldData: any) => {
          const newAnchors = oldData.anchors.map((a: Anchor) => {
            if (a.id === anchor.id) {
              return { ...a, ...anchor };
            }
            return a;
          });
          return { ...oldData, anchors: newAnchors };
        },
      );
      return { previousChapter, projectId, chapterId };
    },
    onSettled: (_data, _error, { projectId, chapterId }) => {
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "chapters", chapterId],
      });
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData(
        ["projects", context?.projectId, "chapters", context?.chapterId],
        context?.previousChapter,
      );
    },
  });
};

export const useMutationDeleteAnchor = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      projectId,
      chapterId,
      anchorId,
    }: {
      projectId: string;
      chapterId: string;
      anchorId: string;
    }) => deleteAnchor(projectId, chapterId, anchorId),
    onSuccess: (_data, { projectId, chapterId }) => {
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "chapters", chapterId],
      });
    },
  });
};

// =================== Content ===================
// We don't have useQueryContent because we use useQueryChapter,
// which includes the content.
export const useMutationCreateContent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      projectId,
      chapterId,
      content,
      file,
    }: {
      projectId: string;
      chapterId: string;
      content: Content;
      file?: File;
    }) => {
      if (file) {
        const resource = await createResource(file);
        content.sourceObjectId = resource.id;
      }
      try {
        return await createContent(projectId, chapterId, content);
      } catch (err) {
        if (file && content.sourceObjectId) {
          await deleteResource(content.sourceObjectId);
        }
        throw err;
      }
    },
    onSuccess: (_data, { projectId, chapterId }) => {
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "chapters", chapterId],
      });
    },
  });
};

// useMutationUpdateContent updates content optimisticly.
export const useMutationUpdateContent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      projectId,
      chapterId,
      content,
      file,
    }: {
      projectId: string;
      chapterId: string;
      content: Content;
      file?: File;
    }) => {
      if (file) {
        const resource = await createResource(file);
        content.sourceObjectId = resource.id;
      }
      return await updateContent(projectId, chapterId, content);
    },
    onMutate: async ({ projectId, chapterId }) => {
      await queryClient.cancelQueries({
        queryKey: ["projects", projectId, "chapters", chapterId],
      });
      const oldChapter = queryClient.getQueryData([
        "projects",
        projectId,
        "chapters",
        chapterId,
      ]);
      return { previousChapter: oldChapter, projectId, chapterId };
    },
    // onError: (_err, _variables, context) => {
    //   queryClient.setQueryData(
    //     ["projects", context?.projectId, "chapters", context?.chapterId],
    //     context?.previousChapter,
    //   );
    // },
    // onSettled: (_data, _error, { projectId, chapterId }) => {
    //   queryClient.invalidateQueries({
    //     queryKey: ["projects", projectId, "chapters", chapterId],
    //   });
    // },
    onSuccess: (content, { projectId, chapterId }) => {
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "chapters", chapterId],
      });
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId],
      });
      // updateContentLocally(queryClient, projectId, chapterId, { ...content });
    },
  });
};

export const updateContentLocally = (
  queryClient: QueryClient,
  projectId: string,
  chapterId: string,
  content: Content,
) => {
  queryClient.setQueryData<Container>(
    ["projects", projectId, "chapters", chapterId],
    (oldChapter) => {
      const newContent = oldChapter?.content?.map((c: Content) => {
        if (c.id === content.id) {
          return { ...c, ...content };
        }
        return c;
      });
      return { ...oldChapter, content: newContent };
    },
  );
};

export const useMutationDeleteContent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      projectId,
      chapterId,
      contentId,
    }: {
      projectId: string;
      chapterId: string;
      contentId: string;
    }) => deleteContent(projectId, chapterId, contentId),
    onSuccess: (_data, { projectId, chapterId }) => {
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "chapters", chapterId],
      });
    },
  });
};

// ======================== QR Code ========================
export const useMutationCreateQRCode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (projectId: string) => {
      return await createQRCode(projectId);
    },
    onSuccess: (_data, projectId) => {
      //queryClient.invalidateQueries({ queryKey: ["resources"] });
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId],
      });
    },
  });
};

export const useMutationCreateQRCodeForChapter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      projectId,
      chapterId,
    }: {
      projectId: string;
      chapterId: string;
    }) => {
      return await createQRCodeForChapter(projectId, chapterId);
    },
    onSuccess: (_data, { projectId, chapterId }) => {
      //queryClient.invalidateQueries({ queryKey: ["resources"] });
      //queryClient.invalidateQueries({ queryKey: ["projects"] })
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "chapters", chapterId],
      });
    },
  });
};

// ======================== statistics ========================

export const useQueryStatistics = () => {
  return useQuery({
    queryKey: ["statistics"],
    queryFn: () => readStatistics(),
  });
};

export const useQueryAllStatistics = () => {
  return useQuery({
    queryKey: ["statistics/all-subscriptions"],
    queryFn: () => readAllStatistics(),
  });
};
//filtered by timeframe:
export const useQueryFilteredStatistics = (startDate: Date, endDate: Date) => {
  return useQuery({
    queryKey: ["statistics", startDate, endDate],
    queryFn: () => readFilteredStatistics(startDate, endDate),
  });
};

export const useQueryFilteredAllStatistics = (
  startDate: Date,
  endDate: Date,
) => {
  return useQuery({
    queryKey: ["statistics/all-subscriptions", startDate, endDate],
    queryFn: () => readFilteredAllStatistics(startDate, endDate),
  });
};
// ======================== Helpers ========================

export const useCurrentChapter = () => {
  const { projectId, chapterId } = useProjectChapterId();
  return useQueryChapter(projectId, chapterId);
};
// ======================== users ========================
export const useQueryUsers = (subscriptionId: string) => {
  return useQuery({
    queryKey: ["subscriptions", subscriptionId, "users"],
    queryFn: () => readUsers(subscriptionId),
  });
};

export const useQueryUser = (subscriptionId: string, userId: string) => {
  return useQuery({
    queryKey: ["subscriptions", subscriptionId, "users", userId],
    queryFn: () => readUser(subscriptionId, userId),
  });
};

export const useMutationUpdateUser = (subscriptionId, userId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ user }: { user: UserBean }) =>
      await updateUser(subscriptionId, userId, user),

    onMutate: async (newUser) => {
      const queryKey = ["subscriptions", subscriptionId, "users", userId];

      await queryClient.cancelQueries({ queryKey });

      const previousUser = queryClient.getQueryData(queryKey);
      console.log(previousUser);
      if (previousUser) {
        queryClient.setQueryData(queryKey, { ...newUser.user });
        console.log(newUser.user);
      } else {
        console.warn("No existing data in cache for", queryKey);
      }
    },
    onSuccess: (newUser) => {
      console.log("User updated successfully");
      queryClient.invalidateQueries({
        queryKey: ["subscriptions", subscriptionId, "users", newUser.id],
      });
    },
    onError: (error: Error) => {
      console.error("Error during mutation:", error);
    },
  });
};

export const useMutationUpdateUsers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      subscriptionId,
      users,
    }: {
      subscriptionId: string;
      users: UserBean[];
    }) => {
      const results: UserBean[] = [];
      for (const user of users) {
        results.push(await updateUser(subscriptionId, user.id!, user));
      }
      return results;
    },
    onSuccess: (_data, _subscription) => {
      console.log("User updated successfully");
      queryClient.invalidateQueries({
        queryKey: ["subscriptions"],
      });
      queryClient.invalidateQueries({
        queryKey: ["subscriptions", _subscription.subscriptionId],
      });
    },
    onError: (error: Error) => {
      console.error("Error during mutation:", error);
    },
  });
};

export const useMutationDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      subscriptionId,
      userId,
    }: {
      subscriptionId: string;
      userId: string;
    }) => deleteUser(subscriptionId, userId),
    onSuccess: (_data, { subscriptionId, userId }) => {
      console.log("User deleted successfully");
      queryClient.invalidateQueries({
        queryKey: ["subscriptions", subscriptionId],
      });
      queryClient.invalidateQueries({
        queryKey: ["subscriptions", subscriptionId, "users", userId],
      });
    },
    onError: (error: Error) => {
      console.error("Error during mutation:", error);
    },
  });
};

// ======================== usersGroups ========================
export const useQueryUsersGroup = (
  subscriptionId: string,
  userGroupId: string,
) => {
  return useQuery({
    queryKey: ["subscriptions", subscriptionId, "userGroups", userGroupId],
    queryFn: () => readUsersGroup(subscriptionId, userGroupId),
  });
};
export const useQueryUsersGroups = (subscriptionId: string) => {
  return useQuery({
    queryKey: ["subscriptions", subscriptionId, "userGroups"],
    queryFn: () => readUsersGroups(subscriptionId),
  });
};
export const useMutationCreateUserGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      subscriptionId,
      userGroup,
    }: {
      subscriptionId: string;
      userGroup: UserGroup;
    }) => createUserGroup(subscriptionId, userGroup),
    onSuccess: (_data, { subscriptionId }) => {
      queryClient.invalidateQueries({ queryKey: ["subscriptions"] });
      queryClient.invalidateQueries({
        queryKey: ["subscriptions", subscriptionId],
      }); //should i add "userGroups" to the path ??
    },
  });
};

export const useMutationUpdateUserGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      subscriptionId,
      userGroupId,
      userGroup,
    }: {
      subscriptionId: string;
      userGroupId: string;
      userGroup: UserGroup;
    }) => {
      return updateUsersGroup(subscriptionId, userGroupId, userGroup);
    },
    onMutate: async ({ subscriptionId, userGroupId, userGroup }) => {
      await queryClient.cancelQueries({
        queryKey: ["subscriptions", subscriptionId, "userGroups", userGroupId],
      });

      const previousData = queryClient.getQueryData<UserGroup>([
        "subscriptions",
        subscriptionId,
        "userGroups",
        userGroupId,
      ]);

      queryClient.setQueryData<UserGroup[]>(
        ["subscriptions", subscriptionId, "userGroups"],
        (oldData) => {
          if (!oldData) {
            return [userGroup];
          }
          return oldData.map((user) =>
            user.id === userGroup.id ? userGroup : user,
          );
        },
      );

      return { previousData };
    },
    onError: (error, variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          ["projects", variables.subscriptionId, "userGroups"],
          context.previousData,
        );
      }
      console.error("Mutation failed:", error);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["subscriptions", variables.subscriptionId],
      });
      console.log(data);
      console.log(variables.userGroup.name, variables.userGroup.users);
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["subscriptions", variables.subscriptionId, "userGroups"],
      });
      console.log(data);
    },
  });
};

export const useMutationDeleteUserGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      subscriptionId,
      userGroupId,
    }: {
      subscriptionId: string;
      userGroupId: string;
    }) => deleteUsersGroup(subscriptionId, userGroupId),
    onSuccess: (_data, { subscriptionId, userGroupId }) => {
      queryClient.invalidateQueries({
        queryKey: ["subscriptions", subscriptionId],
      });
      queryClient.invalidateQueries({
        queryKey: ["subscriptions", subscriptionId, "userGroups", userGroupId],
      });
    },
  });
};
