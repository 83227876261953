import { FC } from "react";
import { Content } from "api/model";
import { Html } from "@react-three/drei";

interface Props {
  content: Content;
}

export const ContentVideoURL: FC<Props> = ({ content }) => {
  const embedUrl = content?.configurations?.videoURL.embedUrl;
  return (
    <Html
      transform
      occlude={"blending"}
      distanceFactor={1.33}
      zIndexRange={[10, 10]}
    >
      <iframe src={embedUrl}></iframe>
    </Html>
  );
};
