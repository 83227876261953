import React, { useEffect, useRef } from "react";
import { Heading, Text } from "@chakra-ui/react";
import { useTranslation } from '../../layout/TranslationContext';
import { translatePage } from '../../layout/translatePage';
export const Register = () => {
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    const sessionKey = `translated_${language}`;
    if (!sessionStorage.getItem(sessionKey)) {
      translatePage(document.body, language);
      sessionStorage.setItem(sessionKey, 'true'); 
      hasTranslated.current = true; 
    } else {
      translatePage(document.body, language);
    }
  }, [language]);
  return (
    <div>
      <Heading px="16px">Register</Heading>
      <Text px="16px">
        Coming soon. Until then please reach out to kranz@interlake.net
      </Text>
    </div>
  );
};
