import { Button, Alert } from "@chakra-ui/react";
import { FC, useMemo, useState } from "react";
import { ContentType, ProjectBean } from "api/model";

export const OfflineContentWarning: FC<{ project: ProjectBean }> = ({
  project,
}) => {
  const [hide, setHide] = useState(false);
  const handleButtonClick = () => {
    setHide(true);
  };

  const hasOnlineContent = useMemo(() => {
    const onlineContentTypes = [
      ContentType.Youtube,
      ContentType.Vimeo,
      ContentType.UrlEndpoint,
    ];

    // Check if the project has any online content
    // like Youtube Video or Website URL
    return (
      project?.containers?.some((container) =>
        container.content?.some((content) =>
          onlineContentTypes.includes(content.type!),
        ),
      ) ?? false
    );
  }, [project]);

  return (
    <>
      {!hide && hasOnlineContent && (
        <Alert status="warning" mt={4}>
          ATTENTION: You have online content in your project like Youtube Video
          or Website URL which cannot be stored for offline use. Please remove
          this content first and try again.
          <Button px="10" onClick={handleButtonClick}>
            OK
          </Button>
        </Alert>
      )}
    </>
  );
};
