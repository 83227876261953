import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface TranslationContextType {
  language: string;
  setLanguage: (language: string) => void;
}

const TranslationContext = createContext<TranslationContextType | undefined>(undefined);

export const TranslationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {//imported in index.tsx
  const browserLanguage = navigator.language.split('-')[0];
  //console.log('Detected browser language:', browserLanguage);

  const [language, setLanguage] = useState(browserLanguage);

  useEffect(() => {
    //console.log('Initial language set to:', language);
  }, [language]);

  return (
    <TranslationContext.Provider value={{ language, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
};


