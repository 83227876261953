import * as React from "react";
import { Button } from "@chakra-ui/react";

interface CategoriesProps {
  categories: string[];
  onCategorySelected: Function;
  activeCategory: string;
}

export const Categories: React.FC<CategoriesProps> = ({
  categories,
  onCategorySelected,
  activeCategory,
}) => {
  return (
    <div>
      <Button
        minWidth="auto"
        className="item"
        size="sm"
        borderRadius="50"
        variant="ghost"
        isActive={activeCategory === "all"}
        padding="4px 16px"
        marginRight="10px"
        data-targetid="1"
        onClick={() => onCategorySelected("all")}
      >
        All
      </Button>
      <Button
        minWidth="auto"
        className="item"
        size="sm"
        borderRadius="50"
        variant="ghost"
        isActive={activeCategory === "Published"}
        padding="4px 16px"
        marginRight="10px"
        data-targetid="2"
        onClick={() => onCategorySelected("Published")}
      >
        Published
      </Button>
      <Button
        minWidth="auto"
        className="item"
        size="sm"
        borderRadius="50"
        variant="ghost"
        isActive={activeCategory === "Draft"}
        padding="4px 16px"
        marginRight="10px"
        data-targetid="3"
        onClick={() => onCategorySelected("Draft")}
      >
        Draft
      </Button>
      {categories.map((category, index) => (
        <Button
          key={index}
          minWidth="auto"
          className="item doNotTranslate"
          size="sm"
          borderRadius="50"
          variant="ghost"
          isActive={activeCategory === category}
          padding="4px 16px"
          marginRight="10px"
          data-targetid={4 + index}
          onClick={() => onCategorySelected(category)}
        >
          {category}
        </Button>
      ))}
    </div>
  );
};
