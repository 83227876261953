import React from "react";
import { IconButton } from "@chakra-ui/react";
import { IoHelpSharp } from "react-icons/io5";

interface HelpIconButtonProps {
  link: string;
  isFloating?: boolean;
}

export const HelpIconButton: React.FC<HelpIconButtonProps> = ({
  link,
  isFloating = false,
}) => {
  const openLinkInNewWindow = () => {
    const width = 700;
    const height = 620;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const windowFeatures = `width=${width},height=${height},left=${left},top=${top}`;

    window.open(link, "HelpWindow", windowFeatures);
  };

  return (
    <IconButton
      aria-label="Help"
      icon={<IoHelpSharp />}
      onClick={openLinkInNewWindow}
      position={isFloating ? "fixed" : "relative"}
      bottom={isFloating ? "20px" : "12px"}
      right={isFloating ? "20px" : "16px"}
      bgColor={isFloating ? "#4D888C" : "transparent"}
      color={isFloating ? "white" : "primary.6"}
      borderRadius="50%"
      boxShadow={
        isFloating
          ? "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.10)"
          : undefined
      }
      size={isFloating ? "lg" : "md"}
      _hover={{ bg: isFloating ? "#367A7C" : "primary.0" }}
      zIndex={isFloating ? 9999 : undefined}
    />
  );
};
