import { Content, ContentType } from "api/model";
import { FC } from "react";
import { SubtitlesPreview } from "../ContentEditorWizard/Preview";
import { ContentList3DDetails } from "./ContentList3DDetails";
import { ContentListVideoDetails } from "./ContentListVideoDetails";

interface ContentTabContentDetailsProps {
  content: Content;
}

export const ContentListContentDetails: FC<ContentTabContentDetailsProps> = ({
  content,
}) => {
  switch (content.type) {
    case ContentType.Audio:
    case ContentType.Text2Speech:
      return <audio src={content.sources!.main} controls></audio>;
    case ContentType.Video:
      return <ContentListVideoDetails content={content} />;
    case ContentType.Value3DModel:
      return <ContentList3DDetails content={content} />;
    case ContentType.Subtitles:
      return <SubtitlesPreview src={content.sources!.main} />;
    default:
      return null;
  }
};
