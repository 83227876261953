export function truncate(n, len) {
  var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
  var filename = n.replace("." + ext, "");
  if (filename.length <= len) {
    return n;
  }
  filename = filename.substr(0, len) + (n.length > len ? "[...]" : "");
  return filename + "." + ext;
}

export const extensionWithDot = (filename: string): string => {
  const index = filename.lastIndexOf(".");

  // Check if there is no dot or the only dot is at the beginning
  if (
    index === -1 ||
    (index === 0 && filename.indexOf(".", index + 1) === -1)
  ) {
    return "";
  }

  return filename.slice(index);
};

// dToR converts degrees to radians
export const dToR = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

export const rToD = (radians: number) => {
  return (radians * 180) / Math.PI;
};
