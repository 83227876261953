import * as React from "react";
import { useRouteError } from "react-router-dom";
import { useTranslation } from './../layout/TranslationContext';
import { translatePage } from './../layout/translatePage';
export const Error = () => {
  const error: any = useRouteError();
  console.error(error);
  const { language, setLanguage } = useTranslation();
  const hasTranslated = React.useRef(false);

  React.useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  React.useEffect(() => {
    const sessionKey = `translated_${language}`;
    if (!sessionStorage.getItem(sessionKey)) {
      translatePage(document.body, language);
      sessionStorage.setItem(sessionKey, 'true'); 
      hasTranslated.current = true; 
    } else {
      translatePage(document.body, language);
    }
  }, [language]);  
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};
