import React, { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Box, Button, Flex, Grid, Heading, Icon, Text } from "@chakra-ui/react";
import { IoAddSharp } from "react-icons/io5";
import { ChapterCard } from "./components/ChapterCard";
import { useNavigate } from "react-router-dom";
import {
  useMutationCreateChapter,
  useMutationUpdateChapters,
  useQueryProject,
} from "state/remoteState";
import { useProjectId } from "state/localState";
import { AnchorType, Container, DisplayMode, Orientation } from "api/model";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
//import { useTranslation } from '../../layout/TranslationContext';
//import { translatePage } from '../../layout/translatePage';
const chunkArrayWithNumberKeys = <T,>(
  array: T[],
  chunkSize: number,
): Record<number, T[]> => {
  const result: Record<number, T[]> = {};
  let groupNumber = 1;

  for (let i = 0; i < array.length; i += chunkSize) {
    result[groupNumber] = array.slice(i, i + chunkSize);
    groupNumber += 1;
  }

  return result;
};
export const Chapters = () => {
  const navigate = useNavigate();
  const projectId = useProjectId();
  const { data: project } = useQueryProject(projectId);
  const mutationCreateChapter = useMutationCreateChapter();
  const mutationUpdateChapters = useMutationUpdateChapters();
  const [chaptersCount, setChaptersCount] = useState(1);
  const chaptersRef = useRef<HTMLDivElement>(null);
  const [chapters, setChapters] = useState(
    chunkArrayWithNumberKeys(
      project?.containers?.sort(
        (a, b) => (a.sortOrder ?? 1) - (b.sortOrder ?? 0),
      ) ?? [],
      chaptersCount,
    ),
  );
  const [groundZeroChapters, setGroundZeroChapters] = useState(
    project?.containers,
  );
  //const { language, setLanguage } = useTranslation();
  //const hasTranslated = useRef(false);

  /*useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);*/

  /*useEffect(() => {
    const sessionKey = `translated_${language}`;
    if (!sessionStorage.getItem(sessionKey)) {
      translatePage(document.getElementById('chapter-list')!, language);
      sessionStorage.setItem(sessionKey, 'true'); 
      hasTranslated.current = true; 
    } else {
      translatePage(document.body, language);
    }
  }, [language, chapters]);*/
 
  function handleResize() {
    //setChaptersCount(1);
    let width = chaptersRef.current!.clientWidth;
    if (window.innerWidth - 500 < width) {
      width = window.innerWidth - 500;
    }
    const amount = width / 200;
    setChaptersCount(parseInt(amount.toString()));
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (chaptersRef != null) {
      handleResize();
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (project) {
      setGroundZeroChapters(project.containers!);
      setChapters(
        chunkArrayWithNumberKeys(project!.containers!, chaptersCount),
      );
    }
  }, [project, chaptersCount]);

  const mutationUpdateChapterDebounced = useDebouncedCallback(
    (updatedChapters: Container[]) => {
      mutationUpdateChapters.mutate({ projectId, chapters: updatedChapters });
    },
    50,
  );

  const moveArrayItemToNewPos = (arr, oldPos, newPos) => {
    if (newPos >= arr.length) {
      let k = newPos - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newPos, 0, arr.splice(oldPos, 1)[0]);
    return arr;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const sourcePos =
      result.source.index +
      chaptersCount * (parseInt(result.source.droppableId) - 1);
    let destinationPos =
      result.destination.index +
      chaptersCount * (parseInt(result.destination.droppableId) - 1);
    if (destinationPos >= groundZeroChapters!.length) {
      destinationPos = groundZeroChapters!.length - 1;
    }
    const newChapters = moveArrayItemToNewPos(
      groundZeroChapters,
      sourcePos,
      destinationPos,
    );
    newChapters.forEach((chapter, index) => (chapter.sortOrder = index));
    setGroundZeroChapters(newChapters);
    setChapters(chunkArrayWithNumberKeys(newChapters, chaptersCount));
    mutationUpdateChapterDebounced(newChapters);
  };
  const handleCreateChapter = () => {
    let displayMode = DisplayMode.AR;
    if (project?.anchorType === AnchorType.QrCode) {
      displayMode = DisplayMode.Web;
    }
    mutationCreateChapter.mutate(
      {
        projectId,
        chapter: {
          name: "",
          description: "",
          displayMode: displayMode,
          orientation: Orientation.Flat,
        },
      },
      {
        onSuccess: (chapter) => {
          navigate(`/projects/${projectId}/chapters/${chapter.id}`);
        },
      },
    );
  };
  return (
    <Flex direction="column" gap="4" id="chapter-list">
      <Heading size="lg">Chapters</Heading>
      <Grid gap="4" templateColumns="190px auto">
        <Button
          variant="outline"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="190px"
          height="220px"
          whiteSpace="normal"
          gap="8"
          borderRadius="8"
          onClick={handleCreateChapter}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="primary.1"
            width="30px"
            height="30px"
            borderRadius="50"
          >
            <Icon as={IoAddSharp} color="primary.6" boxSize="5" />
          </Box>

          <Box display="flex" flexDirection="column" gap="1">
            <Text fontSize="sm" color="primary.6" fontWeight="700">
              Add new chapter
            </Text>
            <Text fontSize="xs" color="primary.4" fontWeight="500">
              You can fill your project with content here
            </Text>
          </Box>
        </Button>

        {project && chapters ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <div ref={chaptersRef}>
              {Object.entries(chapters).map(([k, v]) => (
                <Droppable
                  droppableId={k}
                  type="CARD"
                  direction="horizontal"
                  isCombineEnabled={false}
                  key={k}
                >
                  {(dropProvided) => (
                    <div {...dropProvided.droppableProps}>
                      <div>
                        <div>
                          <Grid
                            style={{ display: "flex", marginBottom: "10px" }}
                            gap={4}
                            templateColumns="repeat(5,1fr)"
                            ref={dropProvided.innerRef}
                          >
                            {v.map((chapter, index) => (
                              <Draggable
                                key={chapter.id}
                                draggableId={chapter.id!}
                                index={index}
                              >
                                {(dragProvided) => (
                                  <div
                                    {...dragProvided.dragHandleProps}
                                    {...dragProvided.draggableProps}
                                    ref={dragProvided.innerRef}
                                  >
                                    <ChapterCard chapter={chapter} 
                                    data-nontranslatable={`${chapter.name} ${chapter.displayMode}`}/>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {dropProvided.placeholder}
                          </Grid>
                        </div>
                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </DragDropContext>
        ) : null}
      </Grid>
    </Flex>
  );
};
