import { useNavigate } from "react-router-dom";
import { HStack, IconButton, Text } from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";
import { useQueryChapter } from "state/remoteState";
import { Grid } from "@chakra-ui/react";
import React, { useEffect, useRef} from "react";
import { CanvasARViewer } from "./components/Canvas/CanvasARViewer";
import { ControlPanel } from "./components/ControlPanel/ControlPanel";
import { useProjectChapterId } from "state/localState";
import { DisplayMode } from "api/model";
import { CanvasOnscreenViewer } from "./components/Canvas/CanvasOnScreenViewer";
import { HelpIconButton } from "pages/HelpIconButton";
import { useTranslation } from '../../layout/TranslationContext';
import { translatePage } from '../../layout/translatePage';
export const Chapter = () => {
  //console.log("Rerendering Chapter");
  const { projectId, chapterId } = useProjectChapterId();
  const { data: chapter } = useQueryChapter(projectId, chapterId);
  const navigate = useNavigate();
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    const sessionKey = `translated_${language}`;
    if (!sessionStorage.getItem(sessionKey)) {
      translatePage(document.body, language);
      sessionStorage.setItem(sessionKey, 'true'); 
      hasTranslated.current = true; 
    } else {
      translatePage(document.body, language);
    }
  }, [language, chapter]);
  return (
    <>
      <HStack py="4" pl="12">
        <IconButton
          aria-label="Go Back"
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
          variant="outline"
        />
        <Text color="black" fontWeight="700" pl="2">
          Chapter
        </Text>
        <Text data-nontranslatable={chapter?.name}>{chapter?.name}</Text>
      </HStack>
      <Grid
        // templateColumns doesn't work for some reason
        style={{
          gridTemplateColumns: "minmax(400px, 3fr) minmax(400px,1fr)",
          gridTemplateRows: "minmax(200px, calc(100vh - 168px))",
        }}
        columnGap="5"
        px="12"
        data-nontranslatable>
        {chapter?.displayMode === DisplayMode.AR && <CanvasARViewer />}
        {chapter?.displayMode === DisplayMode.Web && <CanvasOnscreenViewer />}
        <ControlPanel />
      </Grid>
      <HelpIconButton
        link="https://www.nextcreate.com/help/chapter-editor"
        isFloating={true}
      />
    </>
  );
};
