import { useDisclosure } from "@chakra-ui/react";
import { Content, ContentType, DisplayMode } from "api/model";
import { FC, useEffect, useState, useRef } from "react";
import { DialogSelectContentType } from "./DialogSelectContentType";
import { ConfirmOverwriteAlert } from "./ConfirmOverwriteAlert";
import { useCurrentChapter, useMutationDeleteContent } from "state/remoteState";
import { contentTypesVisibleInARMode as contentTypesVisibleInARMode } from "../contentTypeDetails";
import { DialogCreateOrUpdateContent } from "./DialogCreateOrUpdateContent";
import { useProjectChapterId } from "state/localState";
import { useTranslation } from '../../../../../../layout/TranslationContext';
import { translatePage } from '../../../../../../layout/translatePage';
enum Step {
  SelectContentType,
  ConfirmOverwrite,
  CreateContent,
  UpdateContent,
}
// Wizard Controller
export const useContentEditorWizard = () => {
  const [step, setStep] = useState<Step>();
  const [contentType, setContentType] = useState<ContentType>();
  const [file, setFile] = useState<File>();
  const [content, setContent] = useState<Content>();
  const { data: chapter } = useCurrentChapter();
  const { projectId, chapterId } = useProjectChapterId();
  const mutationDeleteContent = useMutationDeleteContent();

  const reset = () => {
    setStep(undefined);
    setContentType(undefined);
    setFile(undefined);
    setContent(undefined);
  };

  const create = (contentType?: ContentType, file?: File) => {
    reset();
    setContentType(contentType);
    setFile(file);
    if (!contentType) {
      setStep(Step.SelectContentType);
    } else {
      // This happens when the user starts the wizard with a preselected content type
      onSelectContentType(contentType);
    }
  };

  const update = (content: Content) => {
    reset();
    setContent(content);
    setStep(Step.UpdateContent);
  };

  const onSelectContentType = (contentType: ContentType) => {
    setContentType(contentType);
    const conflictingContent = findConflictingContent(contentType);
    if (!conflictingContent) {
      setStep(Step.CreateContent);
    } else {
      setContent(conflictingContent);
      setStep(Step.ConfirmOverwrite);
    }
  };

  const onConfirmOverwrite = (response: boolean) => {
    if (response) {
      mutationDeleteContent.mutate({
        projectId,
        chapterId,
        contentId: content!.id!,
      });
      setContent(undefined);
      setStep(Step.CreateContent);
    } else {
      onInterrupt();
    }
  };

  const onInterrupt = () => {
    reset();
  };

  const onSuccess = () => {
    reset();
  };

  const findConflictingContent = (contentType: ContentType) => {
    if (!chapter) {
      throw new Error("Chapter is not loaded");
    }
    // If the chapter is not in AR mode or the content type is not visible,
    // then no conflict can exist.
    if (
      chapter.displayMode !== DisplayMode.AR ||
      !contentTypesVisibleInARMode.includes(contentType)
    ) {
      return undefined;
    }
    // If the chapter is in AR mode and the content type is visible,
    // we can only create the content if there is no visible content.
    return chapter.content?.find((c: Content) =>
      contentTypesVisibleInARMode.includes(c.type!),
    );
  };

  const contentEditorWizardProps: ContentEditorWizardProps = {
    step,
    onSelectContentType,
    onConfirmOverwrite,
    contentType,
    content,
    file,
    onInterrupt,
    onSuccess,
  };
  return { contentEditorWizardProps, create, update };
};

// Wizard Component
interface ContentEditorWizardProps {
  step?: Step;
  contentType?: ContentType;
  onSelectContentType: (t: ContentType) => void;
  onConfirmOverwrite: (response: boolean) => void;
  content?: Content;
  file?: File;
  onInterrupt: () => void;
  onSuccess: () => void;
}

export const ContentEditorWizard: FC<ContentEditorWizardProps> = ({
  step,
  contentType,
  onSelectContentType,
  onConfirmOverwrite,
  content,
  file,
  onInterrupt,
  onSuccess,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false); 
  const { language, setLanguage } = useTranslation();
  const {
    onOpen: openDialogSelectContentType,
    onClose: closeDialogSelectContentType,
    isOpen: isOpenDialogSelectContentType,
  } = useDisclosure();
  const {
    onOpen: openConfirmOverwriteAlert,
    onClose: closeConfirmOverwriteAlert,
    isOpen: isOpenConfirmOverwriteAlert,
  } = useDisclosure();
  const {
    onOpen: openContentEditor,
    onClose: closeContentEditor,
    isOpen: isOpenContentEditor,
  } = useDisclosure();
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    if (isOpenDialogSelectContentType || isOpenConfirmOverwriteAlert || isOpenContentEditor) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false); 
    }
  }, [isOpenDialogSelectContentType, isOpenConfirmOverwriteAlert, isOpenContentEditor]);

useEffect(() => {

  if (modalIsOpen && modalRef.current) {
    translatePage(modalRef.current!, language);
  }
}, [modalIsOpen]);
  useEffect(() => {
    switch (step) {
      case Step.SelectContentType:
        closeAllDialogs();
        openDialogSelectContentType();
        break;
      case Step.ConfirmOverwrite:
        closeAllDialogs();
        openConfirmOverwriteAlert();
        break;
      case Step.CreateContent:
      case Step.UpdateContent:
        closeAllDialogs();
        openContentEditor();
        break;
      case undefined:
        closeAllDialogs();
        break;
      default:
        throw new Error("Unknown step");
    }
  }, [step]);

  const closeAllDialogs = () => {
    closeDialogSelectContentType();
    closeConfirmOverwriteAlert();
    closeContentEditor();
  };
 
  // Wrapper to call onInterrupt after the onClose
  const w = (f: () => void) => {
    return () => {
      f();
      onInterrupt();
    };
  };

  return (
    <>
      {isOpenDialogSelectContentType && (
        <DialogSelectContentType
          initialFocusRef={modalRef} 
          isOpen={isOpenDialogSelectContentType}
          onClose={w(closeDialogSelectContentType)}
          onSelected={onSelectContentType}
        />
      )}
      {isOpenConfirmOverwriteAlert && (
        <ConfirmOverwriteAlert
         initialFocusRef={modalRef} 
          isOpen={isOpenConfirmOverwriteAlert}
          onClose={w(closeConfirmOverwriteAlert)}
          confirmOverwrite={onConfirmOverwrite}
          content={content!}
        />
      )}
      {isOpenContentEditor && (
        <DialogCreateOrUpdateContent
        initialFocusRef={modalRef} 
          isOpen={isOpenContentEditor}
          onClose={w(closeContentEditor)}
          contentType={contentType}
          content={content}
          file={file}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};
