import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { IoAddSharp } from "react-icons/io5";
import { FiCrop, FiMoreVertical } from "react-icons/fi";
import { BsImage } from "react-icons/bs";
import { MdOutlineEdit } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { FC, useRef, useState, useEffect } from "react";
import {
  useMutationCreateAnchor,
  useMutationDeleteAnchor,
  useMutationUpdateAnchor,
  useQueryChapter,
  useQueryProject,
} from "state/remoteState";
import { useProjectChapterId, useStateActiveAnchor } from "state/localState";
import {
  Configurations,
  initialMarkerConfigurations,
} from "api/Configurations";
import piexif from "piexifjs";
import { AnchorType } from "api/model";
import { useTranslation } from "../../../../../layout/TranslationContext";
import { translatePage } from "../../../../../layout/translatePage";

export const PanelMarker = () => {
  const { projectId, chapterId } = useProjectChapterId();
  const chapter = useQueryChapter(projectId, chapterId).data;
  const project = useQueryProject(projectId).data;
  const [activeAnchor, setActiveAnchorId] = useStateActiveAnchor();
  const mutationCreateAnchor = useMutationCreateAnchor();
  const mutationUpdateAnchor = useMutationUpdateAnchor();
  const mutationDeleteAnchor = useMutationDeleteAnchor();
  const {
    isOpen: isOpenUploadImageMarkerModal,
    onOpen: onOpenUploadImageMarkerModal,
    onClose: onCloseUploadImageMarkerModal,
  } = useDisclosure();
  const [selectedAcnorImageURL, setSelectedAcnorImageURL] = useState<any>(null);
  const [file, setFile] = useState<File | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  const [editingMarker, setEditingMarker] = useState(false);
  const [selectedTargetName, setSelectedTargetName] = useState("");
  const [selectedTargetDescription, setSelectedTargetDescription] =
    useState("");
  const [configurationsFromPreview, setConfigurationsFromPreview] =
    useState<Configurations>({});
  const handleSelectedTargetNameChange = (e) => {
    setSelectedTargetName(e.target.value);
  };

  const handleSelectedTargetDescriptionChange = (e) => {
    setSelectedTargetDescription(e.target.value);
  };

  const handleFileUpload = () => {
    hiddenFileInput.current?.click();
  };
  const { language, setLanguage } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    if (isOpenUploadImageMarkerModal) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }, [isOpenUploadImageMarkerModal]);

  useEffect(() => {
    if (modalIsOpen && modalRef.current) {
      translatePage(modalRef.current!, language);
    }
  }, [modalIsOpen]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      setSelectedAcnorImageURL(URL.createObjectURL(file));
      setFile(file);
    }

    setDragActive(false);
  };

  const getBase64 = (file) =>
    new Promise(function (resolve, reject) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSelecteTargetImageChange = async () => {
    if (
      hiddenFileInput.current === null ||
      hiddenFileInput.current.files === null
    ) {
      return;
    }

    const file = hiddenFileInput.current.files[0];
    const dataURL = await getBase64(file);
    const removedDataUrl = piexif.remove(dataURL);
    setSelectedAcnorImageURL(removedDataUrl);
    setFile(file);
  };

  const handleAnchorChange = async () => {
    onCloseUploadImageMarkerModal();
    if (!editingMarker && file) {
      let anchorType = AnchorType.Image;
      if (project?.anchorType === AnchorType.QrCode) {
        anchorType = AnchorType.QrCode;
      }
      mutationCreateAnchor.mutate({
        projectId: projectId!,
        chapterId: chapter!.id!,
        anchor: {
          name: selectedTargetName,
          description: selectedTargetDescription,
          configurations: configurationsFromPreview,
          type: anchorType,
        },
        file: file,
      });
    } else {
      const anchorUpdate = {
        ...activeAnchor,
        name: selectedTargetName,
        description: selectedTargetDescription,
      };
      anchorUpdate.configurations = {
        ...activeAnchor?.configurations,
        ...configurationsFromPreview,
      };
      mutationUpdateAnchor.mutate({
        projectId: projectId!,
        chapterId: chapter!.id!,
        anchor: anchorUpdate,
        file: file ?? undefined,
      });
    }
  };

  const removeSelectedTargetAction = async (targetIdToRemove: string) => {
    mutationDeleteAnchor.mutate({
      projectId: projectId!,
      chapterId: chapter!.id!,
      anchorId: targetIdToRemove,
    });
  };

  const hansleSetConfigurations = (configurations) => {
    setConfigurationsFromPreview(configurations);
  };

  return (
    <>
      <button
        onClick={() => {
          setEditingMarker(false);
          setSelectedAcnorImageURL(null);
          setSelectedTargetName("");
          setSelectedTargetDescription("");
          onOpenUploadImageMarkerModal();
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #E2E8F0",
          borderRadius: "4px",
          padding: "3px",
          backgroundColor: "white",
          position: "relative",
          width: "100%",
        }}
      >
        <Flex align="center" gap="4" p="4">
          <Box
            backgroundColor="primary.0"
            width="90px"
            height="70px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
          >
            <Icon as={IoAddSharp} />
          </Box>
          <Flex direction="column">
            <Text textAlign="left" fontWeight="700" fontSize="md" color="black">
              {project?.anchorType === AnchorType.Image && (
                <>Add image marker</>
              )}
              {project?.anchorType === AnchorType.QrCode && <>Add QR Code</>}
            </Text>
            <Text
              textAlign="left"
              fontWeight="500"
              fontSize="sm"
              color="primary.4"
            >
              You can add one or more image markers which lead to the same
              content
            </Text>
          </Flex>
        </Flex>
      </button>
      <Modal
        isOpen={isOpenUploadImageMarkerModal}
        onClose={onCloseUploadImageMarkerModal}
        size="3xl"
        initialFocusRef={modalRef}
      >
        <ModalOverlay />
        <ModalContent p="8" ref={modalRef}>
          <ModalHeader>
            {project?.anchorType === AnchorType.Image && (
              <>Set an Image Marker</>
            )}
            {project?.anchorType === AnchorType.QrCode && <>Set a QR Code</>}
            <Text fontSize="md" fontWeight="500">
              Upload your image and fill in the information
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="1fr 1fr" gap="4">
              <GridItem>
                {selectedAcnorImageURL ? (
                  <Box
                    position="relative"
                    onClick={handleFileUpload}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    cursor={"pointer"}
                  >
                    <Box
                      borderRadius="6"
                      width="100%"
                      height="100%"
                      objectFit="cover"
                    >
                      <ImageMarkerPreview
                        src={selectedAcnorImageURL}
                        setConfigurations={hansleSetConfigurations}
                      />
                    </Box>
                    <IconButton
                      aria-label="delete image"
                      icon={<FiCrop />}
                      position="absolute"
                      top="10px"
                      right="10px"
                      backgroundColor="primary.0"
                      color="black"
                      borderRadius="50%"
                      _hover={{ backgroundColor: "primary.0" }}
                      onClick={() => console.log("crop image")}
                    />
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      ref={hiddenFileInput}
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handleSelecteTargetImageChange}
                    />
                  </Box>
                ) : (
                  <>
                    <Button
                      position="relative"
                      width="100%"
                      height="100%"
                      backgroundColor="primary.0"
                      borderRadius="6"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      _hover={{ backgroundColor: "primary.0" }}
                      onClick={handleFileUpload}
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    >
                      <Flex direction="column" align="center">
                        {!dragActive ? (
                          <>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              backgroundColor="primary.1"
                              w="50px"
                              h="50px"
                              borderRadius="50"
                            >
                              <Icon
                                as={BsImage}
                                color="primary.6"
                                boxSize="6"
                              />
                            </Box>
                            <Text color="primary.6" mt="2">
                              Upload Image
                            </Text>
                            <Text color="primary.4" mt="1">
                              Supports: JPG, JPEG, PNG
                            </Text>
                          </>
                        ) : (
                          <Text color="primary.6">Drop here</Text>
                        )}
                      </Flex>
                    </Button>
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      ref={hiddenFileInput}
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handleSelecteTargetImageChange}
                    />
                  </>
                )}
              </GridItem>
              <Flex direction="column" gap="4">
                <Alert
                  status="info"
                  color="secondary.2"
                  backgroundColor="rgba(77, 136, 140, 0.1)"
                  border="1px solid #4D888C"
                  borderRadius="8"
                >
                  <AlertIcon color="secondary.2" />
                  <Text fontWeight="500" fontSize="xs">
                    You can upload one or more image markers, which will lead to
                    the same content.
                  </Text>
                </Alert>
                <Flex align="center">
                  <Text
                    fontSize="sm"
                    fontWeight="600"
                    width="40%"
                    color="primary.4"
                  >
                    Marker Name
                  </Text>
                  <Input
                    placeholder="Image Marker 01"
                    borderRadius="32"
                    size="sm"
                    onChange={handleSelectedTargetNameChange}
                    value={selectedTargetName}
                  />
                </Flex>
                <Flex direction="column" gap="2">
                  <Text fontSize="sm" fontWeight="600" color="primary.4">
                    Description
                  </Text>
                  <Textarea
                    placeholder="Is there something special about the marker?"
                    onChange={handleSelectedTargetDescriptionChange}
                    value={selectedTargetDescription}
                  />
                </Flex>
              </Flex>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary"
              onClick={handleAnchorChange}
              isDisabled={!selectedAcnorImageURL}
            >
              Next
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex direction="column" mt="2" gap="2">
        {chapter?.anchors &&
          chapter?.anchors.map((anchor, i) => {
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  border: `1px solid ${
                    activeAnchor?.id === anchor.id ? "#4D888C" : "#E2E8F0"
                  }`,
                  borderRadius: "4px",
                  padding: "3px",
                  backgroundColor: "white",
                  position: "relative",
                  width: "100%",
                }}
              >
                <button
                  key={i}
                  onClick={() => setActiveAnchorId(anchor.id!)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "3px",
                    backgroundColor: "white",
                    position: "relative",
                    width: "80%",
                  }}
                >
                  <Flex align="center" gap="4" p="4">
                    <Box
                      backgroundColor="primary.0"
                      width="90px"
                      height="70px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="8px"
                    >
                      <Image
                        src={anchor.sources?.main}
                        borderRadius="6"
                        width="100%"
                        height="100%"
                        fit="cover"
                      />
                    </Box>
                    <Flex direction="column">
                      <Text
                        textAlign="left"
                        fontWeight="700"
                        fontSize="md"
                        color="black"
                      >
                        {anchor.name}
                      </Text>
                      <Text
                        textAlign="left"
                        fontWeight="500"
                        fontSize="sm"
                        color="primary.4"
                      >
                        {anchor.description}
                      </Text>
                    </Flex>
                  </Flex>
                </button>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<FiMoreVertical />}
                    variant="ghost"
                    position="absolute"
                    top="10%"
                    right="5%"
                  />
                  <MenuList>
                    <MenuItem
                      icon={<MdOutlineEdit />}
                      onClick={() => {
                        setSelectedAcnorImageURL(anchor.sources?.main);
                        setSelectedTargetName(anchor.name ?? "");
                        setSelectedTargetDescription(anchor.description ?? "");
                        setEditingMarker(true);
                        onOpenUploadImageMarkerModal();
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      icon={<AiOutlineDelete />}
                      onClick={() =>
                        removeSelectedTargetAction(anchor.id ?? "")
                      }
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            );
          })}
      </Flex>
    </>
  );
};

interface ImageMarkerPreviewProps {
  src: string;
  setConfigurations: (configurations: Configurations) => void;
}

const ImageMarkerPreview: FC<ImageMarkerPreviewProps> = ({
  src,
  setConfigurations,
}) => {
  return (
    <Image
      src={src}
      onLoad={(e) => {
        const img = e.target as HTMLImageElement;
        const size = { x: img.naturalWidth, y: img.naturalHeight };
        setConfigurations(initialMarkerConfigurations(size));
      }}
    />
  );
};
