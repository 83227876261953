import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ComponentProps, FC, useRef, useEffect, useState } from "react";
import { contentTypeDetails } from "../contentTypeDetails";
import { Content } from "api/model";
import { useTranslation } from '../../../../../../layout/TranslationContext';
import { translatePage } from '../../../../../../layout/translatePage';
type Props = Omit<ComponentProps<typeof Modal>, "children"> & {
  confirmOverwrite: (response: boolean) => void;
  content: Content;
};

export const ConfirmOverwriteAlert: FC<Props> = (props) => {
  const { content } = props;
  const typeName = () => {
    if (!content) {
      return undefined;
    }
    return contentTypeDetails(content.type!).typeName;
  };
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false); 
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    console.log(props.isOpen)
    if (props.isOpen) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false); 
    }
  }, [props.isOpen]);

useEffect(() => {

  if (modalIsOpen && modalRef.current) {
    translatePage(modalRef.current!, language);
  }
}, [modalIsOpen]);
  return (
    <Modal {...props} initialFocusRef={modalRef} >
      <ModalOverlay />
      <ModalContent ref={modalRef} >
        <ModalHeader>
          For now you can only have one visual element in your scene.
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to replace the existing{" "}
          <strong>{typeName()}</strong> content with another one?
        </ModalBody>
        <ModalFooter>
          <Button
            px="10"
            mr="4"
            variant="solid"
            whiteSpace="normal"
            onClick={() => props.confirmOverwrite(false)}
          >
            Cancel
          </Button>
          <Button px="10" onClick={() => props.confirmOverwrite(true)}>
            Yes, delete the exiting content
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
