import React, { SyntheticEvent } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { useQueryChapter, useMutationDeleteContent } from "state/remoteState";
import { AiOutlineDelete } from "react-icons/ai";
import { Content, ContentType } from "api/model";
import { MdOutlineEdit } from "react-icons/md";
import { contentTypeDetails } from "./contentTypeDetails";
import { ContentListContentDetails } from "./ContentList/ContentListContentDetails";
import { useProjectChapterId } from "state/localState";
import { DialogContentEditorWebsite } from "./ContentEditorWizard/Website/DialogContentEditorWebsite";

export const PanelContentQRCode = () => {
  const { projectId, chapterId } = useProjectChapterId();
  const chapter = useQueryChapter(projectId, chapterId).data;
  const mutationDeleteContent = useMutationDeleteContent();
  const [contentType, setContentType] = React.useState<ContentType | undefined>(
    ContentType.UrlEndpoint,
  );
  const [content, setContent] = React.useState<Content | undefined>(undefined);

  const {
    onOpen: openContentEditor,
    onClose: closeContentEditor,
    isOpen: isOpenContentEditor,
  } = useDisclosure();

  const create = () => {
    setContentType(ContentType.UrlEndpoint);
    setContent(undefined);
    openContentEditor();
  };

  const update = (content: Content) => {
    setContentType(undefined);
    setContent(content);
    openContentEditor();
  };

  const handleDeleteContent = async (contentId: string) => {
    mutationDeleteContent.mutate({
      projectId: projectId!,
      chapterId: chapterId!,
      contentId: contentId,
    });
  };

  return (
    <>
      <DialogContentEditorWebsite
        contentType={contentType}
        content={content}
        onClose={closeContentEditor}
        isOpen={isOpenContentEditor}
        onSuccess={closeContentEditor}
      />
      { (chapter?.content?.length ?? 0) > 0 ? null :
      <Button onClick={create}>Add Content</Button>
      }

      {chapter?.content
        ?.filter((content) => content.type !== ContentType.Background)
        .map((content, i) => (
          <div
            key={i + "div"}
            style={{
              display: "flex",
              flexDirection: "column",
              border: `1px solid ${"#E2E8F0"}`,
              borderRadius: "4px",
              padding: "3px",
              marginTop: "10px",
              backgroundColor: "white",
              position: "relative",
              width: "100%",
            }}
          >
            <Accordion
              key={i + "acc"}
              width={"100%"}
              allowToggle
              defaultIndex={[0]}
            >
              <AccordionItem
                key={i + "accordeoon"}
                borderColor="transparent"
                p="2"
              >
                <AccordionButton
                  key={i + "accordeoonBUtton"}
                  width="100%"
                  justifyContent="space-between"
                  p="1"
                >
                  <Flex align="center" gap="3" w="100%">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="primary.1"
                      w="40px"
                      h="40px"
                      borderRadius="50"
                      flexShrink="0"
                    >
                      <Icon
                        as={contentTypeDetails(content.type!).icon}
                        color="primary.6"
                      />
                    </Box>
                    <Flex direction="column">
                      <Text
                        fontWeight="700"
                        fontSize="sm"
                        color="black"
                        align="left"
                      >
                        {content.name}
                      </Text>
                      <Text
                        fontSize="xs"
                        fontWeight="500"
                        color="primary.4"
                        align="left"
                        mt="1"
                        lineHeight="1.2"
                      >
                        {content.type === ContentType.Background
                          ? "Background"
                          : content.description}
                      </Text>
                    </Flex>
                    <Spacer />
                  </Flex>
                </AccordionButton>
                <AccordionPanel p="2">
                  <ContentListContentDetails content={content} />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Menu key={i + "menu"}>
              <MenuButton
                key={i + "menuButton"}
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical />}
                variant="ghost"
                position="absolute"
                top="1.5vh"
                right="1vh"
              />
              <MenuList key={i + "menuList"}>
                <MenuItem
                  key={i + "menuItem"}
                  icon={<MdOutlineEdit />}
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    update(content);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  icon={<AiOutlineDelete />}
                  onClick={(e: React.SyntheticEvent) => {
                    e.stopPropagation();
                    handleDeleteContent(content.id!);
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        ))}
    </>
  );
};
