import { FC } from "react";
import { Content } from "api/model";
import { ContentImage } from "./ContentImage";

interface Props {
  content: Content;
}

export const ContentWebsite: FC<Props> = ({ content }) => {
  return <ContentImage content={content} />;
};
