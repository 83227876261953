import {
  FC,
  useEffect,
  useRef,
  useState,
  DragEvent,
  SyntheticEvent,
} from "react";
import { useForm } from "react-hook-form";
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Content, ContentType } from "api/model";
import { useProjectChapterId } from "state/localState";
import {
  useMutationCreateContent,
  useMutationUpdateContent,
} from "state/remoteState";
import { MdOutlineVideoLibrary } from "react-icons/md";
import { Configurations, initial2DConfigurations } from "api/Configurations";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { translateText } from "../../../../../../../layout/translatePage";
import { useTranslation } from '../../../../../../../layout/TranslationContext';
type FormData = {
  name: string;
};

export const TabVideoUpload: FC<CreateOrUpdateContentProps> = (props) => {
  const { projectId, chapterId } = useProjectChapterId();
  const [file, setFile] = useState<File | undefined>(props.file);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const [configurationsFromPreview, setConfigurationsFromPreview] =
    useState<Configurations>({});
  const content = props.content ?? ({ type: ContentType.Video } as Content);
  const mode = props.content ? "update" : "create";
  const toast = useToast();

  // Form
  const {
    register,
    watch,
    formState: { isValid },
  } = useForm<FormData>({
    values: {
      name: content?.name ?? "",
    },
  });
  const formData = watch();

  const [previewURL, setPreviewURL] = useState<string>();
  useEffect(() => {
    if (!file) {
      setPreviewURL(content?.sources?.main);
      return;
    }
    const u = URL.createObjectURL(file);
    setPreviewURL(u);
    return () => {
      URL.revokeObjectURL(u);
    };
  }, [file, content?.sources?.main]);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileUpload = () => {
    hiddenFileInput.current?.click();
  };

  const checkAndSetFile = (f: File | null | undefined) => {
    if (!f) return;
    // Checking the file type can be implemented if needed
    setFile(f);
  };

  const handleFileSelected = () => {
    const f = hiddenFileInput.current?.files?.item(0);
    checkAndSetFile(f);
  };

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const f = e.dataTransfer.files.item(0);
    checkAndSetFile(f);
  };

  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const { language, setLanguage } = useTranslation();
  const showTranslatedToast = async (message: string, language: string) => {
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage,
    });
  };
  const handleCreateContent = () => {
    if (!file) return;
    const newContent: Content = {
      name: formData.name,
      description: file.name,
      type: ContentType.Video,
      configurations: configurationsFromPreview,
    };
    // Initial configuration
    mutationCreateContent.mutate(
      { projectId, chapterId, content: newContent, file },
      {
        onSuccess: () => {
          showTranslatedToast(`Video content "${formData.name}" has been added`, language)
          // toast({
          //   status: "success",
          //   title: "Content added",
          //   description: `Video content "${formData.name}" has been added`,
          // });
          props.onSuccess();
        },
      },
    );
  };

  const handleUpdateContent = () => {
    if (!content) {
      throw new Error("Content is not defined");
    }
    content.name = formData.name;
    if (file) {
      content.description = file.name;
    }
    content.configurations = {
      ...content.configurations,
      ...configurationsFromPreview,
    };
    mutationUpdateContent.mutate(
      {
        projectId,
        chapterId,
        content: content,
        file,
      },
      {
        onSuccess: () => {
          showTranslatedToast( `Video content "${content.name ?? "unnamed"}" has been updated.`, language)
          // toast({
          //   status: "success",
          //   title: "Content updated.",
          //   description: `Video content "${content.name ?? "unnamed"}" has been updated.`,
          // });
          props.onSuccess();
        },
      },
    );
  };

  return (
    <>
      <Grid templateColumns="1fr 1fr" gap="2" minHeight="250px">
        <GridItem>
          <Button
            position="relative"
            onClick={handleFileUpload}
            width="100%"
            height="100%"
            backgroundColor="primary.0"
            borderRadius="6"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{ backgroundColor: "primary.0" }}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <Flex direction="column" align="center">
              {previewURL ? (
                <VideoPreview
                  src={previewURL}
                  setConfigurations={setConfigurationsFromPreview}
                />
              ) : (
                <Icon
                  as={MdOutlineVideoLibrary}
                  color="primary.6"
                  boxSize="6"
                />
              )}
              <Text color="primary.6" pt="6" pb="2" fontWeight="700">
                Upload Video
              </Text>
              <Text color="primary.4" pb="2">
                Supports: MP4
              </Text>
              <Text color="primary.3" pt="4">
                Drag & Drop or click here
              </Text>
            </Flex>
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            style={{ display: "none" }}
            onChange={handleFileSelected}
            accept="video/*"
          />
        </GridItem>
        <Flex direction="column" gap="4" pl="2">
          <Alert
            status="info"
            color="secondary.2"
            backgroundColor="rgba(77, 136, 140, 0.05)"
            border="1px solid #4D888C"
            borderRadius="8"
          >
            <AlertIcon color="secondary.2" />
            <Text fontWeight="500" fontSize="xs">
              You can upload a video in mp4 format here.
            </Text>
          </Alert>
          <Flex align="center" gap="2">
            <Text
              fontSize="sm"
              fontWeight="600"
              width="45%"
              color="primary.4"
              pr="1"
            >
              Content Name
            </Text>
            <Input
              placeholder="Name"
              borderRadius="32"
              size="sm"
              {...register("name")}
            />
          </Flex>
        </Flex>
      </Grid>
      <Flex width="100%" justifyContent="flex-end">
        <Spacer />
        {mode === "create" && (
          <Button
            isDisabled={file === null || !isValid}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleCreateContent}
            isLoading={mutationCreateContent.isPending}
          >
            Add Content
          </Button>
        )}
        {mode === "update" && (
          <Button
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleUpdateContent}
          >
            Update Content
          </Button>
        )}
      </Flex>
    </>
  );
};

interface VideoPreviewProps {
  src: string;
  setConfigurations: (configurations: Configurations) => void;
}

const VideoPreview: FC<VideoPreviewProps> = ({ src, setConfigurations }) => {
  return (
    <video
      key={src}
      controls
      onLoadedMetadata={(e) => {
        const video = e.target as HTMLVideoElement;
        const size = { x: video.videoWidth, y: video.videoHeight };
        setConfigurations(initial2DConfigurations(size));
      }}
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};
