import * as React from "react";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BsImage } from "react-icons/bs";
import { NavLink as ReachLink, useNavigate } from "react-router-dom";
import { IoOpenOutline, IoQrCode } from "react-icons/io5";
import { FiMoreHorizontal } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { useMutationDeleteProject } from "state/remoteState";
import { AccessType } from "../../../api/model";

interface projectCardProps {
  id: string;
  title: string;
  description: string;
  published: boolean;
  type: string;
  users: string[];
  accessType: AccessType;
  date: string;
  thumbnail: string;
}

export const ProjectCard: React.FC<projectCardProps> = ({
  id,
  title,
  description,
  published,
  type,
  accessType,
  date,
  thumbnail,
}) => {
  const mutationDeleteProject = useMutationDeleteProject();
  const elemRef = React.useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [localDate] = React.useState(new Date(date));
  const navigate = useNavigate();

  const handleContextMenu = React.useCallback((e) => {
    e.preventDefault();
    setOpenMenu(true);
  }, []);

  React.useEffect(() => {
    if (elemRef.current) {
      elemRef.current.addEventListener("contextmenu", handleContextMenu);
    }
    return () => {
      if (elemRef.current) {
        elemRef.current.removeEventListener("contextmenu", handleContextMenu);
      }
    };
  });

  const handleDeleteProject = (id: string) => {
    mutationDeleteProject.mutate(id, {
      onSuccess: () => {
        navigate("/");
      },
    });
  };

  // @ts-ignore
  return (
    <LinkBox
      as={Card}
      id={id}
      ref={elemRef}
      borderRadius="8"
      _hover={{
        cursor: "pointer",
        transform: "scale(1.05)",
        transition: "transform .2s ease-in-out",
        zIndex: "100",
      }}
      onMouseLeave={() => setOpenMenu(false)}
    >
      <CardBody p="0" position="relative" height="183px">
        <LinkOverlay as={ReachLink} to={`/projects/${id}`}>
          {thumbnail ? (
            <Image
              height="183px"
              width="100%"
              borderTopLeftRadius="8"
              borderTopRightRadius="8"
              src={thumbnail}
              fit="cover"
              alt="Project Thumbnail"
              loading={"eager"}
            />
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="183px"
              borderTopLeftRadius="8"
              borderTopRightRadius="8"
              backgroundColor="primary.0"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="primary.1"
                width="20px"
                height="20px"
                borderRadius="50%"
              >
                <Icon as={BsImage} w={8} h={8} />
              </Box>
            </Box>
          )}
        </LinkOverlay>
        <Box
          display="flex"
          width="20px"
          height="20px"
          top="12px"
          left="10px"
          position="absolute"
          backgroundColor="primary.1"
          borderRadius="50"
          css={{ justifyContent: "center", alignItems: "center" }}
        >
          <Icon boxSize="3" as={type === "Image" ? BsImage : IoQrCode} />
        </Box>
        <Box
          display="flex"
          position="absolute"
          top="12px"
          right="10px"
          padding="2px 8px 2px 8px"
          borderRadius="50"
          backgroundColor={!published ? "orange.300" : "#C8ECD4"}
          height="20px"
          css={{ justifyContent: "center", alignItems: "center" }}
        >
          <Text fontSize="12px" fontWeight="500">
            {published ? "Published" : "Draft"}
          </Text>
        </Box>
        <Stack spacing="2" pl="1rem" pr="1rem">
          <Flex width="100%" justify="space-between">
            <Box
              display="flex"
              mt="0.4rem"
              backgroundColor="#EDF2F7"
              width="auto"
              borderRadius="50"
              p="2px 13px 2px 13px"
              css={{ justifyContent: "center", alignItems: "center" }}
            >
              <Text fontSize="12px" fontWeight="500" color="#2A4365">
                {accessType === "All" ? "Anyone" : "All Company Users"}
              </Text>
            </Box>
            <Menu
              isOpen={openMenu}
              onClose={() => setOpenMenu(false)}
              onOpen={() => setOpenMenu(true)}
            >
              {/*// @ts-ignore*/}
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreHorizontal />}
                variant="link"
                justifyContent="flex-end"
                zIndex={1}
              />
              <MenuList borderRadius="12px" width="261px">
                <MenuItem
                  icon={<Icon as={IoOpenOutline} boxSize="4" />}
                  color="black"
                  fontSize="12px"
                  fontWeight="500"
                  onClick={() => navigate(`/projects/${id}`)}
                  _hover={{ backgroundColor: "secondary.0" }}
                >
                  Open
                </MenuItem>
                {/*<MenuItem
                  icon={<Icon as={HiOutlineDuplicate} boxSize="4" />}
                  color="black"
                  fontSize="12px"
                  fontWeight="500"
                  _hover={{ backgroundColor: "secondary.0" }}
                >
                  Duplicate
                </MenuItem>
                <MenuItem
                  icon={<Icon as={FiUsers} boxSize="4" />}
                  color="black"
                  fontSize="12px"
                  fontWeight="500"
                  alignContent="center"
                  _hover={{ backgroundColor: "secondary.0" }}
                >
                  Share
                </MenuItem>
                <MenuItem
                  icon={<Icon as={MdOutlineUnpublished} boxSize="4" />}
                  color="black"
                  fontSize="12px"
                  fontWeight="500"
                  _hover={{ backgroundColor: "secondary.0" }}
                >
                  Unpublish
                </MenuItem>*/}
                <MenuItem
                  icon={<Icon as={AiOutlineDelete} boxSize="4" />}
                  color="black"
                  fontSize="12px"
                  fontWeight="500"
                  _hover={{ backgroundColor: "secondary.0" }}
                  onClick={() => handleDeleteProject(id)}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <LinkOverlay as={ReachLink} to={`/projects/${id}`}>
            <Heading
              size="md"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              data-nontranslatable={title}
            >
              {title}
            </Heading>
            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" data-nontranslatable={description}>
              {description}
            </Text>
          </LinkOverlay>
        </Stack>
      </CardBody>
      <CardFooter p="1rem">
        <LinkOverlay as={ReachLink} to={`/projects/${id}`}>
          <Flex width="100%" justify="space-between" align="center">
            <Flex flexDirection="column">
              <Text fontSize="sm" color="gray">
                Last updated:
              </Text>
              <Text fontSize="sm" color="gray" data-nontranslatable>
                {localDate.getDate().toString().padStart(2, "0")}.
                {(localDate.getMonth() + 1).toString().padStart(2, "0")}.
                {localDate.getUTCFullYear()}{" "}
                {localDate.getHours().toString().padStart(2, "0")}:
                {localDate.getMinutes().toString().padStart(2, "0")}
              </Text>
            </Flex>
            {/*<AvatarGroup size="sm" max={3}>
            {authors.map((author, i) => (
              <Avatar key={i} name={author} />
            ))}
          </AvatarGroup>*/}
          </Flex>
        </LinkOverlay>
      </CardFooter>
    </LinkBox>
  );
};
