import { useLoader } from "@react-three/fiber";
import { FC, useMemo, useState } from "react";
import { Html } from "@react-three/drei";
import { Euler, TextureLoader, Vector3 } from "three";
import { Content } from "api/model";
interface Props {
  content: Content;
}

export const ContentSubtitles: FC<Props> = ({ content }) => {
  const [textContent, setTextContent] = useState("loading...");

  try {
    fetch(content.sources!.main)
      .then((x) => x.text())
      .then((text) => setTextContent(text))
      .catch(() => setTextContent("Error Loading srt file"));
  } catch {
    setTextContent("Error loading srt file");
  }
  const style = {
    transform: "translate(-145px,-125px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "53px",
    width: "300px",
  };
  const texture = useLoader(
    TextureLoader,
    `${process.env.PUBLIC_URL}/icons/subtitles_content_icon.png`,
  );
  const { scale, rotation } = useMemo(() => {
    const maxD = Math.max(texture.image.width, texture.image.height);
    const scaleFactor = 0.3;
    return {
      scale: new Vector3(
        (texture.image.width / maxD) * scaleFactor,
        (texture.image.height / maxD) * scaleFactor,
        0,
      ),
      rotation: new Euler(-Math.PI / 2, 0, Math.PI / 2),
    };
  }, [texture]);
  return (
    <mesh rotation={rotation} scale={scale}>
      <Html>
        <div style={style}>
          {textContent
            .slice(0, Math.min(80, textContent.length))
            .replace("\n", "")}
          ...
        </div>
      </Html>
    </mesh>
  );
};
