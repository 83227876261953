import { ContentType } from "api/model";
import { BiCube } from "react-icons/bi";
import { BsImage } from "react-icons/bs";
import { IoTextSharp } from "react-icons/io5";
import {
  MdLibraryMusic,
  MdOutlineQuestionMark,
  MdOutlineVideoLibrary,
} from "react-icons/md";
import { IconType } from "react-icons";

export enum ContentCategory {
  All = "All",
  Images = "Images",
  AudioFiles = "Audio Files",
  Videos = "Videos",
  ThreeDObjects = "3D Objects",
  Subtitles = "Subtitles",
}

interface ContentCategoryDetails {
  category: ContentCategory;
  icon: IconType;
  extensions: string[];
  contentType: ContentType | undefined;
}

const ContentCategoryData: Record<ContentCategory, ContentCategoryDetails> = {
  [ContentCategory.All]: {
    category: ContentCategory.All,
    icon: MdOutlineQuestionMark,
    extensions: [],
    contentType: undefined,
  },
  [ContentCategory.Images]: {
    category: ContentCategory.Images,
    icon: BsImage,
    extensions: [".jpg", ".png", ".jpeg", ".gif"],
    contentType: ContentType.Image,
  },
  [ContentCategory.AudioFiles]: {
    category: ContentCategory.AudioFiles,
    icon: MdLibraryMusic,
    extensions: [".mp3", ".wav", ".flac", ".m4a"],
    contentType: ContentType.Audio,
  },
  [ContentCategory.Videos]: {
    category: ContentCategory.Videos,
    icon: MdOutlineVideoLibrary,
    extensions: [".mp4", ".avi", ".mkv", ".webm"],
    contentType: ContentType.Video,
  },
  [ContentCategory.ThreeDObjects]: {
    category: ContentCategory.ThreeDObjects,
    icon: BiCube,
    extensions: [".obj", ".fbx", ".gltf", ".glb"],
    contentType: ContentType.Value3DModel,
  },
  [ContentCategory.Subtitles]: {
    category: ContentCategory.Subtitles,
    icon: IoTextSharp,
    extensions: [".srt", ".vtt"],
    contentType: ContentType.Subtitles,
  },
};

export const contentCategoryDetailsByContentType = (
  contentType: ContentType,
): ContentCategoryDetails => {
  const categoryDetails = Object.values(ContentCategoryData).find(
    (details) => details.contentType === contentType,
  );
  if (categoryDetails) {
    return categoryDetails;
  }
  return ContentCategoryData[ContentCategory.All];
};

export const contentCategoryDetailsByExtension = (
  extension: string | undefined | null,
): ContentCategoryDetails => {
  if (!extension) {
    return ContentCategoryData[ContentCategory.All];
  }

  const categoryDetails = Object.values(ContentCategoryData).find((details) =>
    details.extensions.includes(extension.toLowerCase()),
  );

  return categoryDetails || ContentCategoryData[ContentCategory.All];
};
