import { useVideoTexture } from "@react-three/drei";
import { Content } from "api/model";
import { FC, useEffect } from "react";
import { DoubleSide } from "three";

interface Props {
  content: Content;
}

// This one doesn't work. TODO: figure out how to make it the right way.
export const ContentVideo: FC<Props> = ({ content }) => {
  const texture = useVideoTexture(content?.sources?.main ?? "", {
    unsuspend: "canplay",
    crossOrigin: "Anonymous",
    muted: true,
    loop: true,
  });

  useEffect(() => {
    const videoElement = texture.source.data;
    if (videoElement && "play" in videoElement) {
      videoElement
        .play()
        .catch((e: any) => console.error("Video play failed", e));
    }
  }, [texture]);

  return (
    <mesh>
      <planeGeometry />
      <meshBasicMaterial map={texture} toneMapped={false} side={DoubleSide} />
    </mesh>
  );
};
