import { ComponentProps, FC, useRef, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Icon,
} from "@chakra-ui/react";
import { HelpIconButton } from "pages/HelpIconButton";
import { ContentType } from "api/model";
import { contentTypeDetails } from "../contentTypeDetails";
import { useTranslation } from '../../../../../../layout/TranslationContext';
import { translatePage } from '../../../../../../layout/translatePage';
type DialogSelectContentTypeProps = Omit<
  ComponentProps<typeof Modal>,
  "children"
> & {
  onSelected: (contentType: ContentType) => void;
};

export const DialogSelectContentType: FC<DialogSelectContentTypeProps> = (
  props,
) => {
  const { onSelected } = props;
 const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false); 
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    console.log(props.isOpen)
    if (props.isOpen) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false); 
    }
  }, [props.isOpen]);

useEffect(() => {

  if (modalIsOpen && modalRef.current) {
    translatePage(modalRef.current!, language);
  }
}, [modalIsOpen]);
  return (
    <Modal {...props} size="3xl" initialFocusRef={modalRef} 
>
      <ModalOverlay />
      <ModalContent ref={modalRef} 
      >
        <ModalHeader>
          <Flex direction="row">
            <Box>
              Select Content Type
              <Text fontSize="sm">
                What content type do you want to upload? You can add more
                content afterwards
              </Text>
            </Box>
            <Spacer />
            <HelpIconButton
              link="https://www.nextcreate.com/help/content-upload              "
              isFloating={false}
            />
          </Flex>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Grid
            templateColumns="repeat(3, 1fr)"
            templateRows="repeat(2, 1fr)"
            gap="4"
            marginBottom="20px"
          >
            {[
              ContentType.Image,
              ContentType.Video,
              ContentType.UrlEndpoint,
              ContentType.Value3DModel,
              ContentType.Audio,
              ContentType.Subtitles,
            ].map((t) => (
              <ContentTypeButton
                key={t}
                contentType={t}
                onClick={() => onSelected(t)}
              />
            ))}
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface ContentTypeButtonProps {
  contentType: ContentType;
  onClick: (contentType: ContentType) => void;
}

const ContentTypeButton: FC<ContentTypeButtonProps> = ({
  contentType,
  onClick,
}) => {
  const { icon, typeName: name, extensions } = contentTypeDetails(contentType);

  return (
    <button
      onClick={() => onClick(contentType)}
      style={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid #2E464F",
        borderRadius: "12px",
        padding: "3px",
        position: "relative",
        width: "100%",
      }}
    >
      {/* <Icon
        as={AiFillCheckCircle}
        position="absolute"
        top="10%"
        right="5%"
        boxSize={5}
        color="primary.6"
      /> */}
      <Flex direction="row" gap="4" p="16px 8px" align="center">
        <Flex
          align="center"
          justify="center"
          width="50px"
          height="50px"
          backgroundColor="primary.1"
          borderRadius="50%"
        >
          <Icon as={icon} w={6} h={6} color="primary.6" />
        </Flex>
        <Flex direction="column" align="flex-start">
          <Text whiteSpace="nowrap" fontSize="sm" fontWeight="bold">
            {name}
          </Text>
          <Text fontSize="xs" color="primary.4">
            {extensions}
          </Text>
        </Flex>
      </Flex>
    </button>
  );
};
