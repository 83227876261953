import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { FC, SyntheticEvent, DragEvent, useState } from "react";
import { FiUpload } from "react-icons/fi";

interface ContentUploadButtonProps {
  onDrop: (file: File) => void;
  onClick: () => void;
}

export const ContentUploadButton: FC<ContentUploadButtonProps> = ({
  onDrop,
  onClick,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const f = e.dataTransfer.files.item(0);
    if (!f) return;
    onDrop(f);
  };

  return (
    <Box
      onClick={() => onClick()}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      style={{
        display: "flex",
        flexDirection: "column",
        border: "1px dashed #E2E8F0",
        borderRadius: "4px",
        padding: "3px",
        backgroundColor: "white",
        position: "relative",
        width: "100%",
        cursor: "pointer",
      }}
    >
      <Flex
        align="center"
        justify="center"
        gap="4"
        p="4"
        direction="column"
        width="100%"
      >
        <Box
          backgroundColor="primary.0"
          width="45px"
          height="45px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="50%"
        >
          <Icon as={FiUpload} />
        </Box>
        <Flex direction="column" align="center">
          <Text textAlign="left" fontWeight="700" fontSize="md" color="black">
            Upload Content
          </Text>
          <Text
            textAlign="left"
            fontWeight="500"
            fontSize="sm"
            color="primary.4"
          >
            {!dragActive ? "Drag & Drop or click here" : "Drop here"}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
