import axios from '../api/axios';

const isTextNode = (node: Node): node is Text => {
  if(node) {
    return node.nodeType === Node.TEXT_NODE;
  }
  return false;
};

const convertNodeToTextNode = (node: Node): Text => {
  if (isTextNode(node)) {
    return node as Text;
  }
  throw new Error("The provided node is not a Text node.");
};
const getExistingTranslation = async (text: string, language: string): Promise<string | null> => {
  const key = `${language}:${text}`;
  try {
    let data = localStorage.getItem(key);
    if(data){
      return data;
    }

    const response = await axios.get<string>(`${process.env.REACT_APP_API_URI}/Translation`, {
      params: { text, language },
    });
    localStorage.setItem(key, response.data);


    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      console.error('Error fetching existing translation:', error);
      throw error;
    }
  }
};

const translateTextNode = async (textNode: Text, language: string): Promise<void> => {
  try {
    const textContent = textNode.textContent?.trim();
    //console.log(textContent)
    if (textContent && textContent.length > 3) {
      const element = textNode.parentElement;
      if (element && element.dataset.nontranslatable) {
        return;
      }

      const termsToKeep = textContent.match(/\{[^}]+\}/g) || [];
      const placeholders = termsToKeep.map((term, index) => `__PLACEHOLDER_${index}__`);

      let text = textContent;
      termsToKeep.forEach((term, index) => {
        text = text.replace(term, placeholders[index]);
      });

      let translatedText = await getExistingTranslation(text, language); //2
      if (!translatedText) {
        const response = await axios.get<string>(`${process.env.REACT_APP_API_URI}/Translation`, {
          params: { text, language },
        });
        translatedText = response.data;
      }

      placeholders.forEach((placeholder, index) => {
        translatedText = translatedText!.replace(placeholder, termsToKeep[index]);
      });

      textNode.textContent = translatedText;
    }
  } catch (error) {
    console.error('Error translating text:', error);
  }
};

const shouldTranslateElement = (node: Node): boolean => {
  if (node.nodeType !== Node.ELEMENT_NODE) {
    return false;
  }
  const element = node as HTMLElement;
  const excludedTags = ['SCRIPT', 'STYLE', 'NOSCRIPT'];
  return !excludedTags.includes(element.tagName);
};

const translateElementAttributes = async (element: HTMLElement, language: string): Promise<void> => {
  if (element.hasAttribute('placeholder')) {
    const text = element.getAttribute('placeholder');
    if (text) {
      console.log(text);

      const response = await axios.get<string>(`${process.env.REACT_APP_API_URI}/Translation`, {
        params: { text, language },
      });
      const translatedText = response.data;
      element.setAttribute('placeholder', translatedText);
    }
  }
};

const traverseAndTranslate = async (node: Node, language: string): Promise<void> => {
  if (isTextNode(node) && node.textContent?.trim()) {
    await translateTextNode(node, language); //3
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    if (shouldTranslateElement(node)) {
      await translateElementAttributes(node as HTMLElement, language);
      for (let i = 0; i < node.childNodes.length; i++) {
        await traverseAndTranslate(node.childNodes[i], language);
      }
    }
  } 
};

const translationsMap = new Map<string, Map<string, string>>();
const originalTextMap = new Map<Node, string>();

const prepareAndTranslateNode = async (node: Node, language: string): Promise<void> => {
  if (isTextNode(node)) {
    const textContent = node.textContent?.trim();
    if (textContent && textContent.length > 3) {
      const element = node.parentElement;
      if (element && element.dataset.nontranslatable) {
        return;
      }
      // Store the original text 
      if (!originalTextMap.has(node)) {
        originalTextMap.set(node, textContent);
      }

      let text = originalTextMap.get(node) || '';
      let translatedText = await getExistingTranslation(text, language);

      if (!translatedText) {
        const response = await axios.get<string>(`${process.env.REACT_APP_API_URI}/Translation`, {
          params: { text, language },
        });
        translatedText = response.data;
      }

      let languageTranslationsMap = translationsMap.get(language);
      if (languageTranslationsMap == null) {
        languageTranslationsMap = new Map<string, string>();
        translationsMap.set(language, languageTranslationsMap);
      }

      // Store translated text before applying it
      languageTranslationsMap.set(textContent, translatedText);
    }
  } else if (node && node.nodeType === Node.ELEMENT_NODE && shouldTranslateElement(node)) {
    const childNodes = node.childNodes;
    const translationPromises = Array.from(childNodes).map(childNode => prepareAndTranslateNode(childNode, language));
    await Promise.all(translationPromises);
  }
};

const applyTranslations = (node: Node, language: string): void => {
  if (isTextNode(node)) {
    let textContent = originalTextMap.get(node) || '';
    let languageTranslationsMap = translationsMap.get(language);
    let tc = node.textContent;
    node.textContent = languageTranslationsMap?.get(textContent) || textContent || tc;
  } else if (node && node.nodeType === Node.ELEMENT_NODE && shouldTranslateElement(node)) {
    const parent = node as HTMLElement;
    if (!parent.classList.contains("doNotTranslate")) {
      for (let i = 0; i < node.childNodes.length; i++) {
        applyTranslations(node.childNodes[i], language);
      }
    }
  }
};

export const translatePage = async (element: HTMLElement, language: string): Promise<void> => {
  await prepareAndTranslateNode(element, language);
  applyTranslations(element, language);
};

export const translateText = async (text: string, language: string): Promise<string> => {
  try {
    let translatedText = await getExistingTranslation(text, language);
    if (!translatedText) {
      const response = await axios.get<string>(`${process.env.REACT_APP_API_URI}/Translation`, {
        params: { text, language },
      });
      translatedText = response.data;
    }
    return translatedText;
  } catch (error) {
    console.error('Error translating text:', error);
    return text; 
  }
};

