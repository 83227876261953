import { ContentType } from "api/model";
import { IconType } from "react-icons";
import { BiCube, BiSidebar } from "react-icons/bi";
import { BsImage } from "react-icons/bs";
import { IoMusicalNotes, IoTextSharp } from "react-icons/io5";
import {
  MdOutlineImagesearchRoller,
  MdOutlineVideoLibrary,
} from "react-icons/md";
import { contentEditorAudioSupportedContentTypes } from "./ContentEditorWizard/Audio/DialogContentEditorAudio";
import { contentEditorVideoSupportedContentTypes } from "./ContentEditorWizard/Video/DialogContentEditorVideo";
import { contentEditorWebsiteSupportedContentTypes } from "./ContentEditorWizard/Website/DialogContentEditorWebsite";

// Mapping of file types to content types.
const fileTypeMapping: { [key: string]: ContentType } = {
  "image/jpeg": ContentType.Image,
  "image/png": ContentType.Image,
  "video/mp4": ContentType.Video,
  "audio/mpeg": ContentType.Audio,
  ".glb": ContentType.Value3DModel,
  ".srt": ContentType.Subtitles,
  "text/plain": ContentType.Subtitles,
};

// Reverse mapping from content types to file types.
const contentTypeMapping: { [key in ContentType]: string[] } = Object.entries(
  fileTypeMapping,
).reduce(
  (acc, [fileType, contentType]) => {
    // Ensure the array for the content type is initialized.
    if (!acc[contentType]) {
      acc[contentType] = [];
    }
    acc[contentType].push(fileType.toLowerCase());
    return acc;
  },
  {} as { [key in ContentType]: string[] },
);

export const fileTypeToContentType = (
  fileType: string,
): ContentType | undefined => {
  fileType = fileType.toLowerCase();
  return fileTypeMapping[fileType];
};

export const contentTypeToFileType = (
  contentType: ContentType,
): string | undefined => {
  const fileTypes = contentTypeMapping[contentType];
  return fileTypes ? fileTypes.join(", ") : undefined;
};

export interface ContentTypeDetails {
  typeName: string;
  icon: IconType;
  extensions: string;
  info: string;
}

export const contentTypeDetails = (t: ContentType): ContentTypeDetails => {
  switch (t) {
    case ContentType.Image:
      return {
        typeName: "Image",
        icon: BsImage,
        extensions: "JPEG, JPG, PNG",
        info: "For now you can just upload one image at a time. It's not possible to combine 2 or more images in one scene.",
      };
    case ContentType.Video:
      return {
        typeName: "Video",
        icon: MdOutlineVideoLibrary,
        extensions: "MP4, Vimeo, YouTube",
        info: "You can upload a video in mp4 format here.",
      };
    case ContentType.Youtube:
      return {
        typeName: "YouTube Video",
        icon: MdOutlineVideoLibrary,
        extensions: "",
        info: "You can specify a YouTube video URL here.",
      };
    case ContentType.Vimeo:
      return {
        typeName: "Video",
        icon: MdOutlineVideoLibrary,
        extensions: "",
        info: "You can specify a Vimeo video URL here.",
      };
    case ContentType.Audio:
      return {
        typeName: "Audio",
        icon: IoMusicalNotes,
        extensions: "MP3",
        info: "You can add a mp3 audio file here.",
      };
    case ContentType.Text2Speech:
      return {
        typeName: "Text to Speech",
        icon: IoMusicalNotes,
        extensions: "MP3",
        info: "You can add a Text to Speech audio file here.",
      };
    case ContentType.Value3DModel:
      return {
        typeName: "3D Object",
        icon: BiCube,
        extensions: "GLB",
        info: "For now you can just upload one 3D Object at a time. It's not possible to combine 2 or more in one scene.",
      };
    case ContentType.Subtitles:
      return {
        typeName: "Subtitles",
        icon: IoTextSharp,
        extensions: "SRT",
        info: "You can add a SRT subtitle file here.",
      };
    case ContentType.UrlEndpoint:
      return {
        typeName: "Website",
        icon: BiSidebar,
        extensions: "",
        info: "You can add a website here.",
      };
    case ContentType.EditorJs:
      return {
        typeName: "Web-Editor",
        icon: BiSidebar,
        extensions: "",
        info: "You can add a text here.",
      };
    case ContentType.Background:
      return {
        typeName: "Background",
        icon: MdOutlineImagesearchRoller,
        extensions: "",
        info: "You can add a background here.",
      };
    default:
      throw new Error(`Unknown content type: ${t}`);
  }
};

export const contentTypesVisibleInARMode = [
  ContentType.EditorJs,
  ContentType.Image,
  ContentType.UrlEndpoint,
  ContentType.Value3DModel,
  ContentType.Video,
  ContentType.Vimeo,
  ContentType.Youtube,
];

export const contentTypesVisibleInOnScreenMode = [
  ContentType.Audio,
  ContentType.Custom,
  ContentType.EditorJs,
  ContentType.Image,
  // ContentType.Subtitles,
  ContentType.Text2Speech,
  ContentType.UrlEndpoint,
  ContentType.Value3DModel,
  ContentType.Video,
  ContentType.Vimeo,
  ContentType.Youtube,
];

// Some content types have their own editors. Here is the list of them.
export const contentTypesWithOwnEditor = [
  ...contentEditorAudioSupportedContentTypes,
  ...contentEditorVideoSupportedContentTypes,
  ...contentEditorWebsiteSupportedContentTypes,
];
