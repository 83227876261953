import * as React from "react";
import { AuthenticatedApp } from "./AuthenticatedApp";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { UnauthenticatedApp } from "./UnauthenticatedApp";

function App() {
  return (
    <>
      <AuthenticatedTemplate>
        <AuthenticatedApp />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedApp />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
